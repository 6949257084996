import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Switch } from "antd";
import { Link } from "react-router-dom";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  ReloadOutlined,
  DeleteOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { format } from "date-fns";
import MediaInput from "./MediaInput";
import "./components.scss";
import { EffectProfileTableRow } from "types/effectProfileTable";
import { MediaTableRow } from "types/mediaTableRow";
import { MediaMetadata } from "types/mediaData";
import VideoPreview from "./VideoPreview";
import { useNavigate } from "react-router-dom";
import withMessage, { WithMessageProps } from "../hoc/withMessage";
import { DatePicker, Space } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { start } from "repl";

interface MyComponentProps {
  subscriberProfileDropdownOptions?;
  subscriberId?;
  nvObj?;
  mediaId?;
}
const Media: React.FC<MyComponentProps & WithMessageProps> = ({ subscriberProfileDropdownOptions, subscriberId, nvObj, mediaId, showMessage }) => {
  const navigate = useNavigate();
  const selectionType = "checkbox";
  const [file, setFile] = useState<File>();
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [transcriptionLanguage, setTranscriptionLanguage] = useState();
  const [resolutionProfile, setResolutionProfile] = useState();
  const [effectProfile, setEffectProfile] = useState<EffectProfileTableRow[]>();
  const [watermarkText, setWatermarkText] = useState();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [mediaData, setMediaData] = useState<MediaTableRow[] | false>(false);
  const [filteredTableData, setFilteredTableData] = useState<
    MediaTableRow[] | false
  >(false);
  const [gifDuration, setGifDuration] = useState();
  const [gifStartTime, setGifStartTime] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [streamBtnLoading, setStreamBtnLoading] = useState(false);
  const [transcriptionBtnLoading, setTranscriptionBtnLoading] = useState(false);
  const [selectedMediaRows, setSelectedMediaRows] = useState([]);
  const [videoPreviewSection, setVideoPreviewSection] = useState(false);
  const [searchMediaId, setSearchMediaId] = useState<string>();
  const [activeMedia, setActiveMedia] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [startDateUnix, setStartDateUnix] = useState<number | null>(null);
  const [endDateUnix, setEndDateUnix] = useState<number | null>(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [useBuffyHalved, setUseBuffyHalved] = useState(true)
  const [fullMediaInfo, setFullMediaInfo] = useState<
    MediaMetadata[] | undefined
  >();

  console.log(nvObj)

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedMediaRows(selectedRows);
    }
  };

  useEffect(() => {
    nvObj?.on("app:ready", () => {
      console.log("app:ready");
    });

    nvObj?.on("upload:uploaded", () => {
      showMessage("Media file is uploaded", 'success');
      console.log("upload:uploaded");
    });

    nvObj?.on("upload:failed", () => {
      showMessage("Upload failed", 'error');
      console.log("upload:failed");
    });

    nvObj?.on("upload:verified", params => {
      showMessage("Upload Verified", 'success');
      console.log("upload:verified");
      console.log(params);
    });

    nvObj?.on("upload:processed", params => {
      showMessage("Media file is processed", 'success');
      console.log("upload:processed");
      console.log(params);
    });
  }, [nvObj]);

  const handleFileSelected = e => {
    setFile(e.target.files[0]);
  };

  const handleTHumbnailFileSelected = e => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleUploadProgress = (uploaded, total, percentage) => {
    setUploadPercentage(percentage);
  };

  const handleThumbnailUploadProgress = (uploaded, total, percentage) => {
    console.log(uploaded, total, percentage);
  };

  const handleCancelRequest = () => {
    nvObj.cancelRequest();
  };

  const handleDeleteMedia = data => {
    setDeleteLoader(true);
    const reqData = [
      {
        mimeType: data["mimeType"],
        subscriberId: subscriberId,
        mediaId: data["id"]
      }
    ];
    nvObj.deleteMediaFile(reqData).finally(res => {
      setDeleteLoader(false);
      reloadMediaList();
    });
  };

  const handleSearchByMediaId = e => {
    setSearchMediaId(e);
    if (mediaData) {
      console.log(mediaData)
      let filteredArray = mediaData.filter(media => media?.key.includes(e));
      console.log(filteredArray)
      if (filteredArray.length === 0) {
        filteredArray = mediaData.filter(media => media?.clientKey.includes(e));
        console.log(filteredArray)
      }
      setFilteredTableData(filteredArray);
    }
  };

  const handleMultipleDelete = () => {
    setDeleteBtnLoading(true);
    let reqData: {
      mimeType: string;
      mediaId: string;
    }[] = [];
    // for media in selectedMediaRows:
    for (const media of selectedMediaRows) {
      const obj = {
        mimeType: media["mimeType"],
        mediaId: media["id"]
      };
      reqData.push(obj);
    }
    nvObj.deleteMediaFile(reqData).finally(res => {
      setDeleteBtnLoading(false);
      setSelectedMediaRows([]);
      reloadMediaList();
    });
  };

  const handleReTriggerNotification = (notificationType) => {
    if (notificationType === "streams") {
      setStreamBtnLoading(true);
    }
    else {
      setTranscriptionBtnLoading(true)
    }
    let mediaId = ""
    // for media in selectedMediaRows:
    for (const media of selectedMediaRows) {
      mediaId = media["id"]
    }
    nvObj.reTriggerNotifications(mediaId, notificationType).finally(res => {
      console.log(res)
      if (notificationType === "streams") {
        setStreamBtnLoading(false);
      }
      else {
        setTranscriptionBtnLoading(false)
      }
    });
  };

  const handleFileUpload = async () => {
    setLoading(true);

    let data = {
      file: file,
      mimeType: file?.type,
      // mimeType: "video/webm;codecs=vp9",
      // isMultiStreamRecording: true,
      // metadata: {
      //   tags: ["web", "lgv"]
      // },
      // thumbnailMimeType: "image/png",
      version: 1,
      // putRequest: true
      requiresSocket: true,
      clientKey: Math.random().toString().substr(2, 10),
      // useBuffyHalved: useBuffyHalved,
      // "transcribedText": "undefined"
      // noGptInsights: false,
      // isProcessed: true,
      // effectProfile: []
    };

    // hardcoded dynamic watermark text for thumbnails
    // data["dynamicThumbnailText"] = "NativeVideo";

    if (transcriptionLanguage)
      data["transcriptionLanguage"] = transcriptionLanguage;
    if (resolutionProfile) data["resolutionProfile"] = resolutionProfile;
    if (effectProfile && effectProfile.length !== 0)
      data["effectProfile"] = effectProfile;
    if (watermarkText) data["watermarkText"] = watermarkText;
    if (gifDuration) data["animatedThumbnailDuration"] = gifDuration;
    if (gifStartTime) data["animatedThumbnailStartTime"] = gifStartTime;
    if (thumbnailFile) {
      data["thumbnailFile"] = thumbnailFile;
      data["thumbnailMimeType"] = thumbnailFile.type;
    }

    nvObj
      .uploadMedia(data, handleUploadProgress, handleThumbnailUploadProgress)
      .then(() => {
        setLoading(false);
      });

    // nvObj
    //   .azureUploadTest(
    //     data,
    //     handleUploadProgress,
    //     handleThumbnailUploadProgress
    //   )
    //   .then(() => {
    //     setLoading(false);
    //   });

    // nvObj.getPresingedUrlForMediaUpload(data).then(res => {
    //   console.log(res);
    //   setLoading(false);
    // });
  };

  // console.log(Math.random().toString().substr(2, 10))

  useEffect(() => {
    if (subscriberId && nvObj) fetchMediaDetails(subscriberId); // fetch all media files for this subscriber
  }, [subscriberId, nvObj]);

  const fetchMediaDetails = (subscriberId, startDateUnix?: number, endDateUnix?: number) => {
    setFilteredTableData(false);
    setErrorMessage("");
    nvObj
      .getAllMediaDetails(subscriberId, startDateUnix, endDateUnix)
      .then((res: MediaMetadata[]) => {
        setFullMediaInfo(res);
        const tabData = mapApiResponseToTableData(res);
        setMediaData(tabData);
        setFilteredTableData(tabData);
      })
      .catch(err => {
        console.log("error: ", err);
        setErrorMessage("Not authorized");
        setMediaData([]);
      });
  };

  const reloadMediaList = () => {
    setVideoPreviewSection(false);
    setMediaData(false);
    setFilteredTableData(false);
    fetchMediaDetails(subscriberId);
  };

  const mapApiResponseToTableData = res => {
    let dataList: MediaTableRow[];
    dataList = [];
    let filteredArray: MediaMetadata[];
    filteredArray = [];
    filteredArray = res.filter(function (media) {
      return media.uploadedAt;
    });
    let sortedRes = filteredArray.sort(
      (a, b) =>
        parseFloat(b?.uploadedAt as string) -
        parseFloat(a?.uploadedAt as string)
    );
    for (const media of sortedRes) {
      if (!media.uploadedAt) {
        continue;
      }
      let tableRow = {
        key: media.mediaId,
        id: media.mediaId,
        clientKey: media.clientKey ? media.clientKey : "",
        thumbnailUrl: media.thumbnailUrl,
        mimeType: media.mimeType,
        type: media.mimeType ? media.mimeType.split("/")[0] : "-",
        uploadedTime: media.uploadedAt
          ? format(
            new Date(parseInt(media.uploadedAt) * 1000),
            "MMM d yyyy 'AT' h:m a"
          ).toUpperCase()
          : "-",
        transcodingStatus:
          media.transcoding && media.transcoding.status
            ? media.transcoding.status
            : "-",
        watermarkingStatus:
          media.watermarking && media.watermarking.status
            ? media.watermarking.status
            : "-",
        transcribingStatus:
          media.transcribing && media.transcribing.status
            ? media.transcribing.status
            : "-"
      };

      dataList.push(tableRow);
    }
    return dataList;
  };

  const handleMultiEffectProfileSelect = e => {
    const updatedOptions = [...e.target.options]
      .filter(option => option.selected)
      .map(x => x.value);
    setEffectProfile(updatedOptions);
  };

  const openVideoPreview = id => {
    if (fullMediaInfo) {
      const selected = fullMediaInfo.find(o => o.mediaId === id);
      if (
        activeMedia &&
        selected?.mediaId === JSON.parse(activeMedia).mediaId
      ) {
        setVideoPreviewSection(!videoPreviewSection);
      }
      if (selected) setActiveMedia(JSON.stringify(selected));
    } else {
      setVideoPreviewSection(!videoPreviewSection);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "thumbnailUrl, id",
      render: (text, record) =>
        record["thumbnailUrl"] ? (
          <img
            src={record["thumbnailUrl"]}
            alt={record["id"]}
            className="table-thumbnail"
            onClick={() =>
              navigate(`/subscribers/${subscriberId}/media/${record["id"]}`)
            }
          />
        ) : (
          <Link to={`/subscribers/${subscriberId}/media/${record["id"]}`}>
            {record["id"]}
          </Link>
        )
    },
    {
      title: "Type",
      dataIndex: "type"
    },
    {
      title: "Transcoding",
      dataIndex: "transcodingStatus",
      render(text, record) {
        return {
          props: {
            style: {
              color: processingStatusColor(text)
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Watermarking",
      dataIndex: "watermarkingStatus",
      render(text, record) {
        return {
          props: {
            style: {
              color: processingStatusColor(text)
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Transcribing",
      dataIndex: "transcribingStatus",
      render(text, record) {
        return {
          props: {
            style: {
              color: processingStatusColor(text)
            }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Uploaded At",
      dataIndex: "uploadedTime"
    },
    {
      title: "",
      dataIndex: "deleteMedia",
      width: "5%",
      render: (text, record) =>
        !deleteLoader ? (
          <DeleteOutlined
            className="delete-icon"
            onClick={() => handleDeleteMedia(record)}
          />
        ) : (
          <LoadingOutlined className="delete-loader" />
        )
    }
  ];

  const handleDateChange = (
    date
  ) => {
    console.log('Selected Time: ', date);
    // console.log('Formatted Selected Time: ', dateString);
    let unixTs = moment(date).unix()
    console.log(unixTs)
    console.log(typeof unixTs)
  };

  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', value);
  };

  const handleStartDateUnixSelected = (date) => {
    console.log('Selected Time: ', date);
    setStartDate(date)
    setStartDateUnix(moment(date).unix())
  };

  const handleEndDateUnixSelected = (date) => {
    console.log('Selected Time: ', date);
    setEndDate(date)
    setEndDateUnix(moment(date).unix())
  };

  const handleFilterToggle = (showFilters) => {
    if (showFilters) { // reset case
      setEndDateUnix(null)
      setStartDateUnix(null)
      setStartDate(null)
      setEndDate(null)
      fetchMediaDetails(subscriberId)
    }
    setShowFilters(!showFilters)
  }

  const handleFilterApply = () => {
    if (!endDateUnix || !startDateUnix) {
      alert("Please select both dates")
    }
    else if (endDateUnix > startDateUnix) {
      alert("End date can't be greater than start date")
    }
    else {
      fetchMediaDetails(subscriberId, startDateUnix, endDateUnix)
    }
  }

  console.log(startDateUnix)
  console.log(endDateUnix)

  return (
    <div className="display_section">
      <div className="App">
        <header className="App-header">
          <Row justify="center">
            <Col
              className="upload_dropdown"
              onClick={() => setShowUploadForm(!showUploadForm)}
            >
              Upload Media File{" "}
              {!showUploadForm ? <CaretDownOutlined /> : <CaretUpOutlined />}
            </Col>
          </Row>
          {showUploadForm && (
            <div className="upload_form">
              <MediaInput
                labelText="Resolution Profile: "
                type="text"
                placeholder="e.g 720p"
                updateStateFunction={setResolutionProfile}
              />
              <MediaInput
                labelText="Transcription Language: "
                type="text"
                placeholder="e.g en-US"
                updateStateFunction={setTranscriptionLanguage}
              />
              <div className="form_row">
                <label>Effect Profile: </label>
                <select
                  onChange={e => handleMultiEffectProfileSelect(e)}
                  className="form_input"
                  multiple
                >
                  {subscriberProfileDropdownOptions &&
                    subscriberProfileDropdownOptions.map(profile => {
                      return (
                        <option key={profile.value} value={profile.value}>
                          {profile.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <MediaInput
                labelText="Watermark Text: "
                type="text"
                placeholder="e.g NativeVideo"
                updateStateFunction={setWatermarkText}
              />
              <MediaInput
                labelText="Gif Duration: "
                type="text"
                placeholder="in seconds"
                updateStateFunction={setGifDuration}
              />
              <MediaInput
                labelText="Gif Start time: "
                type="text"
                placeholder="in seconds"
                updateStateFunction={setGifStartTime}
              />
              <div className="form_row">
                <label>Select a Media file: </label>
                <input
                  onChange={handleFileSelected}
                  accept=".mp3, .mp4, .quicktime, .mov, .flv, .png, .mpg, .jpeg, .jpg, .ogg, .wav, .avi, .zip, .webm, .webm;codecs_vp9;, .webm;codecs=vp9"
                  className="form_input_file"
                  type="file"
                />
              </div>
              <div className="form_row">
                <label>Select a Thumbnail Image: </label>
                <input
                  disabled={!file}
                  onChange={handleTHumbnailFileSelected}
                  accept=".png, .mpg, .jpeg, .jpg, .heic"
                  className="form_input_file"
                  type="file"
                />
              </div>
              <div className="form_row">
                <label>Buffy Halved: </label>
                <Switch
                  checked={useBuffyHalved}
                  onChange={() => setUseBuffyHalved(!useBuffyHalved)}
                />
              </div>
              <Row className="upload_row" justify="center" gutter={[20, 20]}>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    disabled={!file}
                    className="upload_form_button"
                    onClick={() => handleFileUpload()}
                  >
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    className="upload_form_button"
                    disabled={!file}
                    onClick={() => handleCancelRequest()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row
                className="upload_progress_row"
                justify="center"
                gutter={[20, 20]}
              >
                {loading && <Col>Media Upload Progress: {uploadPercentage}%</Col>}
              </Row>
            </div>
          )}
        </header>

        {filteredTableData ? (
          <div className="video_listing">
            <h1 className="heading">
              Your media files{" "}
              <span className="reload_table" onClick={() => reloadMediaList()}>
                <ReloadOutlined />
              </span>
            </h1>
            {selectedMediaRows.length > 0 && (
              <Row className="multiple_delete_button">
                <Col>
                  <Button
                    size="large"
                    className="upload_dropdown"
                    loading={deleteBtnLoading}
                    onClick={() => handleMultipleDelete()}
                  >
                    Delete Selected Media
                  </Button>
                </Col>
                {selectedMediaRows.length === 1 && (
                  <>
                    <Col>
                      <Button
                        size="large"
                        className="upload_dropdown"
                        loading={streamBtnLoading}
                        onClick={() => handleReTriggerNotification("streams")}
                      >
                        Streams Notification
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size="large"
                        className="upload_dropdown"
                        loading={transcriptionBtnLoading}
                        onClick={() => handleReTriggerNotification("transcription")}
                      >
                        Transcription Notification
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            )}
            <div className="search_div">
              <input
                onChange={e => handleSearchByMediaId(e.target.value)}
                className="search_box"
                placeholder="Search by media id, key"
                type="text"
              />
              <p className="filter_label" onClick={() => handleFilterToggle(showFilters)}>
                {showFilters ? 'Reset Filters' : 'Show Filters'}
              </p>
            </div>
            {showFilters && (
              <div className="time_filter_row">
                <DatePicker value={startDate} placeholder="Start Date" showTime onOk={handleStartDateUnixSelected} />
                <DatePicker value={endDate} placeholder="End Date" showTime onOk={handleEndDateUnixSelected} />

                <Button
                  size="small"
                  className="filter_apply"
                  loading={transcriptionBtnLoading}
                  onClick={() => handleFilterApply()}
                >
                  Apply
                </Button>
              </div>
            )}
            <div className="media_listing_section">
              <div className="tablediv">
                <Table
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection
                  }}
                  columns={columns}
                  dataSource={filteredTableData}
                  scroll={{ y: "48vh" }}
                />
              </div>
              {videoPreviewSection && (
                <div className="previewdiv">
                  <VideoPreview
                    mediaInfo={activeMedia}
                    subscriberId={subscriberId}
                  />
                </div>
              )}
            </div>
          </div>
        ) : errorMessage !== "" ? (
          <div className="preview">{errorMessage}</div>
        ) : (
          <div className="loader-div">
            <LoadingOutlined className="loading-icon" />
          </div>
        )}
      </div>
    </div>
  );
}

export default withMessage(Media);

const processingStatusColor = text => {
  return text === "completed"
    ? "green"
    : text === "failed"
      ? "red"
      : text === "processing"
        ? "orange"
        : "gray";
};
