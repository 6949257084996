import React, { useState, useEffect, useContext } from "react";
import EffectProfile from "../components/EffectProfile";
import NativeVideo from "nativevideo-sdk-js";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { SubscribersContext } from "../context/subscribersContext";
import "./App.scss";
import { SubscriberProfile } from "types/subscriberProfile";
import { RegionContext } from "../context/regionContext";

function SubscriberEffectProfiles() {
  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [
    subscriberProfileDropdownOptions,
    setSubscriberProfileDropdownOptions
  ] = useState<{}[]>();
  const [subscriberProfiles, setSubscriberProfiles] = useState<
    SubscriberProfile[]
  >();
  const [subscriberId, setSubscriberId] = useState<string>();
  let params = useParams();

  useEffect(() => {
    if (params) setSubscriberId(params.id);
  }, [params]);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }
  }, [subscribersData, subscriberId, regionUrl]);

  const mapProfileApiToDropdownOptions = res => {
    let select: {
      value: string;
      label: string;
    }[];
    select = [];
    for (const profile of res) {
      let option = {
        value: "",
        label: ""
      };
      option.value = profile.profileId;
      option.label = profile.watermark.label
        ? profile.watermark.label
        : profile.profileId;

      select.push(option);
    }
    return select;
  };

  const fetchSubscriberProfiles = () => {
    nvObj
      ?.getSubscriberProfiles()
      .then((res: SubscriberProfile[]) => {
        const sorted_res = res.sort(
          (a, b) => parseFloat(b.updatedAt) - parseFloat(a.updatedAt)
        );
        setSubscriberProfileDropdownOptions(
          mapProfileApiToDropdownOptions(sorted_res)
        );
        setSubscriberProfiles(
          sorted_res.sort(
            (a, b) => parseFloat(b.updatedAt) - parseFloat(a.updatedAt)
          )
        );
      })
      .catch(err => {
        console.log("error: ", err);
      });
  };

  useEffect(() => {
    if (subscriberId && nvObj) fetchSubscriberProfiles(); // fetch all subscriber profiles for this subscriber
  }, [nvObj, subscriberId]);

  return (
    <Layout>
      <EffectProfile
        subscriberProfiles={subscriberProfiles}
        subscriberId={subscriberId}
        nvObj={nvObj}
      />
    </Layout>
  );
}

export default SubscriberEffectProfiles;
