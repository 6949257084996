import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import VideoJS from "./VideoJS";
import videojs from "video.js";
import "../components.scss";

function VideoDetailsRow(props) {
  const { url, label, videoDetails, overlayUrl, showDynamicOverlay, transcriptionBtnLoading, streamBtnLoading, handleReTriggerNotification } = props;

  const [showAfterBuffed, setShowAfterBuffed] = useState(showDynamicOverlay)

  const playerRef = React.useRef<videojs.Player>();
  let buffedTs = 0
  let currentTs = 0
  let fixedTs = 0

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    // responsive: true,
    preload: "auto",
    // fluid: true,
    sources: [
      {
        src: url,

        type: "application/x-mpegURL"
      }
    ],
    html5: {
      nativeAudioTracks: true,
      nativeVideoTracks: true,
      nativeTextTracks: true
    }
  };

  const handlePlayerReady = player => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });

    player.on('timeupdate', function () {
      buffedTs = player.bufferedEnd()
      currentTs = player.currentTime()
    });
  };


  useEffect(() => {
    if (!showDynamicOverlay) {
      fixedTs = buffedTs
    }
  }, [showDynamicOverlay]);

  useEffect(() => {
    if (fixedTs > 0 && currentTs >= fixedTs) {
      setShowAfterBuffed(false)
    }
  }, [currentTs, fixedTs]);


  return (
    <div className="media_details">
      <Row justify="center" className="video_player_row">
        <Col className="vid_col">
          {overlayUrl && (showDynamicOverlay || showAfterBuffed) && (<img src={overlayUrl} className="image-overlay" alt="" />)}
          <VideoJS
            className="videoJs-player"
            options={videoJsOptions}
            onReady={handlePlayerReady}
          />
        </Col>
      </Row>
      <Row className="video_detail_row">
        <Col className="label_col">
          <div>
            <span className="media_label">{label}</span>
          </div>
          <div>
            <Button
              size="large"
              className="notification_btn"
              loading={streamBtnLoading}
              onClick={() => handleReTriggerNotification("streams")}
            >
              Streams Notification
            </Button>
          </div>
          <div>
            <Button
              size="large"
              className="notification_btn"
              loading={transcriptionBtnLoading}
              onClick={() => handleReTriggerNotification("transcription")}
            >
              Audio Notification
            </Button>
          </div>
        </Col>
        <Col className="detail_col">
          {videoDetails && (
            <>
              {videoDetails.fileSize && (
                <div>
                  <span className="detail_label">File size: </span>{" "}
                  {videoDetails.fileSize} mb
                </div>
              )}
              {videoDetails.processingTime && (
                <div>
                  <span className="detail_label">Processing time: </span>{" "}
                  {videoDetails.processingTime}
                </div>
              )}

              {videoDetails.resolution && (
                <div>
                  <span className="detail_label">Resolution: </span>{" "}
                  {videoDetails.resolution}
                </div>
              )}

              {videoDetails.duration && (
                <div>
                  <span className="detail_label">Media duration: </span>{" "}
                  {videoDetails.duration} sec
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default VideoDetailsRow;
