import React, { useState, createContext } from "react";

const AsideContext = createContext<any>("");

const AsideContextProvider = ({ children }) => {
    const [searchText, setSearchText] = useState("");
    const [selectedSubscriber, setSelectedSubscriber] = useState("");

    return (
        <AsideContext.Provider value={{ searchText, setSearchText, selectedSubscriber, setSelectedSubscriber }}>
            {children}
        </AsideContext.Provider>
    );
};

export { AsideContext, AsideContextProvider };
