import React from "react";
import Login from "../components/Login";
import "./App.scss";

function MediaDetails() {
  return (
    <div className="App">
      <Login />
    </div>
  );
}

export default MediaDetails;
