import React, { useContext } from "react";
import Layout from "../components/Layout/Layout";
import SystemHealthComp from "components/SystemHealth";
import "./App.scss";

function SystemHealth() {
  return (
    <Layout>
      <SystemHealthComp />
    </Layout>
  );
}

export default SystemHealth;
