import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent
} from "react-pro-sidebar";
import {
  BarChartOutlined,
  VideoCameraFilled,
  ProfileOutlined,
  UserOutlined,
  DollarCircleOutlined,
  HeartOutlined,
  AppstoreOutlined,
  PieChartOutlined,
  VideoCameraAddOutlined
} from "@ant-design/icons";

const Aside = ({
  collapsed,
  rtl,
  toggled,
  handleClickMedia,
  handleClickOverview,
  handleClickProfile,
  handleClickCost,
  handleClickUsage,
  handleClickNavigation,
  formattedsubscriberData,
  selectedOrgName,
  setSelectedOrgName,
  handleOrgClick,
  activeTab
}) => {
  return (
    <ProSidebar
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      key="prosidebar"
    >
      <SidebarContent key="SidebarContent">
        <Menu iconShape="circle" key="main-Menu">
          <MenuItem
            key={"Dashboard"}
            icon={<PieChartOutlined />}
            onClick={() => handleClickNavigation("/")}
          >
            Dashboard
          </MenuItem>
          {formattedsubscriberData &&
            Object.keys(formattedsubscriberData).map(organizationName => {
              return (
                <SubMenu
                  icon={<UserOutlined />}
                  title={organizationName}
                  key={organizationName + "sub5"}
                  open={selectedOrgName === organizationName}
                  onClick={() => handleOrgClick(organizationName)}
                  className={
                    formattedsubscriberData[organizationName].length === 1 &&
                      formattedsubscriberData[organizationName][0].isSandbox
                      ? "sandbox-client"
                      : ""
                  }
                >
                  {formattedsubscriberData[organizationName] &&
                    formattedsubscriberData[organizationName].map(
                      subscriber => {
                        if (
                          formattedsubscriberData[organizationName].length === 1
                        ) {
                          return (
                            <>
                              <MenuItem
                                onClick={() =>
                                  handleClickOverview(subscriber.subscriberId)
                                }
                                icon={<AppstoreOutlined />}
                                key={subscriber.subscriberId + "-overview"}
                                className={
                                  activeTab === "overview" ? "active-tab" : ""
                                }
                              >
                                {" "}
                                Overview{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickMedia(subscriber.subscriberId)
                                }
                                icon={<VideoCameraFilled />}
                                key={subscriber.subscriberId + "-media"}
                                className={
                                  activeTab === "media" ? "active-tab" : ""
                                }
                              >
                                {" "}
                                Media{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickProfile(subscriber.subscriberId)
                                }
                                icon={<ProfileOutlined />}
                                key={subscriber.subscriberId + "-ep"}
                                className={
                                  activeTab === "effect_profiles"
                                    ? "active-tab"
                                    : ""
                                }
                              >
                                {" "}
                                Effect Profiles{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickCost(subscriber.subscriberId)
                                }
                                icon={<DollarCircleOutlined />}
                                key={subscriber.subscriberId + "-cost"}
                                className={
                                  activeTab === "cost" ? "active-tab" : ""
                                }
                              >
                                {" "}
                                Cost{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickUsage(subscriber.subscriberId)
                                }
                                icon={<BarChartOutlined />}
                                key={subscriber.subscriberId + "-stats"}
                                className={
                                  activeTab === "usage" ? "active-tab" : ""
                                }
                              >
                                {" "}
                                Usage Stats{" "}
                              </MenuItem>
                            </>
                          );
                        } else {
                          return (
                            <SubMenu
                              icon={<UserOutlined />}
                              title={
                                subscriber.nickname
                                  ? subscriber.nickname
                                  : subscriber.salesforceOrganizationId
                              }
                              className={
                                subscriber.isSandbox ? "sandbox-client" : ""
                              }
                              key={subscriber.salesforceOrganizationId + "sub5"}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleClickOverview(subscriber.subscriberId)
                                }
                                icon={<BarChartOutlined />}
                                key={subscriber.subscriberId + "-overview"}
                              >
                                {" "}
                                Overview{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickMedia(subscriber.subscriberId)
                                }
                                icon={<VideoCameraFilled />}
                                key={subscriber.subscriberId + "-media"}
                              >
                                {" "}
                                Media{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickProfile(subscriber.subscriberId)
                                }
                                icon={<ProfileOutlined />}
                                key={subscriber.subscriberId + "-ep"}
                              >
                                {" "}
                                Effect Profiles{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickCost(subscriber.subscriberId)
                                }
                                icon={<DollarCircleOutlined />}
                                key={subscriber.subscriberId + "-cost"}
                              >
                                {" "}
                                Cost{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleClickUsage(subscriber.subscriberId)
                                }
                                icon={<BarChartOutlined />}
                                key={subscriber.subscriberId + "-stats"}
                              >
                                {" "}
                                Usage Stats{" "}
                              </MenuItem>
                            </SubMenu>
                          );
                        }
                      }
                    )}
                </SubMenu>
              );
            })}
          <MenuItem
            key={"cost_estimator"}
            icon={<DollarCircleOutlined />}
            onClick={() => handleClickNavigation("/cost-estimator")}
          >
            Cost Estimator
          </MenuItem>
          <MenuItem
            key={"system_health"}
            icon={<HeartOutlined />}
            onClick={() => handleClickNavigation("/system-health")}
          >
            System Health
          </MenuItem>
          <MenuItem
            key={"system_health"}
            icon={<VideoCameraAddOutlined />}
            onClick={() => handleClickNavigation("/in-meeting-new")}
          >
            Video Call Test
          </MenuItem>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default Aside;
