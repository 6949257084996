"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * [[Attendee]] contains the information of an attendee.
 */
class Attendee {
}
exports.default = Attendee;
