import React, { useContext } from "react";
import videojs from "video.js";
import { MediaKeysContext } from "../../context/mediaKeysContext";
import { MediaKeysContextDynamicContent } from "../../context/mediaKeysContextDynamic";
import _ from "videojs-contrib-quality-levels";
import "videojs-contrib-quality-levels";
import qualitySelector from "videojs-hls-quality-selector";
import "video.js/dist/video-js.css";
import "../components.scss";

export const VideoJS = props => {
  let videoRef = React.useRef<HTMLVideoElement>(null);
  let playerRef = React.useRef<videojs.Player>();
  const { options, onReady } = props;
  const { authToken } = useContext(MediaKeysContext);
  const { tokenTempObjects } = useContext(MediaKeysContextDynamicContent);

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement || !authToken || tokenTempObjects === null) return;
      videojs.Vhs.xhr.beforeRequest = function (options) {
        if (!options.uri.includes("temporary_objects/")) {
          options.uri = options.uri + authToken;
        } else {
          options.uri = options.uri + tokenTempObjects;
        }
        return options;
      };
      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, authToken, tokenTempObjects]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    if (player) {
      console.log("inside quality seldcto");

      player.on('timeupdate', function () {
        // use this function to get buffered time 
        // console.log(player.currentTime());
        // console.log("Buffer end::", player.bufferedEnd())
      });

      player.hlsQualitySelector = qualitySelector;
      player.hlsQualitySelector({ displayCurrentQuality: true });
    }

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        // controls
        // preload="auto"
        // width="640"
        // height="500"
        className="video-js videoJs-player "
      />
    </div>
  );
};

export default VideoJS;
