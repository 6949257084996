import React from 'react';
import { message } from 'antd';

export interface WithMessageProps {
    showMessage: (message: string, messageType?: 'success' | 'error') => void;
}

const withMessage = <OriginalProps extends object>(
    Component: React.ComponentType<OriginalProps & WithMessageProps>
) => {
    const ComponentWithMessage: React.FC<OriginalProps> = (props: OriginalProps) => {
        const showMessage = (messageText: string, messageType?: 'success' | 'error') => {
            switch (messageType) {
                case 'success':
                    message.success(messageText);
                    break;
                case 'error':
                    message.error(messageText);
                    break;
                default:
                    message.info(messageText);
            }
        };

        return <Component {...props} showMessage={showMessage} />;
    };

    return ComponentWithMessage;
};

export default withMessage;
