import React, { useState, useEffect, useContext } from "react";
import SubscriberOverview from "../components/SubscriberOverview";
import NativeVideo from "nativevideo-sdk-js";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { SubscribersContext } from "../context/subscribersContext";
import "./App.scss";
import { SubscriberData } from "types/subscriberData";
import { RegionContext } from "../context/regionContext";

function SubscriberMedia() {
  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [
    selectedSubscriberData,
    setSelectedSubscriberData
  ] = useState<SubscriberData | null>(null);
  const [subscriberId, setSubscriberId] = useState<string>();
  let params = useParams();

  useEffect(() => {
    if (params) setSubscriberId(params.id);
  }, [params]);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }

    if (subscribersData && subscriberId) {
      setSelectedSubscriberData(
        subscribersData.find(o => o.subscriberId === subscriberId)
      );
    }
  }, [subscribersData, subscriberId, regionUrl]);

  return (
    <Layout>
      {selectedSubscriberData && (
        <SubscriberOverview subscriberData={selectedSubscriberData} />
      )}
    </Layout>
  );
}

export default SubscriberMedia;
