import React from "react";
import "./util.scss";
import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  SyncOutlined
} from "@ant-design/icons";

function HealthStatus(props) {
  const { status, label } = props;
  return (
    <div className="health_status_div">
      <div className="label">{label}</div>
      {status === "healthy" ? (
        <div className="status healthy">
          <CheckCircleTwoTone twoToneColor="#52c41a" /> Working
        </div>
      ) : status === "faulty" ? (
        <div className="status faulty">
          <CloseCircleOutlined twoToneColor="red" /> Not Working
        </div>
      ) : status === "loading" ? (
        <div className="status loading">
          <SyncOutlined spin /> Processing
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HealthStatus;
