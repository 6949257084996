"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const SimulcastTransceiverController_1 = require("./SimulcastTransceiverController");
class SimulcastContentShareTransceiverController extends SimulcastTransceiverController_1.default {
    constructor(logger, browserBehavior) {
        super(logger, browserBehavior);
        let scale = 2;
        this.videoQualityControlParameterMap = new Map();
        for (let i = 0; i < SimulcastContentShareTransceiverController.NAME_ARR_ASCENDING.length; i++) {
            const ridName = SimulcastContentShareTransceiverController.NAME_ARR_ASCENDING[i];
            this.videoQualityControlParameterMap.set(ridName, {
                rid: ridName,
                scaleResolutionDownBy: scale,
                maxBitrate: SimulcastContentShareTransceiverController.BITRATE_ARR_ASCENDING[i] * 1000,
            });
            scale = scale / 2;
        }
    }
    // Note: `scaleResolutionDownBy` has only been tested with values 1, 2, and 4.
    setEncodingParameters(encodingParamMap) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._localCameraTransceiver || this._localCameraTransceiver.direction !== 'sendrecv') {
                return;
            }
            const sender = this._localCameraTransceiver.sender;
            const newEncodingParams = Array.from(encodingParamMap.values());
            if (newEncodingParams.length <= 0) {
                return;
            }
            const oldParam = sender.getParameters();
            if (!oldParam.encodings) {
                oldParam.encodings = newEncodingParams;
            }
            else {
                for (let i = 0; i < oldParam.encodings.length; i++) {
                    if (oldParam.encodings[i].rid === SimulcastTransceiverController_1.default.LOW_LEVEL_NAME) {
                        this.copyEncodingParams(encodingParamMap.get(SimulcastTransceiverController_1.default.LOW_LEVEL_NAME), oldParam.encodings[i]);
                    }
                    if (oldParam.encodings[i].rid === SimulcastTransceiverController_1.default.HIGH_LEVEL_NAME) {
                        this.copyEncodingParams(encodingParamMap.get(SimulcastTransceiverController_1.default.HIGH_LEVEL_NAME), oldParam.encodings[i]);
                    }
                }
            }
            yield sender.setParameters(oldParam);
            this.logVideoTransceiverParameters();
        });
    }
}
exports.default = SimulcastContentShareTransceiverController;
SimulcastContentShareTransceiverController.NAME_ARR_ASCENDING = ['low', 'hi'];
SimulcastContentShareTransceiverController.BITRATE_ARR_ASCENDING = [300, 1200];
