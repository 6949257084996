import React, { useContext, useState } from "react";
import "./App.scss";
import { regions } from "../constants";
import { Spin } from "antd";
import { RegionContext } from "../context/regionContext";
import useAuth from "../context/useAuth";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import OTPInput from "components/OTPInputComponent";

function AuthCallback() {
  let [searchParams] = useSearchParams();
  let { region } = useParams();
  const navigate = useNavigate();

  const { login } = useAuth();

  const authCode = searchParams.get("code");
  const [showSpinner, setShowSpinner] = useState(false);
  const { setRegionUrl, setRegionKey } = useContext(RegionContext);

  const handleOtpChange = otp => {
    if (otp.length === 6) {
      setShowSpinner(true);
      invokeLoginFunction(otp);
    }
  };

  const invokeLoginFunction = otp => {
    if (region) {
      const currentRegion = regions.find(o => o.key === region);
      if (currentRegion) {
        setRegionUrl(currentRegion.value);
        setRegionKey(currentRegion.xApiKeyName as string);
        localStorage.setItem("region", currentRegion.value);

        let data = {
          authorizationCode: authCode,
          totp: otp,
          regionCode: region
        };
        login(data, currentRegion.xApiKeyName, currentRegion.value)
          .then(() => {
            navigate("/");
            setShowSpinner(false);
          })
          .catch(err => {
            navigate("/login");
            setShowSpinner(false);
            console.log("error: ", err);
          });
      }
    }
  };
  return (
    <div className="page_loading">
      {showSpinner ? (
        <Spin size="large" />
      ) : (
        <>
          <div>Enter OTP</div>
          <OTPInput
            autoFocus
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={otp => handleOtpChange(otp)}
          />
        </>
      )}
    </div>
  );
}

export default AuthCallback;
