import React, { useEffect } from 'react';

const RedirectComponent = () => {
    useEffect(() => {
        window.location.href = 'https://auth-alfred.nativevideo.co/oauth2/authorize';
    }, []);

    return null;
};

export default RedirectComponent;
