import React, { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { RegionContext } from "../context/regionContext";
import "./App.scss";
import CostEstimatorComp from "components/CostEstimator";
import MonthlyBill from "components/MonthlyBill";
import {
  awsServiceRegionalCost,
  regionalEcrCost,
  monthlyHours,
  globalWafCost,
  emptyLambdaDuration,
  gbInMb,
  mediaAuthorizerFunctionDuration,
  imageThumbnailSize,
  gifThumbnailSize,
  notificationFunctionDuration,
  generateUploadTokenFunctionDuration,
  orchestrationfunctionDuration
} from "../constants";
import {
  lambdaMonthlyCost,
  NatGatewayHourlyCost,
  s3StorageCost,
  efsCost,
  dynamoDbCost,
  eventBridgeCost,
  restApiGatewayCost,
  socketApiGatewayCost,
  transferAccelerationCost,
  stepFunctionsCost,
  NatGatewayPerGbCost,
  transcribeCost,
  sentimentAnalysisCost,
  estimateLambdaExecutionTime,
  monthlyPlaybackProcessingCost
} from "../util";
import { RegionalPricing } from "types/regionalPricing";

function MediaDetails() {
  const { regionKey } = useContext(RegionContext);

  const [regionalPricing, setRegionalPricing] = useState<RegionalPricing>(
    awsServiceRegionalCost[0]
  );
  const [monthlyComputeCost, setMonthlyComputeCost] = useState(0);
  const [monthlyDbCost, setMonthlyDbCost] = useState(0);
  const [monthlyStorageCost, setMonthlyStorageCost] = useState(0);
  const [monthlyTotalCost, setMonthlyTotalCost] = useState(0);
  const [monthlyCdnCost, setMonthlyCdnCost] = useState(0);
  const [monthlyOtherCost, setMonthlyOtherCost] = useState(0);
  const [monthlyVpcCost, setMonthlyVpcCost] = useState(0);
  const [regionalInstanceCost, setRegionalInstanceCost] = useState(0);

  useEffect(() => {
    if (regionKey) {
      let selectedRegion = awsServiceRegionalCost.find(
        i => i.regionId === regionKey
      );
      if (selectedRegion) {
        setRegionalPricing(selectedRegion);
      }

      let natGatewayCost = NatGatewayHourlyCost(regionalPricing, monthlyHours);
      let instanceRunningCost =
        natGatewayCost + regionalEcrCost + globalWafCost;
      setRegionalInstanceCost(instanceRunningCost);
    }
  }, [regionKey]);

  useEffect(() => {
    if (regionalPricing) {
      let natGatewayCost = NatGatewayHourlyCost(regionalPricing, monthlyHours);
      let instanceRunningCost =
        natGatewayCost + regionalEcrCost + globalWafCost;
      setRegionalInstanceCost(instanceRunningCost);
    }
  }, [regionalPricing]);

  const monthlyUploadProcessingCost = (
    uploadCount: number,
    avgMediaDurationInSec: number,
    totalUploadedSizeInGbs: number,
    watermarkEnabled: boolean,
    thumbnailEnabled: boolean,
    transcribingEnabled: boolean,
    sentimentAnalysisEnabled: boolean,
    externalStorageEnabled: boolean,
    multiStreamSegmentationEnabled: boolean,
    singleStreamSegmentationEnabled: boolean,
    avgTranscodingProcessingTime?: number,
    avgWatermarkingProcessingTime?: number,
    avgThumbnailingProcessingTime?: number,
    avgMediaUploadTime?: number,
    avgTranscriptionCharacters?: number
  ) => {
    let computeCost = 0,
      dbCost = 0,
      storageCost = 0,
      totalCost = 0,
      cdnCost = 0,
      otherCost = 0,
      vpcCost = 0;

    // cost of authorizer function
    let authCost = lambdaMonthlyCost(
      regionalPricing,
      uploadCount,
      128 * gbInMb,
      mediaAuthorizerFunctionDuration
    );
    computeCost += authCost;

    let avgMediaSize = totalUploadedSizeInGbs / uploadCount; // avg media size in gbs

    let dbGetCount = 4;
    let dbPutCount = 7;
    let s3GetCount = 2;
    let s3PutCount = 1;
    let efsStorageCount = 2;
    let s3GBStored = avgMediaSize * 2; // orignal + transcoded
    let efsGbStored = avgMediaSize * 2;

    // avg video segments = 10
    // max 5 resolutions
    if (multiStreamSegmentationEnabled) {
      dbPutCount += 2;
      s3PutCount += 50;
      efsStorageCount += 7; // 5 res and 2 fragments
      s3GBStored += 5 * avgMediaSize;
      efsGbStored += 7 * avgMediaSize;
    }
    if (singleStreamSegmentationEnabled) {
      dbPutCount += 2;
      s3PutCount += 10;
      efsStorageCount += 3;
      s3GBStored += avgMediaSize;
      efsGbStored += 3 * avgMediaSize;
    }

    if (watermarkEnabled) {
      dbGetCount += 2;
      dbPutCount += 3;
      s3GetCount += 2;
      s3PutCount += 1;
      efsStorageCount += 2;
      s3GBStored += avgMediaSize;
      efsGbStored += avgMediaSize * 2.5; // can be > 2 if multiple effect profiles are used

      if (multiStreamSegmentationEnabled) {
        dbPutCount += 1;
        s3GetCount += 1;
        s3PutCount += 40;
        efsStorageCount += 7;
        s3GBStored += avgMediaSize * 5;
        efsGbStored += avgMediaSize * 7;
      }
      if (singleStreamSegmentationEnabled) {
        dbPutCount += 1;
        s3GetCount += 1;
        s3PutCount += 10;
        efsStorageCount += 3;
        s3GBStored += avgMediaSize;
        efsGbStored += 3 * avgMediaSize;
      }
    }

    if (thumbnailEnabled) {
      dbGetCount += 2;
      dbPutCount += 3;
      s3GetCount += 1;
      s3PutCount += 16;
      efsStorageCount += 16;
      s3GBStored += imageThumbnailSize * 8 + gifThumbnailSize * 8;
      efsGbStored += imageThumbnailSize * 8 + gifThumbnailSize * 8;
    }

    if (transcribingEnabled) {
      dbGetCount += 2;
      dbPutCount += 6;
      s3GetCount += 1;
    }

    // requests/storage for 1 month
    s3GetCount *= uploadCount;
    s3PutCount *= uploadCount;
    dbGetCount *= uploadCount;
    dbPutCount *= uploadCount;
    efsGbStored *= uploadCount;
    s3GBStored *= uploadCount;

    let monthlyS3Cost = 0;
    if (!externalStorageEnabled) {
      monthlyS3Cost = s3StorageCost(
        regionalPricing,
        s3GBStored,
        s3GetCount,
        s3PutCount
      );
    }
    let monthlyEfsCost = efsCost(regionalPricing, efsGbStored);
    // assuming one video takes a max of 1 hour to process so efs stores files only for 1 hour
    let efsGBHoursCost = monthlyEfsCost / (30 * 24);

    storageCost += monthlyS3Cost + efsGBHoursCost;

    //  assuming each row is 1kb
    let monthlyDbCost = dynamoDbCost(
      regionalPricing,
      uploadCount,
      dbPutCount,
      dbGetCount
    );
    dbCost += monthlyDbCost;

    let monthlyEventBridgeCost = eventBridgeCost(regionalPricing, uploadCount);
    otherCost += monthlyEventBridgeCost;

    // assuming average upload takes 30 seconds
    let monthlyApiGwCost =
      restApiGatewayCost(uploadCount) +
      socketApiGatewayCost(
        regionalPricing,
        2 * uploadCount,
        avgMediaUploadTime ? avgMediaUploadTime : 30,
        uploadCount
      );
    otherCost += monthlyApiGwCost;

    let monthlyTrasnferAccelerationCost = transferAccelerationCost(
      regionalPricing,
      totalUploadedSizeInGbs
    );
    cdnCost += monthlyTrasnferAccelerationCost;

    let transcodingLambdaCost = avgTranscodingProcessingTime
      ? lambdaMonthlyCost(
          regionalPricing,
          uploadCount,
          10,
          avgTranscodingProcessingTime
        )
      : lambdaMonthlyCost(
          regionalPricing,
          uploadCount,
          10,
          estimateLambdaExecutionTime(avgMediaDurationInSec, "transcode")
        );
    computeCost += transcodingLambdaCost;

    let watermarkLambdaCost = 0;
    if (watermarkEnabled) {
      watermarkLambdaCost = avgWatermarkingProcessingTime
        ? lambdaMonthlyCost(
            regionalPricing,
            uploadCount,
            10,
            avgWatermarkingProcessingTime
          )
        : lambdaMonthlyCost(
            regionalPricing,
            uploadCount,
            10,
            estimateLambdaExecutionTime(avgMediaDurationInSec, "watermark")
          );
      computeCost += watermarkLambdaCost;
    } else {
      watermarkLambdaCost = lambdaMonthlyCost(
        regionalPricing,
        uploadCount,
        10,
        emptyLambdaDuration
      );
      computeCost += watermarkLambdaCost;
    }

    let thumbnailLambdaCost = 0;
    if (thumbnailEnabled) {
      thumbnailLambdaCost = avgThumbnailingProcessingTime
        ? lambdaMonthlyCost(
            regionalPricing,
            uploadCount,
            10,
            avgThumbnailingProcessingTime
          )
        : lambdaMonthlyCost(
            regionalPricing,
            uploadCount,
            10,
            estimateLambdaExecutionTime(avgMediaDurationInSec, "thumbnail")
          );
      computeCost += thumbnailLambdaCost;
    } else {
      thumbnailLambdaCost = lambdaMonthlyCost(
        regionalPricing,
        uploadCount,
        10,
        emptyLambdaDuration
      );
      computeCost += thumbnailLambdaCost;
    }

    //  notification function cost
    let notificationLambdaCost = lambdaMonthlyCost(
      regionalPricing,
      uploadCount,
      1,
      notificationFunctionDuration
    );
    computeCost += notificationLambdaCost;

    // generate upload token function
    let uploadLambdaCost = lambdaMonthlyCost(
      regionalPricing,
      uploadCount,
      128 * gbInMb,
      generateUploadTokenFunctionDuration
    );
    computeCost += uploadLambdaCost;

    // orchestrator function cost
    let orchestratorLambdaCost = lambdaMonthlyCost(
      regionalPricing,
      uploadCount,
      1,
      orchestrationfunctionDuration
    );
    computeCost += orchestratorLambdaCost;

    let monthlyStepFuncCost = stepFunctionsCost(
      regionalPricing,
      uploadCount * 5
    );
    otherCost += monthlyStepFuncCost;

    let monthlyNatCost = NatGatewayPerGbCost(
      regionalPricing,
      uploadCount * avgMediaSize
    );
    vpcCost += monthlyNatCost;

    let monthlyTranscriptionsCost = 0;
    if (transcribingEnabled) {
      monthlyTranscriptionsCost = transcribeCost(
        regionalPricing,
        uploadCount * avgMediaDurationInSec
      );
    }
    computeCost += monthlyTranscriptionsCost;

    let monthlySACost = 0;
    if (sentimentAnalysisEnabled) {
      monthlySACost = sentimentAnalysisCost(
        regionalPricing,
        uploadCount,
        avgTranscriptionCharacters ? avgTranscriptionCharacters : 1000
      );
    }
    computeCost += monthlySACost;

    totalCost =
      storageCost +
      otherCost +
      dbCost +
      dbCost +
      computeCost +
      cdnCost +
      vpcCost;

    return {
      totalCost,
      storageCost,
      otherCost,
      dbCost,
      computeCost,
      cdnCost,
      vpcCost
    };
  };

  const calculateMonthlyBill = (
    uploadCount: number,
    avgMediaDurationInSec: number,
    avgMediaSizeInMbs: number,
    playbackCount: number,
    watermarkEnabled: boolean,
    thumbnailEnabled: boolean,
    transcribingEnabled: boolean,
    sentimentAnalysisEnabled: boolean,
    externalStorageEnabled: boolean,
    multiStreamSegmentationEnabled: boolean,
    singleStreamSegmentationEnabled: boolean,
    avgTranscodingProcessingTime?: number,
    avgWatermarkingProcessingTime?: number,
    avgThumbnailingProcessingTime?: number,
    avgMediaUploadTime?: number,
    avgTranscriptionCharacters?: number
  ) => {
    // avg media size is per sec
    let totalUploadedSizeInGbs =
      avgMediaSizeInMbs * uploadCount * gbInMb * avgMediaDurationInSec;
    let uploadCost = monthlyUploadProcessingCost(
      uploadCount,
      avgMediaDurationInSec,
      totalUploadedSizeInGbs,
      watermarkEnabled,
      thumbnailEnabled,
      transcribingEnabled,
      sentimentAnalysisEnabled,
      externalStorageEnabled,
      multiStreamSegmentationEnabled,
      singleStreamSegmentationEnabled,
      avgTranscodingProcessingTime,
      avgWatermarkingProcessingTime,
      avgThumbnailingProcessingTime,
      avgMediaUploadTime,
      avgTranscriptionCharacters
    );

    let playbackCost = monthlyPlaybackProcessingCost(
      regionalPricing,
      playbackCount,
      totalUploadedSizeInGbs,
      false
    );

    setMonthlyStorageCost(uploadCost.storageCost + playbackCost.storageCost);
    setMonthlyOtherCost(uploadCost.otherCost + playbackCost.otherCost);
    setMonthlyCdnCost(uploadCost.cdnCost + playbackCost.cdnCost);
    setMonthlyComputeCost(uploadCost.computeCost + playbackCost.computeCost);
    setMonthlyDbCost(uploadCost.dbCost + playbackCost.dbCost);
    setMonthlyVpcCost(uploadCost.vpcCost);
    setMonthlyTotalCost(uploadCost.totalCost + playbackCost.totalCost);
  };
  return (
    <Layout>
      <CostEstimatorComp calculateMonthlyBill={calculateMonthlyBill} />
      <MonthlyBill
        monthlyComputeCost={monthlyComputeCost}
        monthlyCdnCost={monthlyCdnCost}
        monthlyDbCost={monthlyDbCost}
        monthlyOtherCost={monthlyOtherCost}
        monthlyVpcCost={monthlyVpcCost}
        monthlyTotalCost={monthlyTotalCost}
        monthlyStorageCost={monthlyStorageCost}
        regionalInstanceCost={regionalInstanceCost}
      />
    </Layout>
  );
}

export default MediaDetails;
