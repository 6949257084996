import React from "react";
import { Table } from "antd";
import "./components.scss";

function CostTable(props) {
  let { logData } = props;
  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      render(text, record) {
        return {
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Compute",
      dataIndex: "computeCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Storage",
      dataIndex: "storageCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "VPC",
      dataIndex: "vpcCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Database",
      dataIndex: "dbCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "CDN",
      dataIndex: "cdnCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Other",
      dataIndex: "otherCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Total",
      dataIndex: "totalCost",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}$</div>
        };
      }
    }
  ];
  return (
    <div className="tablediv">
      {logData && (
        <Table columns={columns} dataSource={logData} scroll={{ y: "48vh" }} />
      )}
    </div>
  );
}

export default CostTable;
