import React from "react";
import { Row, Col } from "antd";
import GifPlayer from "react-gif-player";
import "../components.scss";

function VideoGifRow(props) {
  const { url, label } = props;

  return (
    <div className="media_details_gif">
      <GifPlayer gif={url} />
      <div className="thmubnail-key">{label}</div>
    </div>
  );
}

export default VideoGifRow;
