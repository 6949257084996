"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
class ConnectionHealthPolicyConfiguration {
    constructor() {
        this.minHealth = 0;
        this.maxHealth = 1;
        this.initialHealth = 1;
        this.connectionUnhealthyThreshold = 25;
        this.noSignalThresholdTimeMs = 10000;
        this.connectionWaitTimeMs = 10000;
        this.zeroBarsNoSignalTimeMs = 5000;
        this.oneBarWeakSignalTimeMs = 5000;
        this.twoBarsTimeMs = 5000;
        this.threeBarsTimeMs = 10000;
        this.fourBarsTimeMs = 20000;
        this.fiveBarsTimeMs = 60000;
        this.cooldownTimeMs = 60000;
        this.pastSamplesToConsider = 15;
        this.goodSignalTimeMs = 15000;
        this.fractionalLoss = 0.5;
        this.packetsExpected = 50;
        this.maximumTimesToWarn = 2;
        this.missedPongsLowerThreshold = 1;
        this.missedPongsUpperThreshold = 4;
        this.maximumAudioDelayMs = 60000;
        this.maximumAudioDelayDataPoints = 10;
        /**
         * The number of samples required to consider sending-audio to be unhealthy
         *
         * The default value is derived from the median for time taken for receiving an attendee presence message from the
         * server after joining. Attendee presence is only received when the client sends audio packets to the server, so
         * this metric is used as a proxy.
         */
        this.sendingAudioFailureSamplesToConsider = 2;
        /**
         * The purpose of this field is to add a wait time/delay to our evaluation of sending audio health
         * as the microphone may sometimes cause a delay in sending audio packets during the initial stages of a connection.
         */
        this.sendingAudioFailureInitialWaitTimeMs = 3000;
    }
}
exports.default = ConnectionHealthPolicyConfiguration;
