import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import NativeVideo from "nativevideo-sdk-js";
import { Select } from "antd";
import useAuth from "../../context/useAuth";
import { SubscribersContext } from "../../context/subscribersContext";
import { AsideContext } from "../../context/asideContext";
import { RegionContext } from "../../context/regionContext";
import "./layout.scss";
import { regions } from "../../constants";
import Aside from "./Aside";

const { Option } = Select;

const Layout = ({ children }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { regionUrl, setRegionUrl, setRegionKey } = useContext(RegionContext);
  const { searchText, setSearchText, selectedSubscriber, setSelectedSubscriber } = useContext(AsideContext)

  const location = useLocation();
  const params = useParams();

  let nvObj = new NativeVideo({
    adminAuthToken: localStorage.getItem("loginToken") as string,
    backendUrl: regionUrl ? regionUrl : undefined,
  });

  const { setSubscribersData, subscribersData } = useContext(
    SubscribersContext
  );
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>();
  const [subscribersInfo, setSubscribersInfo] = useState(subscribersData);
  // fomattedsubscriberData
  const [formattedsubscriberData, setFormattedsubscriberData] = useState<any>();
  const [selectedOrgName, setSelectedOrgName] = useState<string>();
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    if (subscribersData && params && params.id) {
      const sub = subscribersData.find(o => o.subscriberId === params.id);
      if (sub) {
        setSelectedOrgName(sub.organizationName);
      }
    }
  }, [params, subscribersData]);

  useEffect(() => {
    if (!subscribersData) {
      nvObj
        .getAllSubscribersInfo()
        .then(res => {
          setSubscribersData(res);
          setSubscribersInfo(res);
        })
        .catch(err => {
          console.log("err: ", err);
        });
    }
  }, [subscribersData]);

  useEffect(() => {
    if (regionUrl) {
      const active = regions.find(o => o.value === regionUrl);
      if (active) {
        setSelectedRegion(active.label as string);
        setRegionKey(active.xApiKeyName as string);
      }
      nvObj.updateBackendUrl(regionUrl);
    }
  }, [regionUrl]);

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("subscribers-cost")) {
        setActiveTab("cost");
      } else if (location.pathname.includes("subscribers-overview")) {
        setActiveTab("overview");
      } else if (location.pathname.includes("subscribers-effect-profile")) {
        setActiveTab("effect_profiles");
      } else if (location.pathname.includes("subscribers-usage")) {
        setActiveTab("usage");
      } else if (location.pathname.includes("/subscribers/")) {
        setActiveTab("media");
      }
    }
  }, [location]);

  const handleClickMedia = subId => {
    const sub = subscribersData.find(o => o.subscriberId === subId);
    if (sub) {
      setSelectedOrgName(sub.organizationName);
    }
    setActiveTab("media");
    navigate(`/subscribers/${subId}`);
  };

  const handleClickOverview = subId => {
    const sub = subscribersData.find(o => o.subscriberId === subId);
    if (sub) {
      setSelectedOrgName(sub.organizationName);
    }
    setActiveTab("overview");
    navigate(`/subscribers-overview/${subId}`);
  };

  const handleClickProfile = subId => {
    const sub = subscribersData.find(o => o.subscriberId === subId);
    if (sub) {
      setSelectedOrgName(sub.organizationName);
    }
    setActiveTab("effect_profiles");
    navigate(`/subscribers-effect-profile/${subId}`);
  };

  const handleClickCost = subId => {
    const sub = subscribersData.find(o => o.subscriberId === subId);
    if (sub) {
      setSelectedOrgName(sub.organizationName);
    }
    setActiveTab("cost");
    navigate(`/subscribers-cost/${subId}`);
  };

  const handleClickUsage = subId => {
    const sub = subscribersData.find(o => o.subscriberId === subId);
    if (sub) {
      setSelectedOrgName(sub.organizationName);
    }
    setActiveTab("usage");
    navigate(`/subscribers-usage/${subId}`);
  };

  const moveObjectToStartAndShiftOthers = (originalList, organizationName) => {
    // console.log(JSON.stringify(obj))
    // Extract the "NV Dev" object
    const nvDevObject = originalList[organizationName];

    // Create a new object with reordered arrays
    // const reorderedList = {
    //   organizationName: nvDevObject,
    //   ...originalList[1:]
    // };


    const reorderedList = {
      [organizationName]: nvDevObject,
      ...Object.entries(originalList)
        .filter(([key]) => key !== organizationName)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    };

    return reorderedList;
  }

  const handleOrgClick = orgName => {
    let tempObj = moveObjectToStartAndShiftOthers(formattedsubscriberData, orgName);
    // console.log(tempObj)
    setSelectedOrgName(orgName);
    setSelectedSubscriber(orgName);
    setFormattedsubscriberData(tempObj);
  }

  const handleLogout = () => {
    setSubscribersData(null);
    logout();
    navigate("/login");
  };

  const handleClickNavigation = path => {
    navigate(path);
  };

  const handleRegionChange = (value: string) => {
    localStorage.setItem("region", value);
    setRegionUrl(value);
    setSubscribersData(null);
    logout();
  };

  useEffect(() => {
    const sortedObject = formatSubscriberDataForSideBar(subscribersInfo)
    if (searchText !== "") {
      console.log("ye dobara call karny ka ")
      handleSearchByMediaId(searchText, sortedObject)
    }
    else if (selectedSubscriber !== "") {
      let tempObj = moveObjectToStartAndShiftOthers(sortedObject, selectedSubscriber);
      setFormattedsubscriberData(tempObj);
    }
  }, [subscribersInfo]);

  const formatSubscriberDataForSideBar = (subscribersInfo) => {
    let formattedData = {};
    if (subscribersInfo) {
      for (let i = 0; i < subscribersInfo.length; i++) {
        if (
          !Object.keys(formattedData).includes(
            subscribersInfo[i].organizationName
          )
        ) {
          formattedData[subscribersInfo[i].organizationName] = [
            subscribersInfo[i]
          ];
        }
        for (let j = i + 1; j < subscribersInfo.length; j++) {
          if (
            subscribersInfo[j].organizationName ===
            subscribersInfo[i].organizationName
          ) {
            formattedData[subscribersInfo[i].organizationName].push(
              subscribersInfo[j]
            );
          }
        }
      }
    }
    // setFormattedsubscriberData(formattedData);
    // console.log(formattedData)
    // sort alphabetically 
    const sortedKeys = Object.keys(formattedData).sort();
    const sortedObject = {};

    for (const key of sortedKeys) {
      sortedObject[key] = formattedData[key];
    }
    // console.log(sortedObject)
    setFormattedsubscriberData(sortedObject);
    return sortedObject
  }

  // useEffect(() => {

  //   console.log("inside here")
  //   console.log(searchText)
  //   if (searchText !== "") {
  //     handleSearchByMediaId(searchText)
  //   }
  //   // only run when the component initializes
  // }, []);

  const handleSearchByMediaId = (e, data?) => {
    // console.log(e)
    // console.log(JSON.stringify(formattedsubscriberData))
    console.log(formattedsubscriberData)
    if (e !== "" && (formattedsubscriberData || data)) {
      const foundObjects: any[] = [];

      let asideData = formattedsubscriberData ? formattedsubscriberData : data

      // search by org id
      for (const key in asideData) {
        const objects = asideData[key];
        const matchingObjects = objects.filter(item => item.salesforceOrganizationId && item.salesforceOrganizationId.includes(e));
        // foundObjects.push(...matchingObjects);
        if (matchingObjects.length > 0) {
          foundObjects[key] = matchingObjects;
        }
      }

      // console.log(foundObjects)

      // search by subscriber id
      if (foundObjects.length === 0) {
        for (const key in asideData) {
          const objects = asideData[key];
          const matchingObjects = objects.filter(item => item.subscriberId && item.subscriberId.includes(e));
          // foundObjects.push(...matchingObjects);
          if (matchingObjects.length > 0) {
            foundObjects[key] = matchingObjects;
          }
        }

        // console.log(foundObjects)
        setFormattedsubscriberData(foundObjects);
      }
    }
    else if (e === "") {
      console.log("e is emptyyyyyyy")
      formatSubscriberDataForSideBar(subscribersInfo)
    }
    setSearchText(e)
  };

  console.log("rendered")

  return (
    <>
      <div>
        <div className="menu-bar">
          <img
            src="/nv.png"
            onClick={() => navigate("/")}
            className="nv-logo"
            alt="NativeVideo"
          />
          <div className="nav-right-section">
            <Select
              defaultValue="Select Region"
              onChange={handleRegionChange}
              bordered={false}
              dropdownMatchSelectWidth={200}
              value={selectedRegion}
              dropdownClassName="region-dropdown"
            >
              {regions.map(region => {
                return (
                  <>
                    <Option value={region.value}>{region.label}</Option>
                  </>
                );
              })}
            </Select>
            <div className="logout-label" onClick={() => handleLogout()}>
              Log out
            </div>
          </div>
        </div>
        <div className="outer-container">
          <div className="menu-wrap">
            <input
              onChange={e =>
                handleSearchByMediaId(e.target.value)
                // setSearchText(e.target.value)
              }
              className="aside_search_box"
              placeholder="Org ID / Subscriber ID "
              type="text"
              value={searchText}
            />
            <Aside
              collapsed={false}
              rtl={false}
              toggled={false}
              selectedOrgName={selectedOrgName}
              handleClickProfile={handleClickProfile}
              handleClickCost={handleClickCost}
              handleClickUsage={handleClickUsage}
              handleClickOverview={handleClickOverview}
              handleClickMedia={handleClickMedia}
              handleClickNavigation={handleClickNavigation}
              formattedsubscriberData={formattedsubscriberData}
              setSelectedOrgName={setSelectedOrgName}
              handleOrgClick={handleOrgClick}
              activeTab={activeTab}
            />
          </div>
          <main className="content-wrap">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
