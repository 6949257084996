import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "./components.scss";
import InputText from "./util/InputText";
import InputSwitch from "./util/InputSwitch";
import InputDropdown from "./util/InputDropdown";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { fonts } from "../constants";
import withMessage, { WithMessageProps } from "../hoc/withMessage";

interface MyComponentProps {
    subscriberProfile;
    subscriberId;
    nvObj;
}
const EffectProfileUpdate: React.FC<MyComponentProps & WithMessageProps> = ({ subscriberProfile, subscriberId, nvObj, showMessage }) => {
    const navigate = useNavigate();

    const [profileId, setProfileId] = useState();
    const [file, setFile] = useState(); // watermark image
    const [opacity, setOpacity] = useState();
    const [label, setLabel] = useState();
    const [watermarkType, setWatermarkType] = useState();
    const [scale, setScale] = useState();
    const [position, setPosition] = useState("topLeft");
    const [loading, setLoading] = useState(false);
    const [fontFamily, setFontFamily] = useState<string>();
    const [customFontFamily, setCustomFontFamily] = useState<string>();
    const [fontFile, setFontFile] = useState<File | undefined>();
    const [fontColor, setFontColor] = useState();
    const [fontSize, setFontSize] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [backgroundOpacity, setBackgroundOpacity] = useState();
    const [backgroundWidth, setBackgroundWidth] = useState();
    const [borderColor, setBorderColor] = useState();
    const [fontAngle, setFontAngle] = useState();
    const [fontOpacity, setFontOpacity] = useState();
    const [defaultText, setDefaultText] = useState<string>("");
    const [repeatText, setRepeatText] = useState<boolean>();
    const [isDefault, setIsDefault] = useState<boolean>();

    useEffect(() => {
        if (subscriberProfile) {
            setProfileId(subscriberProfile.profileId)
            setIsDefault(subscriberProfile.isDefault === "true" ? true : false)
            setWatermarkType(subscriberProfile.watermark.type)
            setPosition(subscriberProfile.watermark.position)
            setLabel(subscriberProfile.watermark.label)

            if (subscriberProfile.watermark.type === "text") {
                if (subscriberProfile.watermark.defaultText) setDefaultText(subscriberProfile.watermark.defaultText)
                if (subscriberProfile.watermark.fontFamily) setFontFamily(subscriberProfile.watermark.fontFamily)
                if (subscriberProfile.watermark.fontSize) setFontSize(subscriberProfile.watermark.fontSize)
                if (subscriberProfile.watermark.fontColor) setFontColor(subscriberProfile.watermark.fontColor)
                if (subscriberProfile.watermark.backgroundColor) setBackgroundColor(subscriberProfile.watermark.backgroundColor)
                if (subscriberProfile.watermark.backgroundOpacity) setBackgroundOpacity(subscriberProfile.watermark.backgroundOpacity)
                if (subscriberProfile.watermark.backgroundWidth) setBackgroundWidth(subscriberProfile.watermark.backgroundWidth)
                if (subscriberProfile.watermark.borderColor) setBorderColor(subscriberProfile.watermark.borderColor)
                if (subscriberProfile.watermark.fontAngle) setFontAngle(subscriberProfile.watermark.fontAngle)
                if (subscriberProfile.watermark.fontOpacity) setFontOpacity(subscriberProfile.watermark.fontOpacity)
                if (subscriberProfile.watermark.repeat) {
                    setRepeatText(true)
                } else {
                    setRepeatText(false)
                }
            }
            else if (subscriberProfile.watermark.type === "image") {
                if (subscriberProfile.watermark.opacity) setOpacity(subscriberProfile.watermark.opacity);
                if (subscriberProfile.watermark.scale) setScale(subscriberProfile.watermark.scale);
            }
        }
    }, [subscriberProfile]);

    const handleFormSubmission = async () => {
        setLoading(true);
        var data = {
            watermarkType: watermarkType,
            isDefault: isDefault ? "true" : "false"
        };
        if (position) data["position"] = position;
        if (label) data["label"] = label;

        if (watermarkType === "image") {
            if (file) data["image"] = file;
            if (opacity) data["opacity"] = opacity;
            if (scale) data["scale"] = scale;
        } else {
            data["defaultText"] = defaultText;
            if (fontFile) data["fontFile"] = fontFile;
            if (fontFamily) data["fontFamily"] = fontFamily;
            if (customFontFamily) data["fontFamily"] = customFontFamily;
            if (fontColor) data["fontColor"] = fontColor;
            if (fontSize) data["fontSize"] = fontSize;
            if (backgroundColor) data["backgroundColor"] = backgroundColor;
            if (backgroundOpacity) data["backgroundOpacity"] = backgroundOpacity;
            if (backgroundWidth) data["backgroundWidth"] = backgroundWidth;
            if (borderColor) data["borderColor"] = borderColor;
            if (fontAngle) data["fontAngle"] = fontAngle;
            if (fontOpacity) data["fontOpacity"] = fontOpacity
            data["repeat"] = repeatText;
        }

        nvObj
            .upsertSubscriberProfiles(data, profileId)
            .then(() => {
                setLoading(false);
                showMessage("Profile updated successfully", 'success');
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                showMessage("Profile update failed", 'error');
            });
    };

    const handleFileSelected = e => {
        setFile(e.target.files[0]);
    };

    const handleFontFileSelected = e => {
        setFontFile(e.target.files[0])
        setCustomFontFamily(e.target.files[0].name)
    }

    return (
        <div className="display_section">
            <div className="App">
                <div className="subscriber_info_section">
                    <div className="header">
                        <div className="back-chevron" onClick={() => navigate(`/subscribers-effect-profile/${subscriberId}`)}>
                            <div><LeftOutlined className="chevron-icon" /></div>
                            <div>
                                Go Back
                            </div>
                        </div>
                        <Button
                            type="primary"
                            className="save-button"
                            loading={loading}
                            onClick={() => handleFormSubmission()}
                        >
                            Save
                        </Button>
                    </div>
                    <InputDropdown
                        labelText="Watermark Type"
                        updateState={setWatermarkType}
                        value={watermarkType}
                        options={[
                            { "value": "image", 'label': "image" },
                            { "value": "text", 'label': "text" }
                        ]}
                        disabled={false}
                    />
                    <InputSwitch
                        labelText="Default Profile"
                        updateState={setIsDefault}
                        value={isDefault}
                    />
                    <InputText
                        labelText="Label"
                        type="text"
                        updateState={setLabel}
                        placeholder="label / name"
                        value={label}
                    />
                    <InputDropdown
                        labelText="Position"
                        updateState={setPosition}
                        value={position}
                        options={[
                            { "value": "topLeft", 'label': "topLeft" },
                            { "value": "topRight", 'label': "topRight" },
                            { "value": "center", 'label': "center" },
                            { "value": "bottomLeft", 'label': "bottomLeft" },
                            { "value": "bottomRight", 'label': "bottomRight" },
                        ]}
                        disabled={false}
                    />
                    {watermarkType === "image" ? (
                        <>
                            <InputText
                                labelText="Scale (%)"
                                type="text"
                                placeholder="e.g 50"
                                updateState={setScale}
                                value={scale}
                            />
                            <InputText
                                labelText="Opacity (%)"
                                type="text"
                                placeholder="e.g 50"
                                updateState={setOpacity}
                                value={opacity}
                            />
                        </>
                    ) : (
                        <>
                            <InputText
                                labelText="Default Text"
                                type="text"
                                placeholder="e.g NativeVideo"
                                updateState={setDefaultText}
                                value={defaultText}
                            />
                            <InputDropdown
                                labelText="Font Family"
                                updateState={setFontFamily}
                                value={fontFamily}
                                options={fonts}
                                disabled={false}
                            />
                            {fontFamily === "other" && (
                                <div className="update_input">
                                    <label className="label">Custom Font: </label>
                                    <input
                                        onChange={handleFontFileSelected}
                                        accept=".otf, .ttf, .woff"
                                        className="form_input_file"
                                        type="file"
                                    />
                                </div>
                            )}
                            <InputText
                                labelText="Font Color"
                                type="text"
                                placeholder="e.g 0xFFFFFF"
                                updateState={setFontColor}
                                value={fontColor}
                            />
                            <InputText
                                labelText="Font Size"
                                type="text"
                                placeholder="e.g 24"
                                updateState={setFontSize}
                                value={fontSize}
                            />
                            <InputText
                                labelText="Background Color"
                                type="text"
                                placeholder="e.g 0x000000"
                                updateState={setBackgroundColor}
                                value={backgroundColor}
                            />
                            <InputText
                                labelText="Background Opacity"
                                type="text"
                                placeholder="0 - 100"
                                updateState={setBackgroundOpacity}
                                value={backgroundOpacity}
                            />
                            <InputText
                                labelText="Background/Box Width: "
                                type="text"
                                placeholder="e.g 5"
                                updateState={setBackgroundWidth}
                                value={backgroundWidth}
                            />
                            <InputSwitch
                                labelText="Repeat text"
                                updateState={setRepeatText}
                                value={repeatText}
                                disabled={false}
                            />
                            {repeatText && (
                                <>
                                    <InputText
                                        labelText="Font Angle"
                                        type="text"
                                        placeholder="e.g 330"
                                        updateState={setFontAngle}
                                        value={fontAngle}
                                    />
                                    <InputText
                                        labelText="Font Opacity (%)"
                                        type="text"
                                        placeholder="e.g 0-100"
                                        updateState={setFontOpacity}
                                        value={fontOpacity}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {watermarkType === "image" && (
                        <div className="update_input">
                            <label className="label">Watermark image: </label>
                            <input
                                onChange={handleFileSelected}
                                accept=".png, .jpeg, .jpg"
                                className="form_input_file"
                                type="file"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withMessage(EffectProfileUpdate);

