import React from "react";
import "./util.scss";

function InputText(props) {
  const {
    labelText,
    type,
    placeholder,
    updateStateFunction,
    updateState,
    value,
    disabled
  } = props;
  return (
    <div className="update_input">
      <label className="label">{labelText}</label>
      <input
        onChange={(e) => {
          if (updateState) {
            updateState(e.target.value)
          } else {
            updateStateFunction(labelText, e)
          }
        }}
        className="value"
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

export default InputText;
