import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import "./components.scss";
import InputText from "./util/InputText";
import InputSwitch from "./util/InputSwitch";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import UpdateSelect from "./util/UpdateSelect";

//  video size estimation for 1 sec
//  source: https://www.digitalrebellion.com/webapps/videocalc?format=h264_720&frame_rate=f23.98&length=30&length_type=seconds
//  res: H.264
let resToSizeInMbs = [
  {
    label: "480",
    value: 3.06
  },
  {
    label: "720",
    value: 5.91
  },
  {
    label: "1080",
    value: 9.87
  }
];

function CostEstimatorComp(props) {
  let { calculateMonthlyBill } = props;

  const [uploadCount, setUploadCount] = useState();
  const [playbackCount, setPlaybackCount] = useState();
  const [avgMediaDurationInSec, setAvgMediaDurationInSec] = useState();

  let defaultRes = resToSizeInMbs.find(o => o.label === "720");
  const [avgMediaSizeInMbs, setAvgMediaSizeInMbs] = useState(
    defaultRes ? defaultRes.value : ""
  );
  const [totalUploadedSizeInGbs, setTotalUploadedSizeInGbs] = useState();
  const [playbackSizeInGbs, setPlaybackSizeInGbs] = useState();
  const [watermarkEnabled, setWatermarkEnabled] = useState(false);
  const [thumbnailEnabled, setThumbnailEnabled] = useState(false);

  const [showAdditionalConfig, setShowAdditionalConfig] = useState(false);

  const [transcribingEnabled, setTranscribingEnabled] = useState(false);
  const [sentimentAnalysisEnabled, setSentimentAnalysisEnabled] = useState(
    false
  );
  const [externalStorageEnabled, setExternalStorageEnabled] = useState(false);
  const [
    multiStreamSegmentationEnabled,
    setMultiStreamSegmentationEnabled
  ] = useState(false);
  const [
    singleStreamSegmentationEnabled,
    setSingleStreamSegmentationEnabled
  ] = useState(false);

  const [
    avgTranscodingProcessingTime,
    setAvgTranscodingProcessingTime
  ] = useState();
  const [
    avgWatermarkingProcessingTime,
    setAvgWatermarkingProcessingTime
  ] = useState();
  const [
    avgThumbnailingProcessingTime,
    setAvgThumbnailingProcessingTime
  ] = useState();
  const [avgMediaUploadTime, setAvgMediaUploadTime] = useState();
  const [
    avgTranscriptionCharacters,
    setAvgTranscriptionCharacters
  ] = useState();

  useEffect(() => {
    if (
      (uploadCount && avgMediaDurationInSec && totalUploadedSizeInGbs) ||
      playbackCount
    ) {
      calculateMonthlyBill(
        uploadCount,
        avgMediaDurationInSec,
        avgMediaSizeInMbs,
        playbackCount,
        watermarkEnabled,
        thumbnailEnabled,
        transcribingEnabled,
        sentimentAnalysisEnabled,
        externalStorageEnabled,
        multiStreamSegmentationEnabled,
        singleStreamSegmentationEnabled,
        avgTranscodingProcessingTime,
        avgWatermarkingProcessingTime,
        avgThumbnailingProcessingTime,
        avgMediaUploadTime,
        avgTranscriptionCharacters
      );
    }
  }, [
    uploadCount,
    avgMediaDurationInSec,
    avgMediaSizeInMbs,
    watermarkEnabled,
    thumbnailEnabled,
    transcribingEnabled,
    sentimentAnalysisEnabled,
    externalStorageEnabled,
    multiStreamSegmentationEnabled,
    singleStreamSegmentationEnabled,
    avgTranscodingProcessingTime,
    avgWatermarkingProcessingTime,
    avgThumbnailingProcessingTime,
    avgMediaUploadTime,
    avgTranscriptionCharacters,
    playbackCount
  ]);

  const handleUpdateText = (key, e) => {
    if (key === "Media Count") {
      setUploadCount(e.target.value);
    } else if (key.includes("Media Size")) {
      setTotalUploadedSizeInGbs(e.target.value);
    } else if (key.includes("Duration")) {
      setAvgMediaDurationInSec(e.target.value);
    } else if (key.includes("Transcoding Processing")) {
      setAvgTranscodingProcessingTime(e.target.value);
    } else if (key.includes("Watermarking Processing")) {
      setAvgWatermarkingProcessingTime(e.target.value);
    } else if (key.includes("Thumbnail Processing")) {
      setAvgThumbnailingProcessingTime(e.target.value);
    } else if (key.includes("Media Upload")) {
      setAvgMediaUploadTime(e.target.value);
    } else if (key.includes("Transcription Characters")) {
      setAvgTranscriptionCharacters(e.target.value);
    } else if (key === "Playback Count") {
      setPlaybackCount(e.target.value);
    } else if (key.includes("Playback Size")) {
      setPlaybackSizeInGbs(e.target.value);
    }
  };

  const handleUpdateRadio = (key, value) => {
    if (key === "Watermarking") {
      setWatermarkEnabled(value);
    } else if (key === "Thubmnails") {
      setThumbnailEnabled(value);
    } else if (key === "Transcribing") {
      setTranscribingEnabled(value);
    } else if (key.includes("Sentiment")) {
      setSentimentAnalysisEnabled(value);
    } else if (key.includes("External")) {
      setExternalStorageEnabled(value);
    } else if (key.includes("Multi Stream")) {
      setMultiStreamSegmentationEnabled(value);
    } else if (key.includes("Single Stream")) {
      setSingleStreamSegmentationEnabled(value);
    }
  };

  return (
    <div className="App">
      <div className="subscriber_info_section">
        <div className="header">
          <div>Monthly Data</div>
        </div>
        <InputText
          labelText="Media Count"
          type="number"
          placeholder="e.g 1000"
          updateStateFunction={handleUpdateText}
          value={uploadCount}
        />
        <UpdateSelect
          labelText="Avg Media Resolution"
          name="region"
          options={resToSizeInMbs}
          handleChange={setAvgMediaSizeInMbs}
        />
        <InputText
          labelText="Playback Count"
          type="number"
          placeholder="e.g 1000"
          updateStateFunction={handleUpdateText}
          value={playbackCount}
        />
        <InputText
          labelText="Average Media Duration"
          type="number"
          placeholder="in seconds"
          updateStateFunction={handleUpdateText}
          value={avgMediaDurationInSec}
        />
        <InputSwitch
          labelText="Watermarking"
          updateStateFunction={handleUpdateRadio}
          value={watermarkEnabled}
        />

        <InputSwitch
          labelText="Thubmnails"
          updateStateFunction={handleUpdateRadio}
          value={thumbnailEnabled}
        />
        <InputSwitch
          labelText="Transcribing"
          updateStateFunction={handleUpdateRadio}
          value={transcribingEnabled}
        />
        <InputSwitch
          labelText="Sentiment Analysis"
          updateStateFunction={handleUpdateRadio}
          value={sentimentAnalysisEnabled}
        />
        <InputSwitch
          labelText="External Storage"
          updateStateFunction={handleUpdateRadio}
          value={externalStorageEnabled}
        />

        <InputSwitch
          labelText="Segmentation Multi Stream"
          updateStateFunction={handleUpdateRadio}
          value={multiStreamSegmentationEnabled}
        />

        <InputSwitch
          labelText="Segmentation Single Stream"
          updateStateFunction={handleUpdateRadio}
          value={singleStreamSegmentationEnabled}
        />

        <h4
          className="additiona_form_fields"
          onClick={() => setShowAdditionalConfig(!showAdditionalConfig)}
        >
          Additional Configuration
          {showAdditionalConfig ? (
            <UpOutlined className="dropdown_icon" />
          ) : (
            <DownOutlined className="dropdown_icon" />
          )}
        </h4>

        {showAdditionalConfig && (
          <>
            <InputText
              labelText="Transcoding Processing Time"
              type="number"
              placeholder="in seconds"
              updateStateFunction={handleUpdateText}
              value={avgTranscodingProcessingTime}
            />
            <InputText
              labelText="Watermarking Processing Time"
              type="number"
              placeholder="in seconds"
              updateStateFunction={handleUpdateText}
              value={avgWatermarkingProcessingTime}
            />
            <InputText
              labelText="Thumbnail Processing time"
              type="number"
              placeholder="in seconds"
              updateStateFunction={handleUpdateText}
              value={avgThumbnailingProcessingTime}
            />
            <InputText
              labelText="Media Upload Time"
              type="number"
              placeholder="in seconds"
              updateStateFunction={handleUpdateText}
              value={avgMediaUploadTime}
            />
            <InputText
              labelText="Average Transcription Characters"
              type="number"
              placeholder="e.g 100"
              updateStateFunction={handleUpdateText}
              value={avgTranscriptionCharacters}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CostEstimatorComp;
