import React from "react";
import "./components.scss";

function MediaInput(props) {
  const { labelText, type, placeholder, updateStateFunction } = props;
  return (
    <div className="form_row">
      <label className="form_label">{labelText}</label>
      <input
        onChange={e => updateStateFunction(e.target.value)}
        className="form_input"
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
}

export default MediaInput;
