import React from "react";
import "./util.scss";
import { Switch } from "antd";

function InputSwitch(props) {
  const { labelText, updateStateFunction, value, updateState } = props;
  return (
    <div className="update_input">
      <label className="label">{labelText}</label>
      <Switch
        defaultChecked
        checked={value}
        onChange={(e) => {
          if (updateState) {
            updateState(e)
          } else {
            updateStateFunction(labelText, e)
          }
        }}
      />
    </div>
  );
}

export default InputSwitch;
