import React from "react";
import { Row, Col } from "antd";
import "../components.scss";

function VideoImageRow(props) {
  const { url, key, label } = props;

  console.log(key);

  return (
    <div className="media_details_img">
      <img src={url} alt="thumbnail" key={key} className="thumbnail" />
      <div className="thmubnail-key">{label}</div>
    </div>
  );
}

export default VideoImageRow;
