import React, { useEffect } from "react";
import JsonFormatter from "react-json-formatter";
import { useNavigate } from "react-router-dom";
import "./components.scss";

function VideoPreview(props) {
  const navigate = useNavigate();
  const { mediaInfo, subscriberId } = props;

  const mediaObject = JSON.parse(mediaInfo);

  useEffect(() => {}, []);

  const jsonStyle = {
    propertyStyle: { color: "#00000080" },
    stringStyle: { color: "#00000080" },
    numberStyle: { color: "#00000080" }
  };

  return (
    <div className="preview_section">
      <div className="label">Video details</div>
      <div className="code_box">
        <code className="prettyprint">
          <JsonFormatter json={mediaInfo} tabWith={4} jsonStyle={jsonStyle} />
        </code>
      </div>
    </div>
  );
}

export default VideoPreview;
