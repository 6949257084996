import React, { useState, useEffect, useContext } from "react";
import VideoDetailsRow from "./MediaDetails/VideoDetailsRow";
import VideoRowABR from "./MediaDetails/VideoRowABR";
import { MediaKeysContext } from "../context/mediaKeysContext";
import { MediaKeysContextDynamicContent } from "../context/mediaKeysContextDynamic";
import { SubscribersContext } from "../context/subscribersContext";
import NativeVideo from "nativevideo-sdk-js";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { DownloadMedia, SubtitlesObj } from "../types/downloadMedia";
import "./components.scss";
import { BytesInMbs } from "../constants";
import { RegionContext } from "../context/regionContext";
import VideoPreview from "./VideoPreview";
import { Carousel, Button } from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LeftOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MultiImageCarousel from "./MultiImageCarousel";
import { TrackChangesSharp } from "@mui/icons-material";

function VideoDetailsSection(props) {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(MediaKeysContext);
  const { tokenTempObjects, setTokenTempObjects } = useContext(
    MediaKeysContextDynamicContent
  );
  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl, regionKey } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [mediaData, setMediaData] = useState<DownloadMedia>();
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isMediaFetching, setIsMediaFetching] = useState(false);
  const [previewText, setPreviewText] = useState("Loading. Please wait...");
  const { subscriberId, mediaId } = props;
  const [showDynamicOverlay, setShowDynamicOverlay] = useState(true)
  const [streamBtnLoading, setStreamBtnLoading] = useState(false);
  const [transcriptionBtnLoading, setTranscriptionBtnLoading] = useState(false);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }
  }, [subscribersData, subscriberId, regionUrl, regionKey]);

  useEffect(() => {
    if (nvObj && !mediaData && !isMediaFetching) {
      fetchMediaPlaybackData();
    }
  }, [nvObj, mediaData, isMediaFetching]);

  useEffect(() => {
    if (mediaData) {
      if (mediaData && mediaData.mediadata.isDynamicWatermarked) {
        setShowDynamicOverlay(false)
      }
    }
  }, [mediaData]);

  useEffect(() => {
    nvObj?.on("dynamicWatermarking:processed", () => {
      console.log("dynamicWatermarking:processed");
      setShowDynamicOverlay(false)
    });

    nvObj?.on("dynamicWatermarking:status", (data) => {
      console.log("dynamicWatermarking:status");
      console.log(data?.isDynamicWatermarked)
      if (data?.isDynamicWatermarked) {
        setShowDynamicOverlay(false)
      }
    });
  }, [nvObj]);

  const handleReTriggerNotification = (notificationType) => {
    console.log("herererere")
    console.log(notificationType)
    if (notificationType === "streams") {
      setStreamBtnLoading(true);
    }
    else {
      setTranscriptionBtnLoading(true)
    }
    // for media in selectedMediaRows:

    nvObj?.reTriggerNotifications(mediaId, notificationType).finally(() => {
      if (notificationType === "streams") {
        setStreamBtnLoading(false);
      }
      else {
        setTranscriptionBtnLoading(false)
      }
    });
  };

  const fetchMediaPlaybackData = () => {
    setIsMediaFetching(true);
    let mediaIds = [props.mediaId];
    // hardcoded daynamic watermark text and username
    let otherParams = {
      adminRequest: true, // this is required 
      // dynamicWatermarkText: "Dynamic Text",
      // watermarkUsername: "osamaD",
      // // effectProfile: "4469d6aa-934e-11ed-bb34-32c7a3dbee82"
      // dynamicWatermarkFields: { "Firstname": "OSAMA", "Lastname": "SALEEM", "Email": "OSAMA.SALEEM@NATIVEVIDEO.CO" },
      // requiresSocket: true
    };
    nvObj
      ?.getDownloadMediaUrls(mediaIds, otherParams)
      .then((res: DownloadMedia[]) => {
        const resData = res.find(o => o.mediadata.mediaId === props.mediaId);
        if (resData) {
          // nvObj.connectWithDynamicWatermarkSocket(props.mediaId)
          setMediaData(resData);
          setIsVideo(resData.mediadata.mimeType.split("/")[0] === "video");
          setIsAudio(resData.mediadata.mimeType.split("/")[0] === "audio");
          if (
            resData.mediadata.mimeType.split("/")[0] !== "video" ||
            resData.mediadata.mimeType.split("/")[0] !== "audio"
          ) {
            setPreviewText("Preview available for video and audio files only");
          }
          setAuthToken(resData.authToken);
          if (resData.authTokenTempObjects) {
            setTokenTempObjects(resData.authTokenTempObjects);
          } else {
            setTokenTempObjects(false);
          }
        }
        setIsMediaFetching(false);
      })
      .catch(err => {
        // setIsMediaFetching(false);
        console.log("error: ", err);
      });
  };

  const formattedTimeDifference = (startTimestamp, endTimestamp) => {
    const startTime = new Date(startTimestamp * 1000);
    const endTime = new Date(endTimestamp * 1000);

    const diffInMins = differenceInMinutes(startTime, endTime);
    if (diffInMins > 0) return `${diffInMins} min`;

    return `${differenceInSeconds(startTime, endTime)} sec`;
  };

  const mapVideoDetails = key => {
    let videoDetails = {};
    const uploadtime = mediaData?.mediadata.uploadedAt;

    if (key === "transcoded" && mediaData?.mediadata.transcoding) {
      videoDetails["fileSize"] = mediaData?.mediadata.transcoding?.mediaSize
        ? (
          (mediaData?.mediadata.transcoding?.mediaSize as number) / BytesInMbs
        ).toFixed(2)
        : "-"; // mbs
      videoDetails["processingTime"] = formattedTimeDifference(
        mediaData?.mediadata.transcoding.finishedAt,
        uploadtime
      );
      videoDetails[
        "resolution"
      ] = `${mediaData?.mediadata.transcoding.width} x ${mediaData?.mediadata.transcoding.height}`;
      videoDetails["duration"] = parseFloat(
        mediaData?.mediadata.transcoding.duration
          ? mediaData?.mediadata.transcoding.duration
          : ""
      ).toFixed(2);
    } else if (key === "watermarked" && mediaData?.mediadata.watermarking) {
      videoDetails["fileSize"] = mediaData?.mediadata.watermarking?.mediaSize
        ? (
          (mediaData?.mediadata.watermarking?.mediaSize as number) /
          BytesInMbs
        ).toFixed(2)
        : "-";
      videoDetails["processingTime"] = formattedTimeDifference(
        mediaData?.mediadata.watermarking.finishedAt,
        uploadtime
      );
      videoDetails[
        "resolution"
      ] = `${mediaData?.mediadata.watermarking.width} x ${mediaData?.mediadata.watermarking.height}`;
      videoDetails["duration"] = parseFloat(
        mediaData?.mediadata.watermarking.duration
          ? mediaData?.mediadata.watermarking.duration
          : ""
      ).toFixed(2);
    } else if (
      key === "subtitles" &&
      mediaData?.mediadata.transcribing &&
      mediaData?.mediadata.transcribing.status === "completed"
    ) {
      videoDetails["processingTime"] = formattedTimeDifference(
        mediaData?.mediadata.transcribing.finishedAt,
        uploadtime
      );
    } else if (key === "original" && mediaData?.mediadata.original) {
      videoDetails["fileSize"] = mediaData?.mediadata.original?.mediaSize
        ? (
          (mediaData?.mediadata.original?.mediaSize as number) / BytesInMbs
        ).toFixed(2)
        : "-";
      videoDetails[
        "resolution"
      ] = `${mediaData?.mediadata.original.width} x ${mediaData?.mediadata.original.height}`;
      videoDetails["duration"] = parseFloat(
        mediaData?.mediadata.original.duration
          ? mediaData?.mediadata.original.duration
          : ""
      ).toFixed(2);
    } else if (key === "dynamicWatermarked" && mediaData?.mediadata.original) {
      videoDetails["fileSize"] = mediaData?.mediadata.original?.mediaSize
        ? (
          (mediaData?.mediadata.original?.mediaSize as number) / BytesInMbs
        ).toFixed(2)
        : "-";
      videoDetails[
        "resolution"
      ] = `${mediaData?.mediadata.original.width} x ${mediaData?.mediadata.original.height}`;
      videoDetails["duration"] = parseFloat(
        mediaData?.mediadata.original.duration
          ? mediaData?.mediadata.original.duration
          : ""
      ).toFixed(2);
    }

    return videoDetails;
  };

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  const mapSrcListToTracks = (subtitles_list: SubtitlesObj[]) => {
    let tracks: {
      kind: string,
      src: string,
      default: boolean,
      srcLang: string
    }[] = []
    for (const subtitles of subtitles_list) {
      tracks.push({
        kind: "subtitles",
        src: "https://dev.download.nativevideo.co/461eee32-074d-11ed-9bfc-e67d744c7dee/1_6f9c58e4-e5be-11ed-83c2-76c8cf38eeea/es-MX-6f9c58e4-e5be-11ed-83c2-76c8cf38eeea.vtt?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kZXYuZG93bmxvYWQubmF0aXZldmlkZW8uY28vNDYxZWVlMzItMDc0ZC0xMWVkLTliZmMtZTY3ZDc0NGM3ZGVlLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE2ODI3MDMxNTd9fX1dfQ__&Signature=RhSnAg6Ee7HljR1jUkJMpomGZyRxw9LB38LkxHweVLPcLxZbtEWaeEeNUXeaT8DFh-hQAwHUUf8XlnfKydVLGtROkwhDfnrg5RwhVOSx08H0VGH5Eg7O3SgwyKNpxghuyEe8ILHl4RCclAgAMCuHWpogEAYaaGUmBinWZC67bi6qOVgOUGpRNTD3bnor-vEyMpmka9wLQCoIDle0WOxRMIcvjxXoGitw1gsOpQWBl1hhDpfVHoQ75meOMtVzjLhxZ6VawHyLyjR~2jdQzyx-9ZuoMoB~Un-gBOlqFBdw29ArGC1fRTcJG8fr1AYRr~nhDfClRTTxU~r3Mx25StjqzA__&Key-Pair-Id=KY1XOB3L4SWDH", // subtitles.src,
        default: subtitles.default,
        srcLang: subtitles.locale,
      })
    }
    return tracks;
  }

  return (
    <>
      {(isVideo || isAudio) && mediaData ? (
        <>
          <div className="detail_page_first_row">
            <div
              onClick={() => navigate(`/subscribers/${subscriberId}`)}
              className="back_btn"
            >
              {" "}
              <LeftOutlined className="back_icon" /> Back
            </div>
            <div className="media_key"> {`Media Key: ${mediaId}`}</div>
          </div>
          <div className="video_playback_section">
            <div className="video_carousel_div">
              <div>
                {!mediaData.mediadata.supportSegmentation && (
                  <>
                    <Carousel
                      arrows
                      nextArrow={
                        <img
                          src="/rightArrow.png"
                          className="carousel_arrow"
                          alt="right"
                        />
                      }
                      prevArrow={
                        <img
                          src="/leftArrow.png"
                          className="carousel_arrow"
                          alt="left"
                        />
                      }
                      afterChange={onChange}
                    >
                      <VideoDetailsRow
                        url={mediaData.original}
                        label={"original"}
                        key={"Original"}
                        videoDetails={mapVideoDetails("original")}
                        isAudio={isAudio}
                        handleReTriggerNotification={handleReTriggerNotification}
                        streamBtnLoading={streamBtnLoading}
                        transcriptionBtnLoading={transcriptionBtnLoading}
                      />

                      <VideoDetailsRow
                        url={mediaData.transcoded}
                        label={"Transcoded"}
                        key={"transcoded"}
                        videoDetails={mapVideoDetails("transcoded")}
                        isAudio={isAudio}
                        handleReTriggerNotification={handleReTriggerNotification}
                        streamBtnLoading={streamBtnLoading}
                        transcriptionBtnLoading={transcriptionBtnLoading}
                      />
                      {mediaData.watermarked && (
                        <VideoDetailsRow
                          url={mediaData.watermarked}
                          label={"Watermarked"}
                          key={"watermarked"}
                          videoDetails={mapVideoDetails("watermarked")}
                          isAudio={isAudio}
                          handleReTriggerNotification={handleReTriggerNotification}
                          streamBtnLoading={streamBtnLoading}
                          transcriptionBtnLoading={transcriptionBtnLoading}
                        />
                      )}
                      {mediaData.dynamicWatermarked && (
                        <VideoDetailsRow
                          url={mediaData.dynamicWatermarked}
                          label={"Dynamic Watermarked"}
                          key={"dynamicWatermarked"}
                          videoDetails={mapVideoDetails("dynamicWatermarked")}
                          isAudio={isAudio}
                          overlayUrl={mediaData.dynamicWatermarkOverlay ? mediaData.dynamicWatermarkOverlay : null}
                          showDynamicOverlay={showDynamicOverlay}
                          handleReTriggerNotification={handleReTriggerNotification}
                          streamBtnLoading={streamBtnLoading}
                          transcriptionBtnLoading={transcriptionBtnLoading}
                        />
                      )}
                      {mediaData.subtitlesList && (
                        <VideoDetailsRow
                          url={mediaData.original}
                          isAudio={isAudio}
                          label={"Transcriptionss"}
                          key={"subtitles"}
                          videoDetails={mapVideoDetails("subtitles")}
                          handleReTriggerNotification={handleReTriggerNotification}
                          streamBtnLoading={streamBtnLoading}
                          transcriptionBtnLoading={transcriptionBtnLoading}
                          config={{
                            file: {
                              attributes: { crossOrigin: "true" },
                              tracks: mapSrcListToTracks(mediaData.subtitlesList)
                            }
                          }}
                        />
                      )}
                    </Carousel>

                  </>
                )}
                {mediaData.mediadata.supportSegmentation && (
                  <Carousel
                    arrows
                    nextArrow={<ArrowRightOutlined />}
                    prevArrow={<ArrowLeftOutlined />}
                    afterChange={onChange}
                  >
                    <VideoDetailsRow
                      url={mediaData.original}
                      label={"original"}
                      key={"Original"}
                      videoDetails={mapVideoDetails("original")}
                      isAudio={isAudio}
                      handleReTriggerNotification={handleReTriggerNotification}
                      streamBtnLoading={streamBtnLoading}
                      transcriptionBtnLoading={transcriptionBtnLoading}
                    />
                    <VideoRowABR
                      url={mediaData.transcoded}
                      label={"Transcoded"}
                      key={"transcoded"}
                      videoDetails={mapVideoDetails("transcoded")}
                      handleReTriggerNotification={handleReTriggerNotification}
                      streamBtnLoading={streamBtnLoading}
                      transcriptionBtnLoading={transcriptionBtnLoading}
                    />
                    <VideoRowABR
                      url={mediaData.watermarked}
                      label={"Watermarked"}
                      key={"watermarked"}
                      videoDetails={mapVideoDetails("watermarked")}
                      handleReTriggerNotification={handleReTriggerNotification}
                      streamBtnLoading={streamBtnLoading}
                      transcriptionBtnLoading={transcriptionBtnLoading}
                    />
                    {mediaData.dynamicWatermarked && (
                      <VideoRowABR
                        url={mediaData.dynamicWatermarked}
                        label={"Dynamic Watermarked"}
                        key={"dynamicWatermarked"}
                        videoDetails={mapVideoDetails("dynamicWatermarked")}
                        overlayUrl={mediaData.dynamicWatermarkOverlay ? mediaData.dynamicWatermarkOverlay : null}
                        showDynamicOverlay={showDynamicOverlay}
                        handleReTriggerNotification={handleReTriggerNotification}
                        streamBtnLoading={streamBtnLoading}
                        transcriptionBtnLoading={transcriptionBtnLoading}
                      />
                    )}
                    {mediaData.subtitlesList && (
                      <VideoRowABR
                        url={mediaData.original}
                        label={"Transcriptions"}
                        key={"subtitles"}
                        videoDetails={mapVideoDetails("subtitles")}
                        handleReTriggerNotification={handleReTriggerNotification}
                        streamBtnLoading={streamBtnLoading}
                        transcriptionBtnLoading={transcriptionBtnLoading}
                        config={{
                          file: {
                            attributes: { crossOrigin: "true" },
                            tracks: mapSrcListToTracks(mediaData.subtitlesList)
                          }
                        }}
                      />
                    )}

                  </Carousel>
                )}
              </div>
              <div>
                <MultiImageCarousel mediaData={mediaData} />
                {/* osamaa */}
              </div>
            </div>
            <VideoPreview
              mediaInfo={JSON.stringify(mediaData.mediadata)}
              subscriberId={subscriberId}
            />
          </div>
        </>
      ) : (
        <div className="preview">{previewText}</div>
      )}
    </>
  );
}

export default VideoDetailsSection;
