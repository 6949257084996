import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import "./components.scss";
import InputText from "./util/InputText";
import InputSwitch from "./util/InputSwitch";
import { SubscriberData } from "types/subscriberData";
import InputTextarea from "./util/InputTextarea";
import withMessage, { WithMessageProps } from "../hoc/withMessage";
import { message, Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface MyComponentProps {
  subscriberData;
  nvObj
}

const SubscriberUpdate: React.FC<MyComponentProps & WithMessageProps> = ({ subscriberData, nvObj, showMessage }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [updateData, setUpdateData] = useState<SubscriberData>({});
  const [thumbnailImage, setThumbnailImage] = useState<File | undefined>(
    undefined
  );

  // console.log(updateData)

  useEffect(() => {
    setUpdateData(subscriberData);
  }, [subscriberData]);

  const handleUpdateText = (key, e) => {
    let value = e.target.value;
    if (key === "deleteMediaAfterDays") {
      value = parseInt(e.target.value);
    }
    setUpdateData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleUpdateRadio = (key, value) => {
    setUpdateData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleThumbnailImageUpload = (key, e) => {
    setThumbnailImage(e.target.files[0]);
  };

  const [open, setOpen] = useState(false);
  const confirm = () => {
    setOpen(false);
    handleSaveButton()
    // message.success('Next step.');
  };

  const cancel = () => {
    setOpen(false);
    // message.error('Click on cancel.');
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }

    console.log(updateData)
    if ('audiosMonthlyLimit' in updateData && (updateData.audiosMonthlyLimit == null || updateData.audiosMonthlyLimit === "null" || updateData.audiosMonthlyLimit === "" || Number(updateData.audiosMonthlyLimit) < 0)) {
      setOpen(newOpen)
    }
    else if ('videosMonthlyLimit' in updateData && (updateData.videosMonthlyLimit == null || updateData.videosMonthlyLimit === "null" || updateData.videosMonthlyLimit === "" || Number(updateData.videosMonthlyLimit) < 0)) {
      setOpen(newOpen)
    }
    else if ('callsMonthlyLimit' in updateData && (updateData.callsMonthlyLimit == null || updateData.callsMonthlyLimit === "null" || updateData.callsMonthlyLimit === "" || Number(updateData.callsMonthlyLimit) < 0)) {
      setOpen(newOpen)
    }
    else {
      console.log("insdideeee thissssss")
      // confirm();
      handleSaveButton()
    }

    // 

    // Determining condition before show the popconfirm.
    // console.log(condition);
    // if (condition) {
    //   confirm(); // next step
    // } else {
    //   setOpen(newOpen);
    // }
  };

  const handleSaveButton = () => {
    setBtnLoading(true);
    let updateRequestData = updateData;
    delete updateRequestData.thumbnailKey;

    if (updateData.mediaBackup && typeof updateData.mediaBackup === "string") {
      updateRequestData.mediaBackup = JSON.parse(
        updateData.mediaBackup as string
      );
    }
    if (
      updateData.whitelistedDomains &&
      typeof updateData.whitelistedDomains === "string"
    ) {
      updateRequestData.whitelistedDomains = JSON.parse(
        updateData.whitelistedDomains as string
      );
    }

    nvObj.registerASubscriber(updateRequestData, thumbnailImage)
      .then(() => {
        setBtnLoading(false);
        showMessage("Profile updated successfully", 'success');
      })
      .catch(err => {
        console.log("error: ", err);
        setBtnLoading(false);
        showMessage("Profile update failed", 'error');
      });
  };

  return (
    <div className="display_section">
      <div className="App">
        <Row className="App-header">
          <Col className="subscriber_heading">
            {subscriberData.organizationName}{" "}
          </Col>
        </Row>
        <div className="subscriber_info_section">
          <div className="header">
            <div>Update Subscriber Info</div>
            {/* <Button
              onClick={() => handleSaveButton()}
              type="primary"
              className="save-button"
              loading={btnLoading}
              disabled={btnLoading}
            >
              Save
            </Button> */}
            <Popconfirm
              title="Unlimited access is being given to Audios/Video/Calls. Do you want to continue?"
              open={open}
              onOpenChange={handleOpenChange}
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              {btnLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: 5 }} spin />} />}
              <a>Save</a>
            </Popconfirm>
          </div>
          {updateData &&
            subscriberDataKeys.map(key => {
              if (
                key === "enableSentimentAnalysis" ||
                key === "enableThumbnails" ||
                key === "enableTranscription" ||
                key === "isSandbox" ||
                key === "supportAdaptiveStreaming" ||
                key === "supportSingleStreamSegmentation"
              ) {
                return (
                  <InputSwitch
                    labelText={key}
                    type="text"
                    placeholder="e.g 50"
                    updateStateFunction={handleUpdateRadio}
                    value={updateData[key]}
                    disabled={false}
                  />
                );
              } else if (key === "whitelistedDomains" || key === "mediaBackup") {
                return (
                  <InputTextarea
                    labelText={key}
                    placeholder="e.g 50"
                    updateStateFunction={handleUpdateText}
                    value={JSON.stringify(updateData[key])}
                  />
                );
              } else if (key !== "secretApiKey") {
                return (
                  <InputText
                    labelText={key}
                    type="text"
                    updateStateFunction={handleUpdateText}
                    value={updateData[key]}
                    disabled={
                      key === "salesforceOrganizationId" ||
                        key === "subscriberId" ||
                        key === "authToken"
                        ? true
                        : false
                    }
                  />
                );
              } else {
                return <></>;
              }
            })}
          <InputText
            labelText={"Thumbnail Image"}
            type="file"
            updateStateFunction={handleThumbnailImageUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default withMessage(SubscriberUpdate);

const subscriberDataKeys = [
  "enableThumbnails",
  "salesforceOrganizationId",
  "subscriberId",
  "audiosMonthlyLimit",
  "videosMonthlyLimit",
  "callsMonthlyLimit",
  "enableTranscription",
  "salesforceInstanceName",
  "thumbnailKey",
  "country",
  "enableAnimatedThumbnails",
  "defaultLocale",
  "supportAdaptiveStreaming",
  "isSandbox",
  "enableSentimentAnalysis",
  "supportSingleStreamSegmentation",
  "defaultResolution",
  "organizationName",
  "organizationEdition",
  "deleteMediaAfterDays",
  "access_token",
  "refresh_token",
  "instance_url",
  "notificationEndpoint",
  "thumbnailTime",
  "nickname",
];
