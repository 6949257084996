import React, { useState, createContext } from "react";

const SubscribersContext = createContext<any>([]);

const SubscribersContextProvider = ({ children }) => {
  const [subscribersData, setSubscribersData] = useState(null);

  return (
    <SubscribersContext.Provider
      value={{ subscribersData, setSubscribersData }}
    >
      {children}
    </SubscribersContext.Provider>
  );
};

export { SubscribersContext, SubscribersContextProvider };
