import {
  gbInMb,
  mediaAuthorizerFunctionDuration,
  imageThumbnailSize,
  gifThumbnailSize,
  emptyLambdaDuration,
  notificationFunctionDuration,
  generateUploadTokenFunctionDuration,
  orchestrationfunctionDuration,
  transcodingFactor,
  transcodingFactorAudio,
  watermarkingFactor,
  avgThumbnailLamdaDuration
} from "./constants";
import { SubscriberData } from "./types/subscriberData";
import { CostData } from "types/costData";
import { RegionalPricing } from "./types/regionalPricing";
import {
  ProcessingLogsAttrs,
  AudioProcessingLogsAttrs
} from "types/subscriberCostLog";
import CostEstimatorComp from "components/CostEstimator";

export const lambdaMonthlyCost = (
  regionalPricing: RegionalPricing,
  monthlyInvokations: number,
  memoryInGb: number,
  durationInSec?: number
) => {
  let totalComputeSeconds =
    monthlyInvokations * (durationInSec ? durationInSec : 15);
  let gbSec = memoryInGb * totalComputeSeconds;
  let invokeCost = regionalPricing.lambdaInvokeCost * monthlyInvokations;

  return regionalPricing.lambdaCostPerGbSec * gbSec + invokeCost;
};

export const s3StorageCost = (
  regionalPricing: RegionalPricing,
  storageAmount: number,
  putRequests: number,
  getRequests: number
) => {
  let perGbRate = regionalPricing.s3StorageCost;
  if (storageAmount > 450 * 1024) {
    // 450 TB
    perGbRate = regionalPricing.s3StorageCost - 0.001;
  } else if (storageAmount > 500 * 1024) {
    // 500 TB
    perGbRate = regionalPricing.s3StorageCost - 0.002;
  }
  let storageCost = storageAmount * perGbRate;
  let requestsCost =
    regionalPricing.s3PutRequestCost * putRequests +
    regionalPricing.setGetRequestCost * getRequests;

  return storageCost + requestsCost;
};

export const eventBridgeCost = (
  regionalPricing: RegionalPricing,
  totalRequests: number
) => {
  return regionalPricing.eventBridgeRequestCost * totalRequests;
};

export const dynamoDbCost = (
  regionalPricing: RegionalPricing,
  storageAmountInKb: number,
  putRequests: number,
  getRequests: number
) => {
  let storageAmount = storageAmountInKb / 1048576; // kb to gb
  let storageCost = storageAmount * regionalPricing.dynamoDbPerGbCost;
  let requestsCost =
    putRequests * regionalPricing.dynamoDbPutCost +
    getRequests * regionalPricing.dynamoDbGetCost;

  return storageCost + requestsCost;
};

export const stepFunctionsCost = (
  regionalPricing: RegionalPricing,
  stateTransitions: number
) => {
  return stateTransitions * regionalPricing.stateTransitionCost;
};

export const wafCost = (
  regionalPricing: RegionalPricing,
  totalWhitelistedDomains: number,
  requests: number
) => {
  return (
    totalWhitelistedDomains * regionalPricing.wafRuleCost +
    requests * regionalPricing.wafRequestCost
  );
};

export const secretsManagerCost = (
  regionalPricing: RegionalPricing,
  requests: number
) => {
  return requests * regionalPricing.smPerRequestCost;
};

export const NatGatewayPerGbCost = (
  regionalPricing: RegionalPricing,
  gbProcessed: number
) => {
  return gbProcessed * regionalPricing.natPerGbRate;
};

export const NatGatewayHourlyCost = (
  regionalPricing: RegionalPricing,
  totalHours: number
) => {
  return regionalPricing.natHourlyRate * totalHours;
};

export const sentimentAnalysisCost = (
  regionalPricing: RegionalPricing,
  totalDocuments: number,
  characters: number
) => {
  //  tiered pricing
  //  Upto 10M units = 0.0001$
  // From 10-50M units = 0.00005$
  // Over 50M units = 0.000025$
  let minCharacter = 300;
  if (characters >= 300) {
    minCharacter = characters;
  }

  let totalCharacters = totalDocuments * minCharacter;
  let unit = totalCharacters / 100;

  if (unit < 10000000) {
    // 10 M
    return unit * 0.0001;
  } else if (unit > 10000000 && unit < 50000000) {
    return unit * 0.00005;
  } else {
    return unit * 0.000025;
  }
};

export const transcribeCost = (
  regionalPricing: RegionalPricing,
  durationInSec: number
) => {
  // First 250,000 mins = 0.024$ per min (0.0004 /s)
  // Next 750,000 mins = 0.015$ per min (0.00025 /s)
  // Next 4,000,000 minutes = 0.01020$ min (0.00017 /s)
  // Over 5,000,000 minutes = 0.00780$ min (0.00013 /s)
  let costPerSec = 0.0004;
  if (durationInSec / 60 > 250000 && durationInSec / 60 < 750000) {
    costPerSec = 0.00025;
  } else if (durationInSec / 60 > 750000 && durationInSec / 60 < 4000000) {
    costPerSec = 0.00017;
  } else if (durationInSec / 60 > 5000000) {
    costPerSec = 0.00013;
  }

  return durationInSec * costPerSec;
};

export const restApiGatewayCost = (requestCount: number) => {
  //  rest api cost
  let costPerReq = 0.0000035;
  if (requestCount > 333000000 && requestCount < 667000000) {
    // 333 million
    costPerReq = 0.0000028;
  } else if (requestCount > 667000000) {
    costPerReq = 0.00000238;
  }

  return requestCount * costPerReq;
};

export const socketApiGatewayCost = (
  regionalPricing: RegionalPricing,
  messageCount: number,
  avgConnectionTime: number,
  connectionCount: number
) => {
  //  rest api cost
  let costPerReq = 0.0000013;
  let messagesCost = messageCount * costPerReq;

  let connPrice =
    (avgConnectionTime / 60) *
    connectionCount *
    regionalPricing.socketConnCostPerMinute;

  return connPrice + messagesCost;
};

export const cloudFrontPricing = (
  regionalPricing: RegionalPricing,
  dataOut: number,
  requests: number
) => {
  //  price class 100 (US and Europe region caching)
  // tiered pricing
  // First 10TB = $0.085
  // Next 40TB = $0.080
  // Next 100TB  = $0.060
  // Next 350TB = $0.040
  // Next 524TB  = $0.030
  // Next 4PB  = $0.025
  // Over 5PB  = $0.020

  let dataCost = 0;
  if (dataOut < 10 * 1024) {
    // 10 TB
    dataCost = dataOut * 0.085;
  } else if (dataOut > 10 * 1024 && dataOut < 40 * 1024) {
    dataCost = dataOut * 0.08;
  } else if (dataOut > 40 * 1024 && dataOut < 100 * 1024) {
    dataCost = dataOut * 0.06;
  } else if (dataOut > 100 * 1024 && dataOut < 350 * 1024) {
    dataCost = dataOut * 0.04;
  } else if (dataOut > 350 * 1024 && dataOut < 524 * 1024) {
    dataCost = dataOut * 0.03;
  } else if (dataOut > 524 * 1024 && dataOut < 4 * 1024 * 1024) {
    dataCost = dataOut * 0.025;
  } else {
    dataCost = dataOut * 0.02;
  }

  let requestCost = requests * regionalPricing.cloudFrontRequestsCost;
  return dataCost + requestCost;
};

export const transferAccelerationCost = (
  regionalPricing: RegionalPricing,
  dataUploaded: number
) => {
  return dataUploaded * regionalPricing.transferAccelerationPerGb;
};

export const efsCost = (
  regionalPricing: RegionalPricing,
  gbPerMonth: number
) => {
  return gbPerMonth * regionalPricing.efsGbCost;
};

export const estimateLambdaExecutionTime = (
  mediaDuration: number,
  type: string,
  fileType?: string
) => {
  if (type === "transcode") {
    if (fileType === "audio") {
      return mediaDuration * transcodingFactorAudio;
    }
    return mediaDuration * transcodingFactor;
  } else if (type === "watermark") {
    return mediaDuration * watermarkingFactor;
  } else if (type === "thumbnail") {
    return avgThumbnailLamdaDuration;
  } else {
    return emptyLambdaDuration;
  }
};

export const baseProcessingAndLambdaCost = (
  regionalPricing: RegionalPricing,
  mediaData: ProcessingLogsAttrs | AudioProcessingLogsAttrs,
  subscriberData: SubscriberData,
  fileType: string,
  avgTranscodingProcessingTime?: number,
  avgMediaUploadTime?: number
) => {
  const uploadCount = mediaData.count;
  const avgMediaDurationInSec = mediaData.duration;
  const avgMediaSize = mediaData.size; // size in gb
  const externalStorageEnabled = subscriberData.subscriberMediaS3
    ? true
    : false;
  const multiStreamSegmentationEnabled =
    subscriberData.supportAdaptiveStreaming;
  const singleStreamSegmentationEnabled =
    subscriberData.supportSingleStreamSegmentation;
  let computeCost = 0,
    dbCost = 0,
    storageCost = 0,
    totalCost = 0,
    cdnCost = 0,
    otherCost = 0,
    vpcCost = 0;

  // cost of authorizer function
  let authCost = lambdaMonthlyCost(
    regionalPricing,
    uploadCount,
    128 * gbInMb,
    mediaAuthorizerFunctionDuration
  );
  computeCost += authCost;

  let totalUploadedSizeInGbs = avgMediaSize * uploadCount; // avg media size in gbs

  let dbGetCount = 4;
  let dbPutCount = 7;
  let s3GetCount = 2;
  let s3PutCount = 1;
  let efsStorageCount = 2;
  let s3GBStored = avgMediaSize * 2; // orignal + transcoded
  let efsGbStored = avgMediaSize * 2;

  // avg video segments = 10
  // max 5 resolutions
  if (multiStreamSegmentationEnabled) {
    dbPutCount += 2;
    s3PutCount += 50;
    efsStorageCount += 7; // 5 res and 2 fragments
    s3GBStored += 5 * avgMediaSize;
    efsGbStored += 7 * avgMediaSize;
  }
  if (singleStreamSegmentationEnabled) {
    dbPutCount += 2;
    s3PutCount += 10;
    efsStorageCount += 3;
    s3GBStored += avgMediaSize;
    efsGbStored += 3 * avgMediaSize;
  }

  // requests/storage for 1 month
  s3GetCount *= uploadCount;
  s3PutCount *= uploadCount;
  dbGetCount *= uploadCount;
  dbPutCount *= uploadCount;
  efsGbStored *= uploadCount;
  s3GBStored *= uploadCount;

  let monthlyS3Cost = 0;
  if (!externalStorageEnabled) {
    monthlyS3Cost = s3StorageCost(
      regionalPricing,
      s3GBStored,
      s3GetCount,
      s3PutCount
    );
  }
  let monthlyEfsCost = efsCost(regionalPricing, efsGbStored);
  // assuming one video takes a max of 1 hour to process so efs stores files only for 1 hour
  let efsGBHoursCost = monthlyEfsCost / (30 * 24);

  storageCost += monthlyS3Cost + efsGBHoursCost;

  //  assuming each row is 1kb
  let monthlyDbCost = dynamoDbCost(
    regionalPricing,
    uploadCount,
    dbPutCount,
    dbGetCount
  );
  dbCost += monthlyDbCost;

  let monthlyEventBridgeCost = eventBridgeCost(regionalPricing, uploadCount);
  otherCost += monthlyEventBridgeCost;

  // assuming average upload takes 30 seconds
  let monthlyApiGwCost =
    restApiGatewayCost(uploadCount) +
    socketApiGatewayCost(
      regionalPricing,
      2 * uploadCount,
      avgMediaUploadTime ? avgMediaUploadTime : 30,
      uploadCount
    );
  otherCost += monthlyApiGwCost;

  let monthlyTrasnferAccelerationCost = transferAccelerationCost(
    regionalPricing,
    totalUploadedSizeInGbs
  );
  cdnCost += monthlyTrasnferAccelerationCost;

  let transcodingLambdaCost = avgTranscodingProcessingTime
    ? lambdaMonthlyCost(
        regionalPricing,
        uploadCount,
        10,
        avgTranscodingProcessingTime
      )
    : lambdaMonthlyCost(
        regionalPricing,
        uploadCount,
        10,
        estimateLambdaExecutionTime(
          avgMediaDurationInSec,
          "transcode",
          fileType
        )
      );
  computeCost += transcodingLambdaCost;

  //  notification function cost
  let notificationLambdaCost = lambdaMonthlyCost(
    regionalPricing,
    uploadCount,
    1,
    notificationFunctionDuration
  );
  computeCost += notificationLambdaCost;

  // generate upload token function
  let uploadLambdaCost = lambdaMonthlyCost(
    regionalPricing,
    uploadCount,
    128 * gbInMb,
    generateUploadTokenFunctionDuration
  );
  computeCost += uploadLambdaCost;

  // orchestrator function cost
  let orchestratorLambdaCost = lambdaMonthlyCost(
    regionalPricing,
    uploadCount,
    1,
    orchestrationfunctionDuration
  );
  computeCost += orchestratorLambdaCost;

  let monthlyStepFuncCost = stepFunctionsCost(regionalPricing, uploadCount * 5);
  otherCost += monthlyStepFuncCost;

  let monthlyNatCost = NatGatewayPerGbCost(
    regionalPricing,
    uploadCount * avgMediaSize
  );
  vpcCost += monthlyNatCost;

  totalCost =
    storageCost + otherCost + dbCost + dbCost + computeCost + cdnCost + vpcCost;

  return {
    totalCost,
    storageCost,
    otherCost,
    dbCost,
    computeCost,
    cdnCost,
    vpcCost
  };
};

export const typeProcessingCost = (
  regionalPricing: RegionalPricing,
  mediaData: ProcessingLogsAttrs | {},
  subscriberData: SubscriberData,
  processingEnabled: Boolean,
  processingType: string,
  emptyFunctionCount: number,
  avgTranscriptionCharacters?: number
) => {
  let computeCost = 0,
    dbCost = 0,
    storageCost = 0,
    totalCost = 0,
    cdnCost = 0,
    otherCost = 0,
    vpcCost = 0;
  if (processingEnabled && "count" in mediaData) {
    const uploadCount = mediaData.count;
    const avgMediaDurationInSec = mediaData.duration;
    const avgMediaSize = mediaData.size;
    const externalStorageEnabled = subscriberData.subscriberMediaS3
      ? true
      : false;
    const multiStreamSegmentationEnabled =
      subscriberData.supportAdaptiveStreaming;
    const singleStreamSegmentationEnabled =
      subscriberData.supportSingleStreamSegmentation;

    let dbGetCount = 0;
    let dbPutCount = 0;
    let s3GetCount = 0;
    let s3PutCount = 0;
    let s3GBStored = 0; // orignal + transcoded
    let efsGbStored = 0;

    if (processingType === "watermark") {
      dbGetCount += 2;
      dbPutCount += 3;
      s3GetCount += 2;
      s3PutCount += 1;
      s3GBStored += avgMediaSize;
      efsGbStored += avgMediaSize * 2.5; // can be > 2 if multiple effect profiles are used

      if (multiStreamSegmentationEnabled) {
        dbPutCount += 1;
        s3GetCount += 1;
        s3PutCount += 40;
        s3GBStored += avgMediaSize * 5;
        efsGbStored += avgMediaSize * 7;
      }
      if (singleStreamSegmentationEnabled) {
        dbPutCount += 1;
        s3GetCount += 1;
        s3PutCount += 10;
        s3GBStored += avgMediaSize;
        efsGbStored += 3 * avgMediaSize;
      }
    }

    if (processingType === "thumbnail") {
      dbGetCount += 2;
      dbPutCount += 3;
      s3GetCount += 1;
      s3PutCount += 16;
      s3GBStored += imageThumbnailSize * 8 + gifThumbnailSize * 8;
      efsGbStored += imageThumbnailSize * 8 + gifThumbnailSize * 8;
    }

    if (processingType === "transcription") {
      dbGetCount += 2;
      dbPutCount += 6;
      s3GetCount += 1;
    }

    let monthlyS3Cost = 0;
    if (!externalStorageEnabled) {
      monthlyS3Cost = s3StorageCost(
        regionalPricing,
        s3GBStored,
        s3GetCount,
        s3PutCount
      );
    }
    let monthlyEfsCost = efsCost(regionalPricing, efsGbStored);
    // assuming one video takes a max of 1 hour to process so efs stores files only for 1 hour
    let efsGBHoursCost = monthlyEfsCost / (30 * 24);

    storageCost += monthlyS3Cost + efsGBHoursCost;

    //  assuming each row is 1kb
    let monthlyDbCost = dynamoDbCost(
      regionalPricing,
      uploadCount,
      dbPutCount,
      dbGetCount
    );
    dbCost += monthlyDbCost;

    if (processingType === "transcription") {
      let monthlyNatCost = NatGatewayPerGbCost(
        regionalPricing,
        uploadCount * avgMediaSize
      );
      vpcCost += monthlyNatCost;
    }

    let lambdaCost = 0;
    lambdaCost = lambdaMonthlyCost(
      regionalPricing,
      uploadCount,
      10,
      estimateLambdaExecutionTime(avgMediaDurationInSec, processingType)
    );
    computeCost += lambdaCost;

    if (processingType === "transcription") {
      computeCost += transcribeCost(
        regionalPricing,
        uploadCount * avgMediaDurationInSec
      );
      // if (sentimentAnalysisEnabled) {
      computeCost = sentimentAnalysisCost(
        regionalPricing,
        uploadCount,
        avgTranscriptionCharacters ? avgTranscriptionCharacters : 1000
      );
      // }
    }
  }
  computeCost = lambdaMonthlyCost(
    regionalPricing,
    emptyFunctionCount,
    10,
    emptyLambdaDuration
  );

  return {
    totalCost,
    storageCost,
    otherCost,
    dbCost,
    computeCost,
    cdnCost,
    vpcCost
  };
};

export const calculateAggregateCost = (
  baseCost: CostData,
  watermarkCost: CostData,
  transcribeCost: CostData,
  thumbnailCost: CostData,
  audioBaseCost: CostData,
  month: string
) => {
  return {
    month: month,
    cdnCost:
      baseCost.cdnCost +
      watermarkCost.cdnCost +
      transcribeCost.cdnCost +
      thumbnailCost.cdnCost +
      audioBaseCost.cdnCost,
    computeCost:
      baseCost.computeCost +
      watermarkCost.computeCost +
      transcribeCost.computeCost +
      thumbnailCost.computeCost +
      audioBaseCost.computeCost,
    dbCost:
      baseCost.dbCost +
      watermarkCost.dbCost +
      transcribeCost.dbCost +
      thumbnailCost.dbCost +
      audioBaseCost.dbCost,
    otherCost:
      baseCost.otherCost +
      watermarkCost.otherCost +
      transcribeCost.otherCost +
      thumbnailCost.otherCost +
      audioBaseCost.otherCost,
    storageCost:
      baseCost.storageCost +
      watermarkCost.storageCost +
      transcribeCost.storageCost +
      thumbnailCost.storageCost +
      audioBaseCost.storageCost,
    totalCost:
      baseCost.totalCost +
      watermarkCost.totalCost +
      transcribeCost.totalCost +
      thumbnailCost.totalCost +
      audioBaseCost.totalCost,
    vpcCost:
      baseCost.vpcCost +
      watermarkCost.vpcCost +
      transcribeCost.vpcCost +
      thumbnailCost.vpcCost +
      audioBaseCost.vpcCost
  };
};

export const calculateAggregatePlaybackCost = (
  baseCost: CostData,
  watermarkCost: CostData,
  transcribeCost: CostData,
  month: string
) => {
  return {
    month: month,
    cdnCost: baseCost.cdnCost + watermarkCost.cdnCost + transcribeCost.cdnCost,
    computeCost:
      baseCost.computeCost +
      watermarkCost.computeCost +
      transcribeCost.computeCost,
    dbCost: baseCost.dbCost + watermarkCost.dbCost + transcribeCost.dbCost,
    otherCost:
      baseCost.otherCost + watermarkCost.otherCost + transcribeCost.otherCost,
    storageCost:
      baseCost.storageCost +
      watermarkCost.storageCost +
      transcribeCost.storageCost,
    totalCost:
      baseCost.totalCost + watermarkCost.totalCost + transcribeCost.totalCost,
    vpcCost: baseCost.vpcCost + watermarkCost.vpcCost + transcribeCost.vpcCost
  };
};

export const monthlyPlaybackProcessingCost = (
  regionalPricing: RegionalPricing,
  playbackCount: number,
  playbackSizeInGbs: number,
  ignoreWafCost?: boolean,
  totalWhitelistedDomains?: number
) => {
  let computeCost = 0,
    dbCost = 0,
    storageCost = 0,
    totalCost = 0,
    cdnCost = 0,
    otherCost = 0,
    vpcCost = 0;
  // cost of authorizer function
  let authCost = lambdaMonthlyCost(
    regionalPricing,
    playbackCount,
    128 * gbInMb,
    mediaAuthorizerFunctionDuration
  );
  computeCost += authCost;

  let monthlyCloudfrontCost = cloudFrontPricing(
    regionalPricing,
    playbackSizeInGbs,
    playbackCount
  );
  cdnCost += monthlyCloudfrontCost;

  // get cost + put cost
  let monthlyDbCost =
    2 * playbackCount * regionalPricing.dynamoDbGetCost +
    2 * playbackCount * regionalPricing.dynamoDbPutCost;
  dbCost += monthlyDbCost;

  //  waf cost
  if (!ignoreWafCost) {
    let monthyWafCost = wafCost(
      regionalPricing,
      totalWhitelistedDomains ? totalWhitelistedDomains : 5,
      playbackCount
    );
    otherCost += monthyWafCost;
  }

  let monthlySecretManagerCost = secretsManagerCost(
    regionalPricing,
    2 * playbackCount
  );
  otherCost += monthlySecretManagerCost;

  let s3RequestsCost = 28 * playbackCount * regionalPricing.setGetRequestCost;
  storageCost += s3RequestsCost;

  totalCost = storageCost + otherCost + dbCost + dbCost + computeCost + cdnCost;

  return {
    totalCost,
    storageCost,
    otherCost,
    dbCost,
    computeCost,
    cdnCost,
    vpcCost
  };
};
