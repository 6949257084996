"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const client_chime_sdk_messaging_1 = require("@aws-sdk/client-chime-sdk-messaging");
const FullJitterBackoff_1 = require("../backoff/FullJitterBackoff");
const CSPMonitor_1 = require("../cspmonitor/CSPMonitor");
const Message_1 = require("../message/Message");
const DefaultReconnectController_1 = require("../reconnectcontroller/DefaultReconnectController");
const AsyncScheduler_1 = require("../scheduler/AsyncScheduler");
const DefaultSigV4_1 = require("../sigv4/DefaultSigV4");
const DefaultWebSocketAdapter_1 = require("../websocketadapter/DefaultWebSocketAdapter");
const WebSocketReadyState_1 = require("../websocketadapter/WebSocketReadyState");
const PrefetchOn_1 = require("./PrefetchOn");
class DefaultMessagingSession {
    constructor(configuration, logger, webSocket, reconnectController, sigV4) {
        this.configuration = configuration;
        this.logger = logger;
        this.webSocket = webSocket;
        this.reconnectController = reconnectController;
        this.sigV4 = sigV4;
        this.observerQueue = new Set();
        if (!this.webSocket) {
            this.webSocket = new DefaultWebSocketAdapter_1.default(this.logger);
        }
        if (!this.reconnectController) {
            this.reconnectController = new DefaultReconnectController_1.default(configuration.reconnectTimeoutMs, new FullJitterBackoff_1.default(configuration.reconnectFixedWaitMs, configuration.reconnectShortBackoffMs, configuration.reconnectLongBackoffMs));
        }
        if (!this.sigV4) {
            this.sigV4 = new DefaultSigV4_1.default(this.configuration.chimeClient);
        }
        CSPMonitor_1.default.addLogger(this.logger);
        CSPMonitor_1.default.register();
        this.preBootstrapMessages = [];
    }
    addObserver(observer) {
        this.logger.info('adding messaging observer');
        this.observerQueue.add(observer);
    }
    removeObserver(observer) {
        this.logger.info('removing messaging observer');
        this.observerQueue.delete(observer);
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isClosed()) {
                yield this.startConnecting(false);
            }
            else {
                this.logger.info('messaging session already started');
            }
        });
    }
    stop() {
        if (!this.isClosed()) {
            this.isClosing = true;
            this.webSocket.close();
            CSPMonitor_1.default.removeLogger(this.logger);
        }
        else {
            this.logger.info('no existing messaging session needs closing');
        }
    }
    forEachObserver(observerFunc) {
        for (const observer of this.observerQueue) {
            AsyncScheduler_1.default.nextTick(() => {
                if (this.observerQueue.has(observer)) {
                    observerFunc(observer);
                }
            });
        }
    }
    setUpEventListeners() {
        this.webSocket.addEventListener('open', () => {
            this.openEventHandler();
        });
        this.webSocket.addEventListener('message', (event) => {
            this.receiveMessageHandler(event.data);
        });
        this.webSocket.addEventListener('close', (event) => {
            this.closeEventHandler(event);
        });
        this.webSocket.addEventListener('error', () => {
            this.logger.error(`WebSocket error`);
        });
    }
    startConnecting(reconnecting) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.startConnectingInternal(reconnecting);
            return yield new Promise((resolve, reject) => {
                this.bootstrapResolved = resolve;
                this.bootstrapRejected = reject;
            });
        });
    }
    startConnectingInternal(reconnecting) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let endpointUrl = this.configuration.endpointUrl;
            // Moving this reconnect logic can potentially result into an infinite reconnect loop on errors.
            // Check https://github.com/aws/amazon-chime-sdk-js/issues/2372 for details.
            if (!reconnecting) {
                this.reconnectController.reset();
            }
            if (this.reconnectController.hasStartedConnectionAttempt()) {
                this.reconnectController.startedConnectionAttempt(false);
            }
            else {
                this.reconnectController.startedConnectionAttempt(true);
            }
            // reconnect needs to re-resolve endpoint url, which will also refresh credentials on client if they are expired
            if (reconnecting || endpointUrl === undefined) {
                try {
                    if (this.configuration.chimeClient.getMessagingSessionEndpoint instanceof Function) {
                        const response = yield this.configuration.chimeClient.getMessagingSessionEndpoint();
                        // Check for aws sdk v3 with v2 style compatibility first
                        if ((_a = response.Endpoint) === null || _a === void 0 ? void 0 : _a.Url) {
                            endpointUrl = response.Endpoint.Url;
                        }
                        else {
                            // Make aws sdk v2 call
                            const endpoint = yield this.configuration.chimeClient
                                .getMessagingSessionEndpoint()
                                .promise();
                            endpointUrl = endpoint.Endpoint.Url;
                        }
                    }
                    else {
                        endpointUrl = (yield this.configuration.chimeClient.send(new client_chime_sdk_messaging_1.GetMessagingSessionEndpointCommand({}))).Endpoint.Url;
                    }
                    this.logger.debug(`Messaging endpoint resolved to: ${endpointUrl}`);
                }
                catch (e) {
                    // send artificial close code event so the
                    // re-connect logic of underlying websocket client is
                    // triggered in the close handler
                    this.logger.error(`Messaging Session failed to resolve endpoint: ${e}`);
                    const closeEvent = new CloseEvent('close', {
                        wasClean: false,
                        code: 4999,
                        reason: 'Failed to get messaging session endpoint URL',
                        bubbles: false,
                    });
                    this.closeEventHandler(closeEvent);
                    return;
                }
            }
            const signedUrl = yield this.prepareWebSocketUrl(endpointUrl);
            this.logger.info(`opening connection to ${signedUrl}`);
            if (!reconnecting) {
                this.reconnectController.reset();
            }
            if (this.reconnectController.hasStartedConnectionAttempt()) {
                this.reconnectController.startedConnectionAttempt(false);
            }
            else {
                this.reconnectController.startedConnectionAttempt(true);
            }
            this.webSocket.create(signedUrl, [], true);
            this.forEachObserver(observer => {
                if (observer.messagingSessionDidStartConnecting) {
                    observer.messagingSessionDidStartConnecting(reconnecting);
                }
            });
            this.setUpEventListeners();
        });
    }
    prepareWebSocketUrl(endpointUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParams = new Map();
            queryParams.set('userArn', [this.configuration.userArn]);
            queryParams.set('sessionId', [this.configuration.messagingSessionId]);
            if (this.configuration.prefetchOn === PrefetchOn_1.default.Connect) {
                queryParams.set('prefetch-on', [PrefetchOn_1.default.Connect]);
            }
            if (this.configuration.prefetchSortBy) {
                queryParams.set('prefetch-sort-by', [this.configuration.prefetchSortBy]);
            }
            return yield this.sigV4.signURL('GET', 'wss', 'chime', endpointUrl, '/connect', '', queryParams);
        });
    }
    isClosed() {
        return (this.webSocket.readyState() === WebSocketReadyState_1.default.None ||
            this.webSocket.readyState() === WebSocketReadyState_1.default.Closed);
    }
    openEventHandler() {
        this.reconnectController.reset();
        this.isSessionEstablished = false;
    }
    receiveMessageHandler(data) {
        try {
            const jsonData = JSON.parse(data);
            const messageType = jsonData.Headers['x-amz-chime-event-type'];
            const message = new Message_1.default(messageType, jsonData.Headers, jsonData.Payload || null);
            if (!this.isSessionEstablished && messageType === 'SESSION_ESTABLISHED') {
                // Backend connects WebSocket and then either
                // (1) Closes with WebSocket error code to reflect failure to authorize or other connection error OR
                // (2) Sends SESSION_ESTABLISHED. SESSION_ESTABLISHED indicates that all messages and events on a channel
                // the app instance user is a member of is guaranteed to be delivered on this WebSocket as long as the WebSocket
                // connection stays opened.
                this.forEachObserver(observer => {
                    if (observer.messagingSessionDidStart) {
                        observer.messagingSessionDidStart();
                    }
                });
                this.bootstrapResolved();
                this.isSessionEstablished = true;
                // Send message and flush the queue.
                const preBootstrapMessageLength = this.preBootstrapMessages.length;
                for (let iter = 0; iter < preBootstrapMessageLength; iter++) {
                    const preBootstrapMessage = this.preBootstrapMessages.shift();
                    this.forEachObserver(observer => {
                        this.sendMessageToObserver(observer, preBootstrapMessage);
                    });
                }
            }
            else if (!this.isSessionEstablished) {
                // SESSION_ESTABLISHED is not guaranteed to be the first message, and in rare conditions a message or event from
                // a channel the member is a member of might arrive prior to SESSION_ESTABLISHED.  Because SESSION_ESTABLISHED indicates
                // it is safe to bootstrap the user application without any race conditions in losing events we opt to store messages prior
                // to SESSION_ESTABLISHED being received and send when once SESSION_ESTABLISHED.
                this.preBootstrapMessages.push(message);
                return;
            }
            this.forEachObserver(observer => {
                this.sendMessageToObserver(observer, message);
            });
        }
        catch (error) {
            this.logger.error(`Messaging parsing failed: ${error}`);
        }
    }
    sendMessageToObserver(observer, message) {
        if (observer.messagingSessionDidReceiveMessage) {
            observer.messagingSessionDidReceiveMessage(message);
        }
    }
    retryConnection() {
        return this.reconnectController.retryWithBackoff(() => __awaiter(this, void 0, void 0, function* () {
            yield this.startConnecting(true);
        }), null);
    }
    closeEventHandler(event) {
        this.logger.info(`WebSocket close: ${event.code} ${event.reason}`);
        if (event.code !== 4999) {
            this.webSocket.destroy();
        }
        if (!this.isClosing && this.canReconnect(event.code) && this.retryConnection()) {
            return;
        }
        this.isClosing = false;
        if (this.isSessionEstablished) {
            this.forEachObserver(observer => {
                if (observer.messagingSessionDidStop) {
                    observer.messagingSessionDidStop(event);
                }
            });
        }
        else {
            this.bootstrapRejected(event);
        }
    }
    canReconnect(closeCode) {
        // 4003 is Kicked closing event from the back end
        return (closeCode === 1001 ||
            closeCode === 1006 ||
            (closeCode >= 1011 && closeCode <= 1014) ||
            (closeCode > 4000 && closeCode !== 4002 && closeCode !== 4003 && closeCode !== 4401));
    }
}
exports.default = DefaultMessagingSession;
