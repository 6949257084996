import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button } from "antd";
import InputField from "./util/InputField";
import useAuth from "../context/useAuth";
import InputSelect from "./util/InputSelect";
import { regions } from "../constants";
import { RegionContext } from "context/regionContext";

const Login = () => {
  const { getAuthRedirectUrl } = useAuth();

  const { regionUrl, setRegionUrl, setRegionKey } = useContext(RegionContext);
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>();
  const [showLoader, setshowLoader] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    const redirectUrl = getAuthRedirectUrl();
    if (redirectUrl) {
      setshowLoader(false);
      window.location = redirectUrl;
    }
  };

  const handleRegionChange = value => {
    setRegionUrl(value);
    localStorage.setItem("region", value);
  };

  useEffect(() => {
    const active = regions.find(o => o.value === regionUrl);
    if (active) {
      setSelectedRegion(active.label as string);
      setRegionKey(active.xApiKeyName as string);
    }
  }, [regionUrl]);

  return (
    <div className="login_section">
      <form onSubmit={e => handleLogin(e)}>
        <Row justify="center" className={"form_row_login"}>
          <Col xl={24} md={24} lg={24} sm={22} xs={22} className="">
            <InputSelect
              name="region"
              selectedRegion={selectedRegion}
              handleChange={handleRegionChange}
            />
          </Col>
        </Row>
        <div className="btn_section">
          <Button
            type="primary"
            className="gradient_btn auth_btn"
            htmlType="submit"
            loading={showLoader}
            disabled={!selectedRegion}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
