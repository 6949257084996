import React from "react";
import "./util.scss";
import { regions } from "../../constants";
import { Select } from "antd";

const { Option } = Select;

function UpdateSelect(props) {
  let { options, labelText } = props;
  let dropdownOptions = options;
  if (!options) {
    dropdownOptions = regions;
  }
  return (
    <div className={`update_input`}>
      <label className="label">{labelText}</label>
      <Select
        className="value"
        defaultValue={"720"}
        // value={props.selectedRegion}
        onChange={e => props.handleChange(e)}
        bordered={false}
      >
        {dropdownOptions.map(region => {
          return (
            <>
              <Option value={region.value}>{region.label}</Option>
            </>
          );
        })}
      </Select>
    </div>
  );
}

export default UpdateSelect;
