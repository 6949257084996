"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertServerSideNetworkAdaptionEnumToSignaled = exports.convertServerSideNetworkAdaptionEnumFromSignaled = exports.ServerSideNetworkAdaption = void 0;
const SignalingProtocol_1 = require("../signalingprotocol/SignalingProtocol");
/**
 * [[ServerSideNetworkAdaption]] represents additional server side features that can be enabled for network adaption.
 */
var ServerSideNetworkAdaption;
(function (ServerSideNetworkAdaption) {
    /**
     * No features enabled, but can be overriden from server side values.
     */
    ServerSideNetworkAdaption[ServerSideNetworkAdaption["Default"] = 0] = "Default";
    /**
     * No features enabled. Will not be override from server side choice.
     */
    ServerSideNetworkAdaption[ServerSideNetworkAdaption["None"] = 1] = "None";
    /**
     * Disable the existing client side bandwidth probing methods of waiting and unpausing,
     * or waiting and upgrading simulcast streams (which can be large increases of bitrates
     * and may lead to periodic oversubscription over network capacity
     * and resulting video freezes) and replace it with more gradual server
     * side probing of increasing amounts padding packets until the
     * bandwidth estimate safely reaches the value needed to resume the
     * next video source, or upgrade to the next higher simulcast stream.
     *
     * When this is enabled, any policy 'probing' for bandwidth headroom
     * should be disabled. This may also enable pacing of some media packets from the server
     * side, and may also enable packet burst probing.
     *
     * End users should overall see reduced video freezes, reduced broken audio,
     * and reduced packet loss.
     */
    ServerSideNetworkAdaption[ServerSideNetworkAdaption["BandwidthProbing"] = 2] = "BandwidthProbing";
})(ServerSideNetworkAdaption = exports.ServerSideNetworkAdaption || (exports.ServerSideNetworkAdaption = {}));
exports.default = ServerSideNetworkAdaption;
function convertServerSideNetworkAdaptionEnumFromSignaled(adaption) {
    switch (adaption) {
        case SignalingProtocol_1.SdkServerSideNetworkAdaption.DEFAULT:
            return ServerSideNetworkAdaption.Default;
        case SignalingProtocol_1.SdkServerSideNetworkAdaption.NONE:
            return ServerSideNetworkAdaption.None;
        case SignalingProtocol_1.SdkServerSideNetworkAdaption.BANDWIDTH_PROBING:
            return ServerSideNetworkAdaption.BandwidthProbing;
    }
}
exports.convertServerSideNetworkAdaptionEnumFromSignaled = convertServerSideNetworkAdaptionEnumFromSignaled;
function convertServerSideNetworkAdaptionEnumToSignaled(adaption) {
    switch (adaption) {
        case ServerSideNetworkAdaption.Default:
            return SignalingProtocol_1.SdkServerSideNetworkAdaption.DEFAULT;
        case ServerSideNetworkAdaption.None:
            return SignalingProtocol_1.SdkServerSideNetworkAdaption.NONE;
        case ServerSideNetworkAdaption.BandwidthProbing:
            return SignalingProtocol_1.SdkServerSideNetworkAdaption.BANDWIDTH_PROBING;
    }
}
exports.convertServerSideNetworkAdaptionEnumToSignaled = convertServerSideNetworkAdaptionEnumToSignaled;
