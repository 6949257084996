import React from "react";
import Header from "../components/header";
import VideoDetailsSection from "../components/VideoDetailsSection";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import "./App.scss";

function MediaDetails() {
  let params = useParams();
  return (
    <Layout>
      <div className="App">
        {/* <Header headerText={`Media Key: ${params.mediaId}`} /> */}
        <VideoDetailsSection
          mediaId={params.mediaId}
          subscriberId={params.subscriberId}
        />
      </div>
    </Layout>
  );
}

export default MediaDetails;
