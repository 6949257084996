import React, { useState, createContext } from "react";

const MediaKeysContextDynamicContent = createContext<any>("");

const MediaKeysContextDynamicContentProvider = ({ children }) => {
  const [tokenTempObjects, setTokenTempObjects] = useState(null);

  return (
    <MediaKeysContextDynamicContent.Provider
      value={{ tokenTempObjects, setTokenTempObjects }}
    >
      {children}
    </MediaKeysContextDynamicContent.Provider>
  );
};

export {
  MediaKeysContextDynamicContent,
  MediaKeysContextDynamicContentProvider
};
