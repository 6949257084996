import React, { useState, createContext, useEffect } from "react";
import { regions } from "../constants";

const RegionContext = createContext<any>([]);

const RegionContextProvider = ({ children }) => {
  const [regionUrl, setRegionUrl] = useState<string>();
  const [regionKey, setRegionKey] = useState<string>();

  useEffect(() => {
    let region = localStorage.getItem("region");
    // dev as default region
    if (!region) {
      region = "https://dev.buff.nativevideo.co/v1/"
    }
    if (region) {
      setRegionUrl(region as string);
      const active = regions.find(o => o.value === region);
      if (active) {
        setRegionKey(active.xApiKeyName as string);
      }
    }
  }, [localStorage]);

  return (
    <RegionContext.Provider
      value={{ regionUrl, setRegionUrl, regionKey, setRegionKey }}
    >
      {children}
    </RegionContext.Provider>
  );
};

export { RegionContext, RegionContextProvider };
