import React, { useState, createContext, useEffect, useContext } from "react";
import NativeVideo from "nativevideo-sdk-js";
import { RegionContext } from "./regionContext";

const authContext = createContext<any>("");

function useAuth() {
  const [authed, setAuthed] = useState<boolean | null>(null);
  const { regionUrl, regionKey } = useContext(RegionContext);

  const authenticateLoginToken = () => {
    const loginToken = localStorage.getItem("loginToken");
    if (loginToken) {
      var data = {
        loginToken: loginToken
      };
      if (process.env[regionKey] !== undefined) {
        let nvObj = new NativeVideo({
          xApiKey: process.env[regionKey],
          backendUrl: regionUrl ? regionUrl : undefined,
        });
        return new Promise((resolve, reject) => {
          nvObj
            .adminLogin(data)
            .then(() => {
              setAuthed(true);
              resolve(true);
            })
            .catch(() => {
              setAuthed(false);
              reject(false);
            });
        });
      } else {
        setAuthed(false);
        return null;
      }
    } else {
      setAuthed(false);
      return null;
    }
  };

  useEffect(() => {
    if (regionUrl) authenticateLoginToken();
  }, [regionUrl]);

  return {
    authed,

    login(data, region: string, url: string) {
      if (region && url) {
        let nvObj = new NativeVideo({
          xApiKey: process.env[region],
          backendUrl: url,
        });
        return new Promise((resolve, reject) => {
          nvObj
            .adminLogin(data)
            .then((res: any) => {
              setAuthed(true);
              resolve(res.data);
              localStorage.setItem("loginToken", res.loginToken);
            })
            .catch(err => {
              setAuthed(false);
              reject(err);
            });
        });
      } else {
        setAuthed(false);
        return null;
      }
    },
    logout() {
      localStorage.removeItem("loginToken");
      setAuthed(false);
    },
    getAuthRedirectUrl() {
      if (process.env[regionKey] !== undefined) {
        let nvObj = new NativeVideo({
          xApiKey: process.env[regionKey],
          backendUrl: regionUrl ? regionUrl : undefined,
        });
        return nvObj.getSignUpUrl();
      } else {
        return null;
      }
    },
    authenticateLoginToken
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
