import useAuth from "../context/useAuth";
import { Navigate } from "react-router-dom";
import React from "react";

const RequireAuth = ({ children }) => {
  const { authed } = useAuth();

  if (authed === null) {
    return <p>Page Loading...</p>;
  } else if (authed === true) {
    return children;
  } else if (authed === false) {
    return <Navigate to="/login" replace />;
  }
};

export { RequireAuth };
