import React from "react";
import VideoImageRow from "./MediaDetails/VideoImageRow";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VideoGifRow from "./MediaDetails/VideoGifRow";

function MultiImageCarousel(props) {
  let { mediaData } = props;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <img
        src="/leftArrow.png"
        className={
          currentSlide === 0
            ? "disable"
            : "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        }
        alt="left"
        onClick={() => onClick()}
      />
    );
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <img
        src="/rightArrow.png"
        className={
          currentSlide === 0
            ? "disable"
            : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        }
        alt="right"
        onClick={() => onClick()}
      />
    );
  };

  return (
    <div>
      <div className="thumbnails_heading">Thumbnails</div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        renderButtonGroupOutside={true}
        customRightArrow={
          <CustomRightArrow rest={props.rest} onClick={props.onClick} />
        }
        customLeftArrow={
          <CustomLeftArrow rest={props.rest} onClick={props.onClick} />
        }
      >
        <VideoImageRow
          url={mediaData.thumbnail}
          key={"thumbnail"}
          label={"thumbnail"}
        />
        <VideoImageRow
          url={mediaData.thumbnailWithPlaybutton}
          key={"thumbnailWithPlaybutton"}
          label={"thumbnailWithPlaybutton"}
        />
        {mediaData.effectProfilePlaybuttonThumbnailKey && (
          <VideoImageRow
            url={mediaData.effectProfileThumbnailKey}
            key={"effectProfileThumbnailKey"}
            label={"effectProfileThumbnailKey"}
          />
        )}

        {mediaData.effectProfilePlaybuttonThumbnailKey && (
          <VideoImageRow
            url={mediaData.effectProfilePlaybuttonThumbnailKey}
            key={"effectProfilePlaybuttonThumbnailKey"}
            label={"effectProfilePlaybuttonThumbnailKey"}
          />
        )}

        {mediaData.animatedThumbnail && (
          <VideoGifRow
            url={mediaData.animatedThumbnail}
            key={"animatedThumbnail"}
            label={"animatedThumbnail"}
          />
        )}
      </Carousel>
    </div>
  );
}

export default MultiImageCarousel;
