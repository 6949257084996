import React from "react";
import Login from "../components/Login";
import "./App.scss";

function ZoomRedirect() {
    return (
        <div className="App">
            Successs
        </div>
    );
}

export default ZoomRedirect;
