import React from "react";
import "./util.scss";

function InputDropdown(props) {
    const { labelText, updateStateFunction, options, updateState, value } = props;
    return (

        <div className="update_input">
            <label className="label">{labelText}</label>
            <select
                value={value}
                onChange={(e) => {
                    console.log(e.target.value)
                    if (updateState) {
                        updateState(e.target.value)
                    } else {
                        updateStateFunction(labelText, e)
                    }
                }}
                className="value"
            >
                {options.map(opt => {
                    return (
                        <option value={opt.value}>{opt.label}</option>
                    )
                })}
            </select>
        </div>
    );
}

export default InputDropdown;
