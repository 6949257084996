import React, { useState, useEffect, useContext } from "react";
import NativeVideo from "nativevideo-sdk-js";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { SubscribersContext } from "../context/subscribersContext";
import "./App.scss";
import { RegionContext } from "../context/regionContext";
import UsageStatsTable from "../components/UsageStatsTable";
import { SubscriberUsageData } from "types/subscriberUsageData";
import { LoadingOutlined } from "@ant-design/icons";
import { SubscriberData } from "types/subscriberData";

function SubscriberMedia() {
  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [subscriberId, setSubscriberId] = useState<string>();
  const [mediaLogData, setMediaLogData] = useState<SubscriberUsageData>();
  const [
    selectedSubscriberData,
    setSelectedSubscriberData
  ] = useState<SubscriberData | null>(null);
  let params = useParams();

  useEffect(() => {
    if (params) setSubscriberId(params.id);
  }, [params]);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }

    if (subscribersData && subscriberId) {
      setSelectedSubscriberData(
        subscribersData.find(o => o.subscriberId === subscriberId)
      );
    }
  }, [subscribersData, subscriberId, regionUrl]);

  useEffect(() => {
    if (nvObj && subscriberId) {
      nvObj.getSubscriberUsageStats(subscriberId).then(res => {
        setMediaLogData(res as SubscriberUsageData);
      });
    }
  }, [nvObj, subscriberId]);

  return (
    <Layout>
      {mediaLogData ? (
        <div className="cost_table_section">
          <div className="table_head">Uploading & Processing</div>
          <UsageStatsTable
            logData={mediaLogData.processingLogs}
            processingStats={true}
          />

          <div className="table_head">Playback</div>
          <UsageStatsTable
            logData={mediaLogData.playbackLogs}
            processingStats={false}
          />
        </div>
      ) : (
        <div className="loader-div">
          <LoadingOutlined className="loading-icon" />
        </div>
      )}
    </Layout>
  );
}

export default SubscriberMedia;
