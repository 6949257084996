import React from "react";
import "./components.scss";

function MonthlyBill(props) {
  let {
    monthlyComputeCost,
    monthlyCdnCost,
    monthlyDbCost,
    monthlyOtherCost,
    monthlyVpcCost,
    monthlyTotalCost,
    monthlyStorageCost,
    regionalInstanceCost
  } = props;

  return (
    <div className="subscriber_info_section last_section">
      <div className="header">
        <div>Monthly Bill</div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> Compute cost </div>
        <div className="bill_value"> {monthlyComputeCost.toFixed(2)}$ </div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> Storage cost </div>
        <div className="bill_value"> {monthlyStorageCost.toFixed(2)}$ </div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> VPC cost </div>
        <div className="bill_value"> {monthlyVpcCost.toFixed(2)}$ </div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> Database cost </div>
        <div className="bill_value"> {monthlyDbCost.toFixed(2)}$ </div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> CDN cost </div>
        <div className="bill_value"> {monthlyCdnCost.toFixed(2)}$ </div>
      </div>
      <div className="bill_row">
        <div className="bill_label"> Other Services cost </div>
        <div className="bill_value"> {monthlyOtherCost.toFixed(2)}$ </div>
      </div>

      <div className="bill_row total">
        <div className="bill_label"> Total cost </div>
        <div className="bill_value"> {monthlyTotalCost.toFixed(2)}$ </div>
      </div>

      <div className="bill_row">
        <div className="bill_label"> Region Running cost </div>
        <div className="bill_value"> {regionalInstanceCost.toFixed(2)}$ </div>
      </div>
    </div>
  );
}

export default MonthlyBill;
