import React from "react";
import "./util.scss";

function InputTextarea(props) {
  const {
    labelText,
    placeholder,
    updateStateFunction,
    updateState,
    value,
    disabled
  } = props;
  return (
    <div className="update_input">
      <label className="label">{labelText}</label>
      <textarea
        onChange={(e) => {
          if (updateState) {
            updateState(e.target.value)
          } else {
            updateStateFunction(labelText, e)
          }
        }}
        className="value"
        placeholder={placeholder}
        disabled={disabled}
      >
        {value}
      </textarea>
    </div>
  );
}

export default InputTextarea;
