import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Switch } from "antd";
import { CaretDownOutlined, CaretUpOutlined, EditOutlined } from "@ant-design/icons";
import MediaInput from "./MediaInput";
import { EffectProfileTableRow } from "types/effectProfileTable";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withMessage, { WithMessageProps } from "../hoc/withMessage";

import "./components.scss";
import { fonts } from "../constants";

interface MyComponentProps {
  subscriberProfiles;
  subscriberId;
  nvObj;
}
const EffectProfile: React.FC<MyComponentProps & WithMessageProps> = ({ subscriberProfiles, subscriberId, nvObj, showMessage }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [opacity, setOpacity] = useState();
  const [label, setLabel] = useState();
  const [watermarkType, setWatermarkType] = useState("image");
  const [scale, setScale] = useState();
  const [position, setPosition] = useState("topLeft");
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [effectProfileData, setEffectProfileData] = useState<
    EffectProfileTableRow[]
  >();
  const [fontFamily, setFontFamily] = useState<string>();
  const [customFontFamily, setCustomFontFamily] = useState<string>();
  const [fontFile, setFontFile] = useState<File | undefined>();
  const [fontColor, setFontColor] = useState();
  const [fontSize, setFontSize] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [backgroundOpacity, setBackgroundOpacity] = useState();
  const [backgroundWidth, setBackgroundWidth] = useState();
  const [borderColor, setBorderColor] = useState();
  const [fontAngle, setFontAngle] = useState();
  const [defaultText, setDefaultText] = useState<string>("");
  const [repeatText, setRepeatText] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const handleFileSelected = e => {
    setFile(e.target.files[0]);
  };

  const handleFontFileSelected = e => {
    setFontFile(e.target.files[0])
    setCustomFontFamily(e.target.files[0].name)
  }

  const handleFileUpload = async () => {
    setLoading(true);
    var data = {
      watermarkType: watermarkType,
      isDefault: isDefault ? "true" : "false"
    };
    if (position) data["position"] = position;
    if (label) data["label"] = label;

    if (watermarkType === "image") {
      data["image"] = file;
      if (opacity) data["opacity"] = opacity;
      if (scale) data["scale"] = scale;
    } else {
      data["defaultText"] = defaultText;
      if (fontFile) data["fontFile"] = fontFile;
      if (fontFamily) data["fontFamily"] = fontFamily;
      if (customFontFamily) data["fontFamily"] = customFontFamily;
      if (fontColor) data["fontColor"] = fontColor;
      if (fontSize) data["fontSize"] = fontSize;
      if (backgroundColor) data["backgroundColor"] = backgroundColor;
      if (backgroundOpacity) data["backgroundOpacity"] = backgroundOpacity;
      if (backgroundWidth) data["backgroundWidth"] = backgroundWidth;
      if (borderColor) data["borderColor"] = borderColor;
      if (fontAngle) data["fontAngle"] = fontAngle;
      data["repeat"] = repeatText;
    }

    nvObj
      .upsertSubscriberProfiles(data)
      .then(() => {
        setLoading(false);
        showMessage("Profile updated successfully", 'success');
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        showMessage("Profile update failed", 'error');
      });
  };

  useEffect(() => {
    if (subscriberProfiles) {
      let tableData: EffectProfileTableRow[];
      tableData = [];
      for (const profile of subscriberProfiles) {
        let row = {
          key: profile.profileId,
          isDefault: profile.isDefault,
          name: profile.watermark.label
            ? profile.watermark.label
            : profile.profileId,
          position: profile.watermark.position
            ? profile.watermark.position
            : "-",
          scale: profile.watermark.scale ? profile.watermark.scale : "-",
          opacity: profile.watermark.opacity ? profile.watermark.opacity : "-",

        };
        tableData.push(row);
      }

      setEffectProfileData(tableData);
    }
  }, [subscriberProfiles]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Is Default",
      dataIndex: "isDefault"
    },
    {
      title: "Position",
      dataIndex: "position"
    },
    {
      title: "Edit",
      dataIndex: "key",
      render: (_, record) => (
        <EditOutlined
          className="ep-edit-icon"
          onClick={() => navigate(`/subscribers-effect-profile-update/${subscriberId}/${record["key"]}`, { state: subscriberProfiles })}
        />
      )
    },
  ];

  return (
    <div className="App">
      <header className="App-header">
        <Row justify="center" gutter={[20, 20]}>
          <Col
            className="upload_dropdown"
            onClick={() => setShowUploadForm(!showUploadForm)}
          >
            Create Effect Profile{" "}
            {!showUploadForm ? <CaretDownOutlined /> : <CaretUpOutlined />}
          </Col>
        </Row>
        {showUploadForm && (
          <div className="upload_form">
            <div className="form_row">
              <label className="form_label">Watermark Type : </label>
              <select
                onChange={e => setWatermarkType(e.target.value)}
                className="form_input"
              >
                <option value="image">Image</option>
                <option value="text">text</option>
              </select>
            </div>
            <div className="form_row">
              <label>Default Profile: </label>
              <Switch
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
              />
            </div>
            <MediaInput
              labelText="Label / Name : "
              type="text"
              placeholder="e.g nv-watermark"
              updateStateFunction={setLabel}
            />
            <div className="form_row">
              <label>Position: </label>
              <select
                onChange={e => setPosition(e.target.value)}
                className="form_input"
              >
                <option value="topLeft">topLeft</option>
                <option value="topRight">topRight</option>
                <option value="center">center</option>
                <option value="bottomLeft">bottomLeft</option>
                <option value="bottomRight">bottomRight</option>
              </select>
            </div>
            {watermarkType === "image" ? (
              <>
                <MediaInput
                  labelText="Scale (%): "
                  type="text"
                  placeholder="e.g 50"
                  updateStateFunction={setScale}
                />
                <MediaInput
                  labelText="Opacity (%): "
                  type="text"
                  placeholder="e.g 50"
                  updateStateFunction={setOpacity}
                />
              </>
            ) : (
              <>
                <MediaInput
                  labelText="Default Text: "
                  type="text"
                  placeholder="e.g NativeVideo"
                  updateStateFunction={setDefaultText}
                />
                <div className="form_row">
                  <label>Font Family: </label>
                  <select
                    onChange={e => setFontFamily(e.target.value)}
                    className="form_input"
                  >
                    {fonts.map(font => {
                      return (
                        <option key={font.value} value={font.value}>
                          {font.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {fontFamily === "other" && (
                  <>
                    <div className="form_row">
                      <label>Custom Font: </label>
                      <input
                        onChange={handleFontFileSelected}
                        accept=".otf, .ttf, .woff"
                        className="form_input_file"
                        type="file"
                      />
                    </div>
                  </>
                )}
                <MediaInput
                  labelText="Font Color: "
                  type="text"
                  placeholder="e.g 0xFFFFFF"
                  updateStateFunction={setFontColor}
                />
                <MediaInput
                  labelText="Font Size: "
                  type="text"
                  placeholder="e.g 24"
                  updateStateFunction={setFontSize}
                />
                <MediaInput
                  labelText="Background Color: "
                  type="text"
                  placeholder="e.g 0x000000"
                  updateStateFunction={setBackgroundColor}
                />
                <MediaInput
                  labelText="Background Opacity: "
                  type="text"
                  placeholder="0 - 100"
                  updateStateFunction={setBackgroundOpacity}
                />
                <MediaInput
                  labelText="Background/Box Width: "
                  type="text"
                  placeholder="e.g 5"
                  updateStateFunction={setBackgroundWidth}
                />
                <div className="form_row">
                  <label>Repeat watermark: </label>
                  <Switch
                    checked={repeatText}
                    onChange={() => setRepeatText(!repeatText)}
                  />
                </div>
                {repeatText && (
                  <>
                    <MediaInput
                      labelText="Angle: "
                      type="text"
                      placeholder="e.g 330"
                      updateStateFunction={setFontAngle}
                    />
                    <MediaInput
                      labelText="Border Color: "
                      type="text"
                      placeholder="e.g 0x000000"
                      updateStateFunction={setBorderColor}
                    />
                  </>
                )}
              </>
            )}

            {watermarkType === "image" && (
              <div className="form_row">
                <label>Watermark image: </label>
                <input
                  onChange={handleFileSelected}
                  accept=".png, .jpeg, .jpg"
                  className="form_input_file"
                  type="file"
                />
              </div>
            )}
            <Row className="upload_row" justify="center" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  className="upload_form_button"
                  loading={loading}
                  disabled={!file && !defaultText}
                  onClick={() => handleFileUpload()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </header>

      {effectProfileData ? (
        <div className="video_listing">
          <h1 className="heading">Your effect profiles</h1>
          <Table
            columns={columns}
            dataSource={effectProfileData}
          />
        </div>
      ) : (
        <div className="loader-div">
          <LoadingOutlined className="loading-icon" />
        </div>
      )}
    </div>
  );
}

export default withMessage(EffectProfile);
