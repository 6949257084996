"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
// Using an enum here to make sure we can expand on future features
var PrefetchOn;
(function (PrefetchOn) {
    PrefetchOn["Connect"] = "connect";
})(PrefetchOn || (PrefetchOn = {}));
exports.default = PrefetchOn;
