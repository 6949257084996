import React, { useState, useEffect, useContext } from "react";
import NativeVideo from "nativevideo-sdk-js";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { SubscribersContext } from "../context/subscribersContext";
import "./App.scss";
import { SubscriberData } from "types/subscriberData";
import { LoadingOutlined } from "@ant-design/icons";
import { RegionContext } from "../context/regionContext";
import {
  baseProcessingAndLambdaCost,
  typeProcessingCost,
  calculateAggregateCost,
  monthlyPlaybackProcessingCost,
  calculateAggregatePlaybackCost
} from "../util";
import { awsServiceRegionalCost } from "../constants";
import CostTable from "../components/CostTable";
import { CostData } from "types/costData";
import { RegionalPricing } from "types/regionalPricing";
import {
  SubscriberCostLog,
  PlaybackLogs,
  ProcessingLogs,
  AudioProcessingLogsAttrs
} from "types/subscriberCostLog";

function SubscriberMedia() {
  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [regionalPricing, setRegionalPricing] = useState<RegionalPricing>(
    awsServiceRegionalCost[0]
  );

  const [
    selectedSubscriberData,
    setSelectedSubscriberData
  ] = useState<SubscriberData | null>(null);
  const [subscriberId, setSubscriberId] = useState<string>();
  const [mediaLogData, setMediaLogData] = useState<SubscriberCostLog>();
  const [processingAggregateCost, setProcessingAggregateCost] = useState<
    CostData[]
  >();
  const [playbackAggregateCost, setPlaybackAggregateCost] = useState<
    CostData[]
  >();
  const { regionKey } = useContext(RegionContext);
  let params = useParams();

  useEffect(() => {
    if (regionKey) {
      let selectedRegion = awsServiceRegionalCost.find(
        i => i.regionId === regionKey
      );
      if (selectedRegion) {
        setRegionalPricing(selectedRegion);
      }
    }
  }, [regionKey]);

  useEffect(() => {
    if (params) setSubscriberId(params.id);
  }, [params]);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }

    if (subscribersData && subscriberId) {
      setSelectedSubscriberData(
        subscribersData.find(o => o.subscriberId === subscriberId)
      );
    }
  }, [subscribersData, subscriberId, regionUrl]);

  useEffect(() => {
    if (nvObj && subscriberId) {
      nvObj.getSubscriberMediaStats(subscriberId).then(res => {
        setMediaLogData(res as SubscriberCostLog);
      });
    }
  }, [nvObj, subscriberId]);

  const calculatePlaybackMediaCost = (mediaLogData: PlaybackLogs[]) => {
    let costTableData: CostData[] = [];
    for (const logData of mediaLogData) {
      const playbackCost = monthlyPlaybackProcessingCost(
        regionalPricing,
        logData.transcoded.count,
        logData.transcoded.size,
        true
      );

      let watermarkPlayback: CostData = {
        cdnCost: 0,
        computeCost: 0,
        dbCost: 0,
        otherCost: 0,
        storageCost: 0,
        totalCost: 0,
        vpcCost: 0
      };
      if ("count" in logData.watermarked) {
        watermarkPlayback = monthlyPlaybackProcessingCost(
          regionalPricing,
          logData.watermarked.count,
          logData.watermarked.size,
          true
        );
      }
      let transcribedPlayback: CostData = {
        cdnCost: 0,
        computeCost: 0,
        dbCost: 0,
        otherCost: 0,
        storageCost: 0,
        totalCost: 0,
        vpcCost: 0
      };
      if ("count" in logData.transcribed) {
        transcribedPlayback = monthlyPlaybackProcessingCost(
          regionalPricing,
          logData.transcribed.count,
          logData.transcribed.size,
          true
        );
      }

      costTableData.push(
        calculateAggregatePlaybackCost(
          playbackCost,
          watermarkPlayback,
          transcribedPlayback,
          logData.month
        )
      );
    }
    setPlaybackAggregateCost(costTableData);
  };

  const calculateProcessingMediaCost = (
    mediaLogData: ProcessingLogs[],
    selectedSubscriberData: SubscriberData
  ) => {
    let costTableData: CostData[] = [];
    for (const logData of mediaLogData) {
      let baseCost: CostData = {
        cdnCost: 0,
        computeCost: 0,
        dbCost: 0,
        otherCost: 0,
        storageCost: 0,
        totalCost: 0,
        vpcCost: 0
      };
      if ("count" in logData.transcoded) {
        baseCost = baseProcessingAndLambdaCost(
          regionalPricing,
          logData.transcoded,
          selectedSubscriberData,
          "video"
        );
      }

      let audioBaseCost: CostData = {
        cdnCost: 0,
        computeCost: 0,
        dbCost: 0,
        otherCost: 0,
        storageCost: 0,
        totalCost: 0,
        vpcCost: 0
      };
      if ("count" in logData.audioTranscoded) {
        audioBaseCost = baseProcessingAndLambdaCost(
          regionalPricing,
          logData.audioTranscoded,
          selectedSubscriberData,
          "audio"
        );
      }

      const watermarkCost = typeProcessingCost(
        regionalPricing,
        logData.watermarked,
        selectedSubscriberData,
        "count" in logData.watermarked,
        "watermark",
        "count" in logData.watermarked
          ? logData.mediaCount - logData.watermarked.count
          : logData.mediaCount
      );

      const transcribeCost = typeProcessingCost(
        regionalPricing,
        logData.transcribed,
        selectedSubscriberData,
        "count" in logData.transcribed,
        "transcribe",
        "count" in logData.transcribed
          ? logData.mediaCount - logData.transcribed.count
          : logData.mediaCount
      );
      const thumbnailCost = typeProcessingCost(
        regionalPricing,
        logData.thumbnailed,
        selectedSubscriberData,
        "count" in logData.thumbnailed,
        "thumbnail",
        "count" in logData.thumbnailed
          ? logData.mediaCount - logData.thumbnailed.count
          : logData.mediaCount
      );
      costTableData.push(
        calculateAggregateCost(
          baseCost,
          watermarkCost,
          transcribeCost,
          thumbnailCost,
          audioBaseCost,
          logData.month
        )
      );
    }
    setProcessingAggregateCost(costTableData);
  };

  useEffect(() => {
    if (mediaLogData && selectedSubscriberData) {
      calculateProcessingMediaCost(
        mediaLogData.processingLogs,
        selectedSubscriberData
      );

      calculatePlaybackMediaCost(mediaLogData.playbackLogs);
    }
  }, [mediaLogData, selectedSubscriberData]);

  return (
    <Layout>
      {playbackAggregateCost || playbackAggregateCost ? (
        <div className="cost_table_section">
        {processingAggregateCost && (
          <>
            <div className="table_head">Uploading & Processing Cost</div>
            <CostTable logData={processingAggregateCost} />
          </>
        )}

        {playbackAggregateCost && (
          <>
            <div className="table_head">Playback Cost</div>
            <CostTable logData={playbackAggregateCost} />
          </>
        )}
      </div>
      ): (
        <div className="loader-div">
          <LoadingOutlined className="loading-icon" />
        </div>
      )}
    </Layout>
  );
}

export default SubscriberMedia;
