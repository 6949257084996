"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
const ServerSideNetworkAdaption_1 = require("./ServerSideNetworkAdaption");
/**
 * [[SignalingClientJoin]] contains settings for the Join SignalFrame.
 */
class SignalingClientJoin {
    /**
     * Initializes a SignalingClientJoin with the given properties.
     * @param applicationMetadata [[ApplicationMetadata]].
     */
    constructor(applicationMetadata) {
        this.applicationMetadata = applicationMetadata;
        this.serverSideNetworkAdaption = ServerSideNetworkAdaption_1.default.Default;
        this.supportedServerSideNetworkAdaptions = [];
    }
}
exports.default = SignalingClientJoin;
