import React, { useEffect } from "react";
import "./App.scss";
import VideoJS from "../components/MediaDetails/VideoJS";

function TestPlayer() {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src:
          "https://nv-frontend-testing-app.s3.amazonaws.com/output/master.m3u8",

        type: "application/x-mpegURL"
      }
    ]
  };

  const handlePlayerReady = player => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("beforeRequest", () => {
      console.log("player is before request calling ");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div>Rest of app here</div>

      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

      <div>Rest of app here33</div>
    </>
  );
}

export default TestPlayer;
