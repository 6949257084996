"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
// Using an enum here to make sure we can expand on future features
var PrefetchSortBy;
(function (PrefetchSortBy) {
    PrefetchSortBy["Unread"] = "unread";
    PrefetchSortBy["LastMessageTimestamp"] = "last-message-timestamp";
})(PrefetchSortBy || (PrefetchSortBy = {}));
exports.default = PrefetchSortBy;
