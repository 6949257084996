import React, { useEffect, useState, useContext } from "react";
import { Select, Button } from "antd";

import "./components.scss";
import HealthStatus from "./util/HealthStatus";
import { SubscribersContext } from "../context/subscribersContext";
import { RegionContext } from "../context/regionContext";
import NativeVideo from "nativevideo-sdk-js";
import { DownloadMedia } from "types/downloadMedia";

const { Option } = Select;

function SystemHealth() {
  const [uploadStatus, setUploadStatus] = useState<string>("loading");
  const [transcodeStatus, setTranscodeStatus] = useState<string>("loading");
  const [watermarkStatus, setWatermarkStatus] = useState<string>("loading");
  const [transcribeStatus, setTranscribeStatus] = useState<string>("loading");
  const [websocketsStatus, setWebsocketsStatus] = useState<string>("loading");
  const [playbackStatus, setPlaybackStatus] = useState<string>("loading");
  const [subscriberToken, setSubscriberToken] = useState<string>();
  const [mediaId, setMediaId] = useState<string>("");
  const [testingFile, setTestingFile] = useState<File>();
  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [showStatusDiv, setShowStatusDiv] = useState<boolean>(false);

  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  var getFileBlob = function(url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function() {
      cb(xhr.response);
    });
    xhr.send();
  };

  var blobToFile = function(blob, name) {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
  };

  var getFileObject = function(filePathOrUrl, cb) {
    getFileBlob(filePathOrUrl, function(blob) {
      cb(blobToFile(blob, "video.mov"));
    });
  };

  useEffect(() => {
    getFileObject("video.mov", function(fileObject) {
      setTestingFile(fileObject);
    });
  }, []);

  useEffect(() => {
    nvObj?.on("upload:failed", () => {
      setUploadStatus("faulty");
    });

    nvObj?.on("upload:verified", params => {
      setWebsocketsStatus("healthy");
    });

    nvObj?.on("upload:processed", params => {
      setTranscodeStatus("healthy");
      fetchMediaDataWithBackoff(mediaId);
    });
  }, [nvObj, mediaId]);

  const handleUploadProgress = percentage => {
    console.log(percentage);
  };

  const checkImage = path =>
    new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        setPlaybackStatus("healthy");
        resolve(true);
      };
      img.onerror = () => {
        setPlaybackStatus("faulty");
        resolve(true);
      };
      img.src = path;
    });

  const fetchMediaDataWithBackoff = id => {
    let watermarkStatusFound = false;
    let TranscribingStatusFound = false;
    const intervalId = setInterval(function() {
      let mediaIds = [id];
      let otherParams = {
        adminRequest: true
      };
      if (id) {
        nvObj
          ?.getDownloadMediaUrls(mediaIds, otherParams)
          .then((res: DownloadMedia[]) => {
            const resData = res.find(o => o.mediadata.mediaId === id);
            if (resData) {
              // check playback status
              if (playbackStatus === "loading") {
                checkImage(resData.thumbnail);
              }
              if (watermarkStatusFound && TranscribingStatusFound) {
                clearInterval(intervalId);
              }
              if ("watermarking" in resData.mediadata) {
                if (resData.mediadata?.watermarking?.status === "completed") {
                  setWatermarkStatus("healthy");
                  watermarkStatusFound = true;
                }
                if (resData.mediadata?.watermarking?.status === "faulty") {
                  setWatermarkStatus("faulty");
                  watermarkStatusFound = true;
                }
              } else {
                watermarkStatusFound = true;
              }
              if ("transcribing" in resData.mediadata) {
                if (resData.mediadata?.transcribing?.status === "completed") {
                  setTranscribeStatus("healthy");
                  TranscribingStatusFound = true;
                }
                if (resData.mediadata?.transcribing?.status === "faulty") {
                  setTranscribeStatus("faulty");
                  TranscribingStatusFound = true;
                }
              } else {
                TranscribingStatusFound = true;
              }
              if (
                !("transcribing" in resData.mediadata) &&
                !("watermarking" in resData.mediadata)
              ) {
                clearInterval(intervalId);
              }
            } else {
              clearInterval(intervalId);
            }
          })
          .catch(err => {
            console.log("error: ", err);
            clearInterval(intervalId);
          });
      }
    }, 5000); // 5 sec
  };

  const handleFileUpload = async () => {
    if (subscribersData && subscriberToken && regionUrl && testingFile) {
      let nvObj = new NativeVideo({
        subscriberAuthToken: subscriberToken,
        backendUrl: regionUrl,
        adminAuthToken: localStorage.getItem("loginToken") as string | undefined
      });
      setnvObj(nvObj);

      let data = {
        file: testingFile,
        mimeType: testingFile.type,
        version: 1,
        requiresSocket: true,
        clientKey: "testKey123"
      };

      data["transcriptionLanguage"] = "en-US";
      data["effectProfile"] = [];
      nvObj
        .uploadMedia(data, handleUploadProgress, handleUploadProgress)
        .then(res => {
          setUploadStatus("healthy");
          setMediaId(res as string);
        });
    }
  };

  const handleSubscriberchange = e => {
    setSubscriberToken(e);
  };

  const checkHealthStatus = () => {
    setShowStatusDiv(true);
    handleFileUpload();
  };

  return (
    <div className="App">
      <div className="subscriber_row">
        <Select
          defaultValue="Select Test Subscriber"
          onChange={e => handleSubscriberchange(e)}
          bordered={false}
          dropdownMatchSelectWidth={200}
          dropdownClassName="region-dropdown"
        >
          {subscribersData &&
            subscribersData.map(subscriber => {
              return (
                <>
                  <Option value={subscriber.authToken}>
                    {subscriber.organizationName}
                  </Option>
                </>
              );
            })}
        </Select>
        <Button
          type="primary"
          size="large"
          className="upload_form_button"
          onClick={() => checkHealthStatus()}
        >
          Check Status
        </Button>
      </div>
      {showStatusDiv && (
        <>
          <HealthStatus status={uploadStatus} label="Uploading" />
          <HealthStatus status={transcodeStatus} label="Transcoding" />
          <HealthStatus status={watermarkStatus} label="Watermarking" />
          <HealthStatus status={transcribeStatus} label="Transcribing" />
          <HealthStatus status={websocketsStatus} label="Websockets" />
          <HealthStatus status={playbackStatus} label="Playback" />
        </>
      )}
    </div>
  );
}

export default SystemHealth;
