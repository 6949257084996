import React from "react";
import "./util.scss";
import { regions } from "../../constants";
import { Select } from "antd";

const { Option } = Select;

function InputSelect(props) {
  let { options } = props;
  let dropdownOptions = options;
  if (!options) {
    dropdownOptions = regions;
  }
  return (
    <div className={`input_field_select`}>
      <Select
        defaultValue={
          props.selectedRegion ? props.selectedRegion : "Select Region"
        }
        value={props.selectedRegion}
        onChange={e => props.handleChange(e)}
        bordered={false}
      >
        {dropdownOptions.map(region => {
          return (
            <>
              <Option value={region.value}>{region.label}</Option>
            </>
          );
        })}
      </Select>
    </div>
  );
}

export default InputSelect;
