import React from "react";
import Layout from "../components/Layout/Layout";
import "./App.scss";

function Dashboard() {
  return (
    <Layout>
      <div className="dashboard-section">
        <div>Dashboard coming soon</div>
      </div>
    </Layout>
  );
}

export default Dashboard;
