"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
const SimulcastContentShareTransceiverController_1 = require("../transceivercontroller/SimulcastContentShareTransceiverController");
/**
 * [[DefaultSimulcastUplinkPolicyForContentShare]] sets the capture and encode
 *  parameters based on constructor input parameters
 */
class DefaultSimulcastUplinkPolicyForContentShare {
    constructor(logger, encodingParams) {
        this.logger = logger;
        this.encodingParams = encodingParams;
        this.videoIndex = null;
        this.currLocalDescriptions = [];
        this.nextLocalDescriptions = [];
    }
    updateConnectionMetric(_metrics) {
        // Noop
    }
    chooseMediaTrackConstraints() {
        // Changing MediaTrackConstraints causes a restart of video input and possible small
        // scaling changes.  Always use 720p for now
        return undefined;
    }
    chooseEncodingParameters() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const newMap = new Map();
        const toBps = 1000;
        const nameArr = SimulcastContentShareTransceiverController_1.default.NAME_ARR_ASCENDING;
        newMap.set(nameArr[0], {
            rid: nameArr[0],
            active: true,
            scaleResolutionDownBy: ((_b = (_a = this.encodingParams) === null || _a === void 0 ? void 0 : _a.low) === null || _b === void 0 ? void 0 : _b.scaleResolutionDownBy) || 2,
            maxBitrate: (((_d = (_c = this.encodingParams) === null || _c === void 0 ? void 0 : _c.low) === null || _d === void 0 ? void 0 : _d.maxBitrateKbps) || 300) * toBps,
            maxFramerate: ((_f = (_e = this.encodingParams) === null || _e === void 0 ? void 0 : _e.low) === null || _f === void 0 ? void 0 : _f.maxFramerate) || 5,
        });
        newMap.set(nameArr[1], {
            rid: nameArr[1],
            active: true,
            scaleResolutionDownBy: ((_h = (_g = this.encodingParams) === null || _g === void 0 ? void 0 : _g.high) === null || _h === void 0 ? void 0 : _h.scaleResolutionDownBy) || 1,
            maxBitrate: (((_k = (_j = this.encodingParams) === null || _j === void 0 ? void 0 : _j.high) === null || _k === void 0 ? void 0 : _k.maxBitrateKbps) || 1200) * toBps,
            maxFramerate: (_m = (_l = this.encodingParams) === null || _l === void 0 ? void 0 : _l.high) === null || _m === void 0 ? void 0 : _m.maxFramerate,
        });
        this.getQualityMapString(newMap);
        return newMap;
    }
    updateIndex(videoIndex) {
        this.videoIndex = videoIndex;
    }
    wantsResubscribe() {
        var _a, _b;
        let constraintDiff = false;
        this.nextLocalDescriptions = (_a = this.videoIndex) === null || _a === void 0 ? void 0 : _a.localStreamDescriptions();
        for (let i = 0; i < ((_b = this.nextLocalDescriptions) === null || _b === void 0 ? void 0 : _b.length); i++) {
            const streamId = this.nextLocalDescriptions[i].streamId;
            if (streamId !== 0 && !!streamId) {
                const prevIndex = this.currLocalDescriptions.findIndex(val => {
                    return val.streamId === streamId;
                });
                if (prevIndex !== -1) {
                    if (this.nextLocalDescriptions[i].disabledByWebRTC !==
                        this.currLocalDescriptions[prevIndex].disabledByWebRTC) {
                        constraintDiff = true;
                    }
                }
            }
        }
        this.currLocalDescriptions = this.nextLocalDescriptions;
        return constraintDiff;
    }
    chooseCaptureAndEncodeParameters() {
        // should deprecate in this policy
        return undefined;
    }
    maxBandwidthKbps() {
        // should deprecate in this policy
        return 1200;
    }
    setIdealMaxBandwidthKbps(_idealMaxBandwidthKbps) {
        // should deprecate in this policy
    }
    setHasBandwidthPriority(_hasBandwidthPriority) {
        // should deprecate in this policy
    }
    getQualityMapString(params) {
        var _a;
        let qualityString = '';
        const localDescriptions = (_a = this.videoIndex) === null || _a === void 0 ? void 0 : _a.localStreamDescriptions();
        if ((localDescriptions === null || localDescriptions === void 0 ? void 0 : localDescriptions.length) > 0) {
            params.forEach((value) => {
                let disabledByWebRTC = false;
                if (value.rid === 'low')
                    disabledByWebRTC = localDescriptions[0].disabledByWebRTC;
                else
                    disabledByWebRTC = localDescriptions[1].disabledByWebRTC;
                qualityString += `{ rid: ${value.rid} active:${value.active} disabledByWebRTC: ${disabledByWebRTC} maxBitrate:${value.maxBitrate} scaleResolutionDownBy:${value.scaleResolutionDownBy} maxFrameRate:${value.maxFramerate}`;
            });
            this.logger.info(`simulcast: content policy:chooseEncodingParameters newQualityMap: ${qualityString}`);
        }
    }
    addObserver(_observer) { }
    removeObserver(_observer) { }
    forEachObserver(_observerFunc) { }
}
exports.default = DefaultSimulcastUplinkPolicyForContentShare;
