import { RegionalPricing } from "./types/regionalPricing";
import { LogLevel } from "amazon-chime-sdk-js";

export const BytesInMbs: number = 1000000;
export const regions = [
  {
    key: "us01", // backend regional prefix
    label: "US Ohio (us01)",
    value: "https://us01.buff.nativevideo.co/v1/",
    xApiKeyName: "REACT_APP_US01_KEY"
  },
  {
    key: "eu",
    label: "EU Frankurt (eu01)",
    value: "https://eu01.buff.nativevideo.co/v1/",
    xApiKeyName: "REACT_APP_EU01_KEY"
  },
  {
    key: "us-dev",
    label: "US N. Virginia (dev)",
    value: "https://dev.buff.nativevideo.co/v1/",
    xApiKeyName: "REACT_APP_DEV_KEY"
  },
  {
    key: "ap01",
    label: "AP Sydney (ap01)",
    value: "https://ap01.buff.nativevideo.co/v1/",
    xApiKeyName: "REACT_APP_AP01_KEY"
  }
];

export const awsServiceRegionalCost: RegionalPricing[] = [
  {
    regionId: "REACT_APP_DEV_KEY",
    lambdaGbInMb: 0.0009765625,
    lambdaCostPerGbSec: 0.0000166667,
    lambdaInvokeCost: 0.0000002,
    s3StorageCost: 0.023,
    s3PutRequestCost: 0.000005,
    setGetRequestCost: 0.0000004,
    transferAccelerationPerGb: 0.08,
    dynamoDbPerGbCost: 0.25,
    dynamoDbPutCost: 0.00000125,
    dynamoDbGetCost: 0.00000025,
    stateTransitionCost: 0.000025,
    wafRequestCost: 0.0000006,
    wafRuleCost: 1,
    socketConnCostPerMinute: 0.00000025,
    smPerRequestCost: 0.000005,
    natPerGbRate: 0.045,
    natHourlyRate: 0.045,
    cloudFrontRequestsCost: 0.0000012,
    efsGbCost: 0.3,
    eventBridgeRequestCost: 0.000001
  },
  {
    regionId: "REACT_APP_US01_KEY",
    lambdaGbInMb: 0.0009765625,
    lambdaCostPerGbSec: 0.0000166667,
    lambdaInvokeCost: 0.0000002,
    s3StorageCost: 0.023,
    s3PutRequestCost: 0.000005,
    setGetRequestCost: 0.0000004,
    transferAccelerationPerGb: 0.08,
    dynamoDbPerGbCost: 0.25,
    dynamoDbPutCost: 0.00000125,
    dynamoDbGetCost: 0.00000025,
    stateTransitionCost: 0.000025,
    wafRequestCost: 0.0000006,
    wafRuleCost: 1,
    socketConnCostPerMinute: 0.00000025,
    smPerRequestCost: 0.000005,
    natPerGbRate: 0.045,
    natHourlyRate: 0.045,
    cloudFrontRequestsCost: 0.0000012,
    efsGbCost: 0.3,
    eventBridgeRequestCost: 0.000001
  },
  {
    regionId: "REACT_APP_EU01_KEY",
    lambdaGbInMb: 0.0009765625,
    lambdaCostPerGbSec: 0.0000166667,
    lambdaInvokeCost: 0.0000002,
    s3StorageCost: 0.0245,
    s3PutRequestCost: 0.0000054,
    setGetRequestCost: 0.00000043,
    transferAccelerationPerGb: 0.08,
    dynamoDbPerGbCost: 0.25,
    dynamoDbPutCost: 0.000001525,
    dynamoDbGetCost: 0.000000305,
    stateTransitionCost: 0.000025,
    wafRequestCost: 0.0000006,
    wafRuleCost: 1,
    socketConnCostPerMinute: 0.000000285,
    smPerRequestCost: 0.000005,
    natPerGbRate: 0.052,
    natHourlyRate: 0.052,
    cloudFrontRequestsCost: 0.0000012,
    efsGbCost: 0.36,
    eventBridgeRequestCost: 0.000001
  },
  {
    regionId: "REACT_APP_AP01_KEY",
    lambdaGbInMb: 0.0009765625,
    lambdaCostPerGbSec: 0.0000166667,
    lambdaInvokeCost: 0.0000002,
    s3StorageCost: 0.025,
    s3PutRequestCost: 0.0000055,
    setGetRequestCost: 0.00000044,
    transferAccelerationPerGb: 0.08,
    dynamoDbPerGbCost: 0.25,
    dynamoDbPutCost: 0.0000014231,
    dynamoDbGetCost: 0.0000002846,
    stateTransitionCost: 0.000025,
    wafRequestCost: 0.0000006,
    wafRuleCost: 1,
    socketConnCostPerMinute: 0.000000325,
    smPerRequestCost: 0.000005,
    natPerGbRate: 0.059,
    natHourlyRate: 0.059,
    cloudFrontRequestsCost: 0.0000012,
    efsGbCost: 0.36,
    eventBridgeRequestCost: 0.000001
  }
];

export const gbInMb = 0.0009765625;

// multiply video duration with this to get the estimated lambda execuation time
export const transcodingFactor = 0.45;

export const transcodingFactorAudio = 0.16;
export const watermarkingFactor = 1.2;

// duration for lambda with no processing
export const emptyLambdaDuration = 0.4;
export const avgThumbnailLamdaDuration = 13.7; // seconds
export const mediaAuthorizerFunctionDuration = 0.49; // seconds

export const notificationFunctionDuration = 3.7; // sec
export const orchestrationfunctionDuration = 0.51; // sec
export const generateUploadTokenFunctionDuration = 0.95; // sec

// thumbnails avg size in gbs
export const imageThumbnailSize = 0.1 * gbInMb;
export const gifThumbnailSize = 1 * gbInMb;

export const monthlyHours = 730;
export const regionalEcrCost = 2;
export const globalWafCost = 5;

export const SDK_LOG_LEVELS = {
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  off: LogLevel.OFF
};

export const fonts = [
  {
    value: "Quicksand.ttf",
    label: "Quicksand"
  },
  {
    value: "Arial.ttf",
    label: "Arial"
  },
  {
    value: "Comic-sans.ttf",
    label: "Comic sans"
  },
  {
    value: "Helvetica.ttf",
    label: "Helvetica"
  },
  {
    value: "Times-new-roman.tff",
    label: "Times new roman"
  },
  {
    value: "Peak-Sans_Regular.ttf",
    label: "Peak Sans"
  },
  {
    value: "other",
    label: "Other"
  }
];
