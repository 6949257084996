import React, { useState, createContext } from "react";

const MediaKeysContext = createContext<any>("");

const MediaKeysContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);

  return (
    <MediaKeysContext.Provider value={{ authToken, setAuthToken }}>
      {children}
    </MediaKeysContext.Provider>
  );
};

export { MediaKeysContext, MediaKeysContextProvider };
