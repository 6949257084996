import React from "react";
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import "./components.scss";
import { EditOutlined } from "@ant-design/icons";

function SubscriberOverview(props) {
  const navigate = useNavigate();
  let { subscriberData } = props;

  return (
    <div className="App">
      <Row className="App-header">
        <Col className="subscriber_heading">
          {subscriberData.organizationName}{" "}
        </Col>
      </Row>
      <div className="subscriber_info_section">
        <div className="header">
          <div>Subscriber Info</div>
          <div
            onClick={() =>
              navigate(`/subscriber-update/${subscriberData.subscriberId}`)
            }
            className="edit-icon"
          >
            <EditOutlined />
          </div>
        </div>
        {Object.keys(subscriberData).map(key => {
          return (
            <>
              <div className="data_row">
                <div className="label">{key}</div>
                <div className="value">
                  {subscriberData[key] ? subscriberData[key].toString() : "-"}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SubscriberOverview;
