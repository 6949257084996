"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
const BaseConnectionHealthPolicy_1 = require("./BaseConnectionHealthPolicy");
class SendingAudioFailureConnectionHealthPolicy extends BaseConnectionHealthPolicy_1.default {
    constructor(logger, configuration, data) {
        super(configuration, data, 'Sending Audio Health');
        this.logger = logger;
        this.sendingAudioFailureSamplesToConsider =
            configuration.sendingAudioFailureSamplesToConsider > 0
                ? configuration.sendingAudioFailureSamplesToConsider
                : 1;
        this.sendingAudioFailureInitialWaitTimeMs = configuration.sendingAudioFailureInitialWaitTimeMs;
        this.maximumTimesToWarn = configuration.maximumTimesToWarn;
        this.coolDownTimeMs = configuration.cooldownTimeMs;
        this.lastWarnTimestampMs = 0;
        this.warnCount = 0;
    }
    isSendingAudioUnhealthy() {
        const hasEnoughTimeElapsedToEvaluateStatus = !this.currentData.isConnectionStartRecent(this.sendingAudioFailureInitialWaitTimeMs);
        const areAudioPacketsNotBeingSent = this.currentData.consecutiveStatsWithNoAudioPacketsSent >=
            this.sendingAudioFailureSamplesToConsider;
        return hasEnoughTimeElapsedToEvaluateStatus && areAudioPacketsNotBeingSent;
    }
    health() {
        if (this.isSendingAudioUnhealthy()) {
            const didWarnRecently = Date.now() - this.lastWarnTimestampMs < this.coolDownTimeMs;
            if (this.currentHealth > this.minimumHealth() && !didWarnRecently) {
                this.logger.warn(`Sending Audio is unhealthy for ${this.sendingAudioFailureSamplesToConsider} seconds consecutively.`);
                this.warnCount++;
                if (this.warnCount > this.maximumTimesToWarn) {
                    this.logger.warn('SendingAudioFailure health policy maximum warnings breached. Falling back to reporting healthy.');
                    return this.maximumHealth();
                }
                this.lastWarnTimestampMs = Date.now();
                return this.minimumHealth();
            }
            else {
                return this.currentHealth;
            }
        }
        return this.maximumHealth();
    }
}
exports.default = SendingAudioFailureConnectionHealthPolicy;
