import React, { useState, useEffect, useContext } from "react";
import EffectProfile from "../components/EffectProfile";
import NativeVideo from "nativevideo-sdk-js";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { SubscribersContext } from "../context/subscribersContext";
import "./App.scss";
import { SubscriberProfile } from "types/subscriberProfile";
import { RegionContext } from "../context/regionContext";
import { useLocation, useNavigate } from "react-router-dom";
import EffectProfileUpdate from "components/EffectProfileUpdate";

function SubscriberEffectProfilesUpdate() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { subscribersData } = useContext(SubscribersContext);
  const { regionUrl } = useContext(RegionContext);

  const [nvObj, setnvObj] = useState<NativeVideo>();
  const [subscriberProfile, setSubscriberProfile] = useState<
    SubscriberProfile
  >();
  const [subscriberId, setSubscriberId] = useState<string>();
  const [effectProfileId, setEffectProfileId] = useState<string>();
  let params = useParams();

  useEffect(() => {
    if (params) {
      setSubscriberId(params.subscriberId);
      setEffectProfileId(params.profileId)
    }
  }, [params]);

  useEffect(() => {
    if (!state) {
      navigate(`/subscribers-effect-profile/${subscriberId}`)
    }
    if (state && effectProfileId) {
      const allProfiles = state as SubscriberProfile[]
      let selectedProfile = allProfiles.find(
        sub => sub.profileId === effectProfileId
      );
      setSubscriberProfile(selectedProfile)
    }

  }, [state, effectProfileId, navigate, subscriberId]);

  useEffect(() => {
    if (subscribersData && subscriberId && regionUrl) {
      let selectedSubscriber = subscribersData.find(
        sub => sub.subscriberId === subscriberId
      );
      setnvObj(
        new NativeVideo({
          subscriberAuthToken: selectedSubscriber.authToken,
          backendUrl: regionUrl,
          adminAuthToken: localStorage.getItem("loginToken") as
            | string
            | undefined
        })
      );
    }
  }, [subscribersData, subscriberId, regionUrl]);


  return (
    <Layout>
      <EffectProfileUpdate
        subscriberProfile={subscriberProfile}
        subscriberId={subscriberId}
        nvObj={nvObj}
      />
    </Layout>
  );
}

export default SubscriberEffectProfilesUpdate;
