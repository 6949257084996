import React from "react";
import { Row, Col, Button } from "antd";
import ReactPlayer from "react-player";
import "../components.scss";

function VideoDetailsRow(props) {
  const { url, config, label, videoDetails, isAudio, transcriptionBtnLoading, streamBtnLoading, handleReTriggerNotification } = props;

  return (
    <div className="media_details">
      <Row justify="center" className="video_player_row">
        <Col className="vid_col">
          {isAudio && url.includes("mp3") ? (
            <audio controls className="audio_player">
              <source src={url} type="audio/mp3" />
            </audio>
          ) : (
            <ReactPlayer
              url={url}
              width="100%"
              controls={true}
              config={config}
            />
          )}
        </Col>
      </Row>
      <Row className="video_detail_row">
        <Col className="label_col">
          <div>
            <span className="media_label">{label}</span>
          </div>
          <div>
            <Button
              size="large"
              className="notification_btn"
              loading={streamBtnLoading}
              onClick={() => handleReTriggerNotification("streams")}
            >
              Streams Notification
            </Button>
          </div>
          <div>
            <Button
              size="large"
              className="notification_btn"
              loading={transcriptionBtnLoading}
              onClick={() => handleReTriggerNotification("transcription")}
            >
              Audio Notification
            </Button>
          </div>
        </Col>
        <Col className="detail_col">
          {videoDetails && (
            <>
              {videoDetails.fileSize && (
                <div>
                  <span className="detail_label">File size: </span>{" "}
                  {videoDetails.fileSize} mb
                </div>
              )}
              {videoDetails.processingTime && (
                <div>
                  <span className="detail_label">Processing time: </span>{" "}
                  {videoDetails.processingTime}
                </div>
              )}
              {videoDetails.resolution && (
                <div>
                  <span className="detail_label">Resolution: </span>{" "}
                  {videoDetails.resolution}
                </div>
              )}
              {videoDetails.duration && (
                <div>
                  <span className="detail_label">Media duration: </span>{" "}
                  {videoDetails.duration} sec
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default VideoDetailsRow;
