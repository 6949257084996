import React from "react";
import { Table } from "antd";
import "./components.scss";

function UsageStatsTable(props) {
  let { logData, processingStats } = props;
  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      render(text, record) {
        return {
          children: <div>{text}</div>
        };
      }
    },
    {
      title: processingStats ? "Media Count" : "Playback Count",
      dataIndex: processingStats ? "mediaCount" : "playbackCount",
      render(text, _) {
        return {
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Size (gb)",
      dataIndex: "size",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Duration (min)",
      dataIndex: "duration",
      render(text, _) {
        return {
          children: <div>{text.toFixed(2)}</div>
        };
      }
    },
    {
      title: "Users",
      dataIndex: "users",
      render(text, _) {
        return {
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Videos",
      dataIndex: "videos",
      render(text, _) {
        return {
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Audios",
      dataIndex: "audios",
      render(text, _) {
        return {
          children: <div>{text}</div>
        };
      }
    }
  ];
  return (
    <div className="tablediv">
      {logData && (
        <Table columns={columns} dataSource={logData} scroll={{ y: "48vh" }} />
      )}
    </div>
  );
}

export default UsageStatsTable;
