import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.scss";
import Dashboard from "./pages/Dashboard";
import SubscriberMedia from "./pages/SubscriberMedia";
import SubscriberOverview from "./pages/SubscriberOverview";
import SubscriberCost from "./pages/SubscriberCost";
import SubscriberUsage from "./pages/SubscriberUsage";
import SubscriberUpdate from "./pages/SubscriberUpdate";
import SubscriberEffectProfiles from "./pages/SubscriberEffectProfiles";
import SubscriberEffectProfilesUpdate from "./pages/SubscriberEffectProfilesUpdate";
import TestPlayer from "./pages/TestPlayer";
import MediaDetails from "./pages/MediaDetails";
import CostEstimator from "./pages/CostEstimator";
import SystemHealth from "./pages/SystemHealth";
import Login from "./pages/Login";
import AlfredRedirect from "./pages/AlfredRedirect";
import ZoomRedirect from "./pages/ZoomRedirect";
import reportWebVitals from "./reportWebVitals";
import { RequireAuth } from "./components/RequireAuth";
import AuthCallback from "./pages/AuthCallback";
import { AuthProvider } from "./context/useAuth";
import { MediaKeysContextProvider } from "./context/mediaKeysContext";
import { AsideContextProvider } from "./context/asideContext";
import { MediaKeysContextDynamicContentProvider } from "./context/mediaKeysContextDynamic";
import { SubscribersContextProvider } from "./context/subscribersContext";
import { RegionContextProvider } from "./context/regionContext";
import MeetingViewNew from "pages/MeetingJs";

ReactDOM.render(
  <React.StrictMode>
    <RegionContextProvider>
      <AuthProvider>
        <SubscribersContextProvider>
          <MediaKeysContextDynamicContentProvider>
            <MediaKeysContextProvider>
              <AsideContextProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <Dashboard />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers/:id"
                      element={
                        <RequireAuth>
                          <SubscriberMedia />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers-overview/:id"
                      element={
                        <RequireAuth>
                          <SubscriberOverview />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers-cost/:id"
                      element={
                        <RequireAuth>
                          <SubscriberCost />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers-usage/:id"
                      element={
                        <RequireAuth>
                          <SubscriberUsage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscriber-update/:id"
                      element={
                        <RequireAuth>
                          <SubscriberUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers-effect-profile/:id"
                      element={
                        <RequireAuth>
                          <SubscriberEffectProfiles />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers-effect-profile-update/:subscriberId/:profileId"
                      element={
                        <RequireAuth>
                          <SubscriberEffectProfilesUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/test-player"
                      element={
                        <RequireAuth>
                          <TestPlayer />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/subscribers/:subscriberId/media/:mediaId"
                      element={
                        <RequireAuth>
                          <MediaDetails />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/cost-estimator"
                      element={
                        <RequireAuth>
                          <CostEstimator />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/system-health"
                      element={
                        <RequireAuth>
                          <SystemHealth />
                        </RequireAuth>
                      }
                    />
                    <Route path="/callback/:region" element={<AuthCallback />} />
                    <Route path="/in-meeting-new" element={<MeetingViewNew />} />
                    <Route path="/vinton-auth-redirect" element={<AlfredRedirect />} />
                    <Route path="/zoom-test-redirect" element={<ZoomRedirect />} />
                  </Routes>
                </BrowserRouter>
              </AsideContextProvider>
            </MediaKeysContextProvider>
          </MediaKeysContextDynamicContentProvider>
        </SubscribersContextProvider>
      </AuthProvider>
    </RegionContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
